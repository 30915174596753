import { ordersConstants, productConstants } from "../constants";

export function product(state, action) {
  switch (action.type) {
    case productConstants.ADD_PRODUCT_REQUEST:
      return {
        ProductAdded: "1",
        dataProduct: action.payload,
      };
    case productConstants.ADD_PRODUCT_SUCCESS:
      return {
        ProductAdded: "2",
        dataProduct: action.payload,
      };
    case productConstants.ADD_PRODUCT_FAILURE:
      return {
        ProductAdded: "3",
        dataProduct: action.payload,
      };
    case productConstants.GET_PRODUCT_REQUEST:
      return {
        ...state,
        ProductFinded: "1",
        data: action.payload,
      };
    case productConstants.GET_PRODUCT_SUCCESS:
      return {
        ...state,
        ProductFinded: "2",
        data: action.payload,
      };
    case productConstants.GET_PRODUCT_FAILURE:
      return {
        ...state,
        ProductFinded: "3",
        data: action.payload,
      };
    case productConstants.GET_PRODUCT_V2_REQUEST:
      return {
        ...state,
        GettingAllProducts: "1",
      };
    case productConstants.GET_PRODUCT_V2_SUCCESS:
      return {
        ...state,
        GettingAllProducts: "2",
        productsData: action.payload,
      };
    case productConstants.GET_PRODUCT_V2_FAILURE:
      return {
        ...state,
        GettingAllProducts: "3",
        productsData: action.payload,
      };
    case productConstants.GET_PRODUCT_HISTORY_REQUEST:
      return {
        ...state,
        GettingProductHist: "1",
        dataHist: action.payload,
      };
    case productConstants.GET_PRODUCT_HISTORY_SUCCESS:
      return {
        ...state,
        GettingProductHist: "2",
        dataHist: action.payload,
      };
    case productConstants.GET_PRODUCT_HISTORY_FAILURE:
      return {
        ...state,
        GettingProductHist: "3",
        dataHist: action.payload,
      };
    case productConstants.GET_PRODUCT_PERFORMANCE_REQUEST:
      return {
        ...state,
        GettingProductPerf: "1",
        dataPerf: action.payload,
      };
    case productConstants.GET_PRODUCT_PERFORMANCE_SUCCESS:
      return {
        ...state,
        GettingProductPerf: "2",
        dataPerf: action.payload,
      };
    case productConstants.GET_PRODUCT_PERFORMANCE_FAILURE:
      return {
        ...state,
        GettingProductPerf: "3",
        dataPerf: action.payload,
      };
    case productConstants.GET_ALL_RECEIVED_PRODUCTS_REQUEST:
      return {
        ...state,
        GettingBonReceive: "1",
        dataReceive: action.payload,
      };
    case productConstants.GET_ALL_RECEIVED_PRODUCTS_SUCCESS:
      return {
        ...state,
        GettingBonReceive: "2",
        dataReceive: action.payload,
      };
    case productConstants.GET_ALL_RECEIVED_PRODUCTS_FAILURE:
      return {
        ...state,
        GettingBonReceive: "3",
        dataReceive: action.payload,
      };
    case productConstants.GET_ALL_EXIT_PRODUCTS_REQUEST:
      return {
        ...state,
        GettingBonExit: "1",
        dataExit: action.payload,
      };
    case productConstants.GET_ALL_EXIT_PRODUCTS_SUCCESS:
      return {
        ...state,
        GettingBonExit: "2",
        dataExit: action.payload,
      };
    case productConstants.GET_ALL_EXIT_PRODUCTS_FAILURE:
      return {
        ...state,
        GettingBonExit: "3",
        dataExit: action.payload,
      };
    case productConstants.GET_DETAILS_RECEIVED_PRODUCTS_REQUEST:
      return {
        ...state,
        GettingDetailsVoucher: "1",
        dataDetailsVoucher: action.payload,
      };
    case productConstants.GET_DETAILS_RECEIVED_PRODUCTS_SUCCESS:
      return {
        ...state,
        GettingDetailsVoucher: "2",
        dataDetailsVoucher: action.payload,
      };
    case productConstants.GET_DETAILS_RECEIVED_PRODUCTS_FAILURE:
      return {
        ...state,
        GettingDetailsVoucher: "3",
        dataDetailsVoucher: action.payload,
      };

    case productConstants.GET_DETAILS_TAKEN_PRODUCTS_REQUEST:
      return {
        ...state,
        GettingDetailsVoucherTaken: "1",
        dataDetailsVoucherTaken: action.payload,
      };
    case productConstants.GET_DETAILS_TAKEN_PRODUCTS_SUCCESS:
      return {
        ...state,
        GettingDetailsVoucherTaken: "2",
        dataDetailsVoucherTaken: action.payload,
      };
    case productConstants.GET_DETAILS_TAKEN_PRODUCTS_FAILURE:
      return {
        ...state,
        GettingDetailsVoucherTaken: "3",
        dataDetailsVoucherTaken: action.payload,
      };

    case productConstants.GET_STOCK_RELEASES_REQUEST:
      return {
        ...state,
        GettingStockReleases: "1",
      };
    
    case productConstants.GET_STOCK_RELEASES_SUCCESS:
      return {
        ...state,
        GettingStockReleases: "2",
        dataStockReleases: action.payload
      };
    
    case productConstants.GET_STOCK_RELEASES_FAILURE:
      return {
        ...state,
        GettingStockReleases: "3",
        dataErrorStockReleases: action.payload 
      };

    case productConstants.UPDATE_STOCK_RELEASE_REQUEST:
      return {
        ...state,
        UpdatingStockRelease: "1",
      };

    case productConstants.UPDATE_STOCK_RELEASE_SUCCESS:

      const updatedIndex = state.dataStockReleases.results.findIndex(item => item.id === action.payload.id);
      let new_data = [...state.dataStockReleases.results];
      new_data[updatedIndex] = action.payload;

      return {
        ...state,
        UpdatingStockRelease: "2",
        dataStockReleases: { ...state.dataStockReleases, results: new_data },
      };
    
    case productConstants.UPDATE_STOCK_RELEASE_FAILURE:

      return {
        ...state,
        UpdatingStockRelease: "3",
        updateStockReleaseError: action.payload
      };
    
    case productConstants.ADD_STOCK_RELEASE_REQUEST:
      return {
        ...state,
        AddingStockRelease: "1",
      };
    
    case productConstants.ADD_STOCK_RELEASE_SUCCESS:

      return {
        ...state,
        AddingStockRelease: "2",
        dataAddStockRelease: action.payload
      };
    
    case productConstants.ADD_STOCK_RELEASE_FAILURE:
      return {
        ...state,
        AddingStockRelease: "3",
        addStockReleaseError: action.payload
      };
    
    case productConstants.CLEAR_STOCK_RELEASE_ITEM:
      return {
        ...state,
        AddingStockRelease: null,
        dataAddStockRelease: null,
        UpdatingStockRelease: null,
        updateStockReleaseError: null,
        addStockReleaseError: null
      }

    default:
      return { ...state };
  }
}
export function Stock(state, action) {
  switch (action.type) {
    case productConstants.GET_LOW_STOCK_REQUEST:
      return {
        ...state,
        GettingLowStockProducts: "1",
        LowStockProductsNumber: action.payload
      }
    case productConstants.GET_LOW_STOCK_SUCCESS: 
      return {
        ...state,
        GettingLowStockProducts: "2",
        LowStockProductsNumber: action.payload
      }
    case productConstants.GET_LOW_STOCK_FAILURE:
      return {
        ...state,
        GettingLowStockProducts: "3",
        LowStockProductsNumber: action.payload
      }
    case productConstants.STOCK_PRODUCT_REQUEST:
      return {
        ...state,
        StockLoadded: "1",
        DataStock: action.payload,
      };
    case productConstants.STOCK_PRODUCT_SUCCESS:
      return {
        ...state,
        StockLoadded: "2",
        DataStock: action.payload,
      };
    case productConstants.STOCK_PRODUCT_FAILURE:
      return {
        ...state,
        StockLoadded: "3",
        DataStock: action.payload,
      };
    case productConstants.GET_AVAILABLE_QUANTITY_REQUEST:
      return {
        ...state,
        GettingAvailQty: "1",
        AvailQtyData: action.payload,
      };
    case productConstants.GET_AVAILABLE_QUANTITY_SUCCESS:
      return {
        ...state,
        GettingAvailQty: "2",
        AvailQtyData: action.payload,
      };
    case productConstants.GET_AVAILABLE_QUANTITY_FAILURE:
      return {
        ...state,
        GettingAvailQty: "3",
        AvailQtyData: action.payload,
      };
    case productConstants.UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        ProductUpdated: "1",
        dataUpdate: action.payload,
      };
    case productConstants.UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        ProductUpdated: "2",
        dataUpdate: action.payload,
      };
    case productConstants.UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        ProductUpdated: "3",
        dataUpdate: action.payload,
      };
    case productConstants.DELETE_PRODUCT_REQUEST:
      return {
        ProductDeleted: "1",
        dataDelete: action.payload,
      };
    case productConstants.DELETE_PRODUCT_SUCCESS:
      return {
        ProductDeleted: "2",
        dataDelete: action.payload,
      };
    case productConstants.DELETE_PRODUCT_FAILURE:
      return {
        ProductDeleted: "3",
        dataDelete: action.payload,
      };
    case "CLEAR_STOCK_STORE":
      return {};
    default:
      return { ...state };
  }
}

export function UnlinkProduct(state, action) {
  switch (action.type) {
    case productConstants.GET_PRODUCT_PAGE_REQUEST:
      return {
        GetPrdPage: "0",
        PrdPageData: action.payload,
      };
    case productConstants.GET_PRODUCT_PAGE_SUCCESS:
      return {
        GetPrdPage: "1",
        PrdPageData: action.payload,
      };
    case productConstants.GET_PRODUCT_PAGE_FAILURE:
      return {
        GetPrdPage: "2",
        PrdPageData: action.payload,
      };
    case ordersConstants.IMPORT_PRODUCT_REQUEST:
      return {
        ImportingPrd: "0",
        DataImportProd: action.payload,
      };
    case ordersConstants.IMPORT_PRODUCT_SUCCESS:
      return {
        ImportingPrd: "1",
        DataImportProd: action.payload,
      };
    case ordersConstants.IMPORT_PRODUCT_FAILURE:
      return {
        ImportingPrd: "2",
        DataImportProd: action.payload,
      };
    case "REMOVE_PRODUCT_PAGE":
      return {};
    default:
      return { ...state };
  }
}
