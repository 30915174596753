import React from "react"
import ContentLoader from "react-content-loader"
const LoaderBln = (props) => (
    <ContentLoader 
        speed={2}
        height={300}
        width={"100%"}
        viewBox="0 0 250 310"
        backgroundColor="var(--bdgclInp)"
        foregroundColor="var(--defcl)"
        {...props}
    > 
        <circle cx="120" cy="75" r="70"/>
        <rect x="10" y="170" rx="0" ry="0" width="90%" height="35" />
        <rect x="10" y="220" rx="0" ry="0" width="85%" height="35" />
    </ContentLoader>
  )
  
  export default LoaderBln