import React, { Component } from "react";
import { Translate } from "../../../utils/lang/translate";
import noVoucher from "../../../utils/img/noVoucher.svg";
class NoVoucher extends Component {
  render() {
    return (
      <div className="FlHg FlWd">
        <div className="FlHg FlWd AlgnItm JstfCnt InFlx">
          <div className="TxtCn">
            <img src={noVoucher} alt="under_dev_img" width="350" />
            <div className="TxtCn">
              <p>{Translate("titles", "txtnovoucher")}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export { NoVoucher };
