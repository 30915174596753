import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ContainerPage from '../_Common/ContainerPage'
import { Translate } from '../../../utils/lang/translate'
import GroupeForm from '../../_Common/GroupeForm'
import Button from '../../_Common/Button'
import SelectForm from '../../_Common/SelectForm'

function PickupQuickForm(props) {

    const [providername,setProviderName]= useState(props.providername ||"")
    const [providerphonenumber,setProviderPhoneNumber]= useState(props.providerphonenumber ||"")
    const [pickup_address,setPickupAddress]= useState(props.pickup_address ||"")
    const [car_type,setCarType]= useState(props.car_type ||"2")
    const [errors,setErrors]= useState({})


    const handleChange=(e)=>{
        const {name,value}=e.target

        switch(name){
            case "providername":
                setProviderName(value)

                break;
            case "providerphonenumber":
                setProviderPhoneNumber(value)

                break;
            case "pickup_address":
                setPickupAddress(value)
                

                break;
            case "car_type":
                setCarType(value)

                break;
            default: 
                break;
        }
        props.onChange && props.onChange(name,value)
    }

    const handleSubmit=(e)=>{
        e.preventDefault();
        let kkepGoing = true;
        let pickup = {},error = {};
        // if (commune && commune.id) {
        //     pickup.commune = commune.id;
        // } else {
        //     error.ercommune = Translate("error", "eo2");
        //     kkepGoing = false;
        // }
        if (pickup_address) {
            pickup.pickup_address = pickup_address;
        } else {
            error.eradress = Translate("error", "eo7");
            kkepGoing = false;
        }
        if (providerphonenumber) {
          pickup.customer_phone = providerphonenumber;
        } else {
          error.erphone = Translate("error", "eo5");
          kkepGoing = false;
        }
        if (car_type) {
          pickup.car_type = car_type;
        } else {
          // error.erphone = Translate("error", "eo5");
          kkepGoing = false;
        }
        // if (providername) {
        //   pickup.customer_name = providername;
        // } else {
        //   error.ername = Translate("error", "eo4");
        //   kkepGoing = false;
        // }
        
        if (kkepGoing) {
            setErrors({})
            props.onSuccessFill && props.onSuccessFill(pickup)
       
    
        } else {
            setErrors(error)
            props.onFailFill && props.onFailFill(pickup)
        }

    }
    return (
        <form method="post" onSubmit={handleSubmit}>

            <ContainerPage
              subContainer
              page_title={Translate("pickups", "providerinfos")}
              small_title={true}
            >
              <div className="FlWd InFlx responsFlx flxDrc">
                <div className="Hlwd">
                    <GroupeForm
                        id={"pickup_address"}
                        name={"pickup_address"}
                        placeholder={Translate("pickups", "adress")}
                        text={Translate("pickups", "adress")}
                        type={"text"}
                        value={pickup_address}
                        workfun={handleChange}
                        error={errors.eradress}
                    />
                  
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm
                    id={"providerphonenumber"}
                    name={"providerphonenumber"}
                    placeholder={Translate("auths", "phone")}
                    text={Translate("auths", "phone")}
                    type={"tel"}
                    pattern={"[0]{1}[0-9]{9}"}
                    value={providerphonenumber}
                    workfun={handleChange}
                    error={errors.erphone}
                  />
                </div>
              </div>
            </ContainerPage>
            <ContainerPage
              subContainer
              withOvrl={true}
              page_title={Translate("pickups", "details")}
              small_title={true}
              >
              <div className="FlWd InFlx responsFlx flxDrc" style={{ height: "100%" }}>
                <div className="Hlwd">
                    <SelectForm 
                    id={"car_type"}
                    name={"car_type"}
                    option={props.typesOptions}
                    value={car_type}
                    text={Translate("pickups","suitablevehicule")} 
                    loading={props.GettingTypes!== "2"}
                    workfun={handleChange}
                    />
                  
                  
                </div>
                <div className="Hlwd Mrg2">
                    {/* <Select
                      label={Translate("orders", "district")}
                      placholder={Translate("orders", "districtSearch")}
                      search={true}
                      Options={DataCommune}
                      fieldShow={"name"}
                      name="commune"
                      loading={GetigCommune === "0"}
                      value={commune}
                      onChange={this.handleSelect}
                      maxSize="200px"
                    /> */}
                  
                </div>
              </div>
            </ContainerPage>
          
            {props.btnTitle &&
            <div className="MrAot StMarMx" style={{ width: "25%" }}>
              <Button BtnText={props.btnTitle} />
            </div>}
          </form>
    )
}

PickupQuickForm.propTypes = {
    typesOptions: PropTypes.array.isRequired,
    GettingTypes: PropTypes.string,
    btnTitle: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onSuccessFill: PropTypes.func.isRequired,
    onFailFill: PropTypes.func.isRequired,

}

export default PickupQuickForm

