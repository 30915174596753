import React from "react";
export const MULTI_LANG_PARAGRAPHS={
    en: {
        "new-premium-statuses-explan": <><p>
        The starting point is the moment when you create a new order. At this point 3 cases are possibles:
      </p>
      <ul>
        <li>The order is <b>Ready to ship</b>, since all necessary product's quantities are available in the concerned warehouse</li>
        <li>The order is <b>Waiting Transit</b>, This means that there's no satisfying quantity of the order's products in the concerned warehouse. But you don't need to worry, our system is intelligent to fetch those products from another warehouse and request a transfer without your intervention, since <b>Premium</b> pack relieves you from dispatch. The order will be In Transit after this process</li>
        <li>The order is <b>Out Of Stock</b>, which means there is no available quantity of the order's products in all our warehouses. As a consequence, you must feed the stock. Depending on the destination, the order will eventually become Waiting Transir or Ready to ship.</li>
      </ul>
      <p>
        Now, the order will be be shipped with a delivery agent. Depending on the interaction with the customer, different cases would took place: 
      </p>
      <ul>
        <li>The ideal and straightforward one : The order is successfully <b>delivered</b></li>
        <li>The order has been  <b>postponed</b> to a later date based on customer constraints</li>
        <li>The order is in <b>Alert</b> state and <b>needs your intervention</b>: The delivery agnet call your action due to some possible issue or missunderstanding with the customer. This ensures better commincation and decreases your cancellation rate.</li>
        <li>The order has been <b>cancelled</b> due to one of the abort reasons in our system workflow.</li>
      </ul>
      <p>
        Note that the order might be shipped many times with multiple attempts. You can get this info from the order's history present in the order's details page. 
      </p></>,
        "new-starter-statuses-explan": <>
      <p>
        The process is nearby the same as detailed in this video from the archives. Click <a href="https://storage.googleapis.com/stores_video/Statuts%20MD%20-%20Video.mp4" target="blank">Here</a> to consult it.
      </p>
      <p>
        From the moment of creating an order, and after preparing it, you can either grab it to the nearest warhouse to your location, or you can request it (with others eventually) for a pickup, the order in this case will pass through the pickup process.
      </p>
      <p>
        Based on the warehouse where packages were received and the destination warehouse of the order, this later would become either in Waiting transit/In Hub (different warehouses) or Ready to ship (same warehouses) status. The first status will lead to the second one after an inter-warehouses transit.
      </p>
      
      <p>
        Now, the order will be be shipped with a delivery agent. Depending on the interaction with the customer, different cases would took place: 
      </p>
      <ul>
        <li>The ideal and straightforward one : The order is successfully <b>delivered</b></li>
        <li>The order has been  <b>postponed</b> to a later date based on customer constraints</li>
        <li>The order is in <b>Alert</b> state and <b>needs your intervention</b>: The delivery agnet call your action due to some possible issue or missunderstanding with the customer. This ensures better commincation and decreases your cancellation rate.</li>
        <li>The order has been <b>cancelled</b> due to one of the abort reasons in our system workflow.</li>
      </ul>
      <p>
        Note that the order might be shipped many times with multiple attempts. You can get this info from the order's history present in the order's details page. 
      </p></>
    },
    fr: {
        "new-premium-statuses-explan": <>
      <p>
        Le point de départ est le moment où vous créez une nouvelle commande. A ce stade 3 cas sont possibles:
      </p>
      <ul>
        <li>La commande est <b>prête à être expédiée</b> , car toutes les quantités des produits nécessaires sont disponibles dans l'entrepôt concerné.</li>
        <li>La commande est en <b>attente de transit</b> , cela signifie qu'il n'y a pas de quantité satisfaisante des produits de la commande dans l'entrepôt concerné. Mais ne vous inquiétez pas, notre système est intelligent pour ramener ces produits depuis un autre entrepôt et demander un transfert sans votre intervention, puisque le pack <b>Premium</b> vous soulage du Dispatch. La commande sera en transit après ce processus.</li>
        <li>La commande est en <b>rupture de stock </b>, ce qui signifie qu'il n'y a pas de quantité disponible des produits de la commande dans tous nos entrepôts. Par conséquence, vous devez alimenter votre stock. Selon la destination, la commande deviendra éventuellement En attente de transit ou Prête à être expédiée.</li>
      </ul>
      <p>
      Maintenant, la commande sera expédiée avec un agent de livraison. En fonction de l'interaction avec le client, différents cas se seraient produits : 
      </p>
      <ul>
        <li>L'idéal et simple : La commande est <b>livrée</b> avec succès.</li>
        <li>La commande a été <b>reportée</b> à une date ultérieure en fonction des contraintes du client.</li>
        <li>La commande est en état d'<b> alerte</b> et <b>nécessite votre intervention</b> : L'agent de livraison appelle votre action en raison d'un éventuel problème ou d'un malentendu avec le client. Cela garantit une meilleure communication et diminue votre taux d'annulation.</li>
        <li>La commande a été <b>annulée</b> en raison de l'une des raisons d'abondon définies dans notre système d'information.</li>
      </ul>
      <p>
      Notez que la commande peut être expédiée plusieurs fois avec plusieurs tentatives. Vous pouvez obtenir ces informations à partir de l'historique de la commande présent dans la page des détails de la commande. 
      </p></>,
        "new-starter-statuses-explan": <>
      <p>
      Le processus est à peu près le même que celui détaillé dans cette vidéo des archives. Cliquez <a href="https://storage.googleapis.com/stores_video/Statuts%20MD%20-%20Video.mp4" target="blank">ici</a> pour le consulter.
      </p>
      <p>
      A partir du moment de la création d'une commande, et après l'avoir préparée, vous pouvez soit la déposer au dépôt le plus proche de chez vous, soit la demander (avec d'autres éventuellement) pour un Ramassage, la commande dans ce cas passera par le processus de ramassage.
      </p>
      <p>
      En fonction de l'entrepôt où les colis ont été reçus et de l'entrepôt de destination de la commande, celle-ci deviendrait plus tard soit en attente de transit/en hub (entrepôts différents), soit en état Prêt à expédier (mêmes entrepôts). Le premier statut conduira au second après un transit inter-entrepôts.
      </p>
      
      <p>
      Maintenant, la commande sera expédiée avec un agent de livraison. En fonction de l'interaction avec le client, différents cas se seraient produits : 
      </p>
      <ul>
        <li>L'idéal et simple : La commande est <b>livrée</b> avec succès.</li>
        <li>La commande a été <b>reportée</b> à une date ultérieure en fonction des contraintes du client.</li>
        <li>La commande est en état d'<b> alerte</b> et <b>nécessite votre intervention</b> : L'agent de livraison appelle votre action en raison d'un éventuel problème ou d'un malentendu avec le client. Cela garantit une meilleure communication et diminue votre taux d'annulation.</li>
        <li>La commande a été <b>annulée</b> en raison de l'une des raisons d'abondon définies dans notre système d'information.</li>
      </ul>
      <p>
      Notez que la commande peut être expédiée plusieurs fois avec plusieurs tentatives. Vous pouvez obtenir ces informations à partir de l'historique de la commande présent dans la page des détails de la commande. 
      </p></>
    },
    ar: {
        "new-premium-statuses-explan": <><p>
        نقطة البداية تمثّل اللّحظة التّي فيها يتمّ إنشاء الطّلبيّة, 3 حالات ممكنة من خلال هذه النّقطة
      </p>
      <ul>
        <li>الطّلبيّة<b> جاهزة للشّحن</b>, وهذا لتوفّر جميع الكمّيّات اللازمة الخاصة بالطّلبيّة في المستودع المعني</li>
        <li>الطّلبيّة <b>قيد إنتظار تحويل</b>, وهذا راجع لعدم توفّر أحد أو كل المنتجات الازمة في المستودع المعني. هذا الأمر لا يستدعي تدخلا منكم بما أنّ  عرض <b>Premium </b> يريحكم من عناء إدارة التوزيع والتّحويلات. بناء على ذلك تتحوّل الطّلبيّة إلى حالة قيد التّحويل</li>
        <li>الطّلبيّة متوقفة <b>لعدم توفر المخزون</b> في جميع مستودعاتنا, وعليه , يتوجب عليكم تموين مخزونكم.  تتفيّر بعدها حالة الطّلبيّة إلى جاهزة للشّحن أو قيد انتظار تحويل حسب الوجهة و مكان التّموين</li>
      </ul>
      <p>
        على هذا المستوى , الطّلبيّة الآن جاهزة و يتمّ شحنها مع عامل توصيل. يوجد عدّة إحتمالات ممكنة  
      </p>
      <ul>
        <li>الطّلبيّة <b>موصّلة</b> بنجاح للزبون , وهي الحالة المثلى والأبسط</li>
        <li>الطّلبيّة <b>مؤجّلة</b> لتاريخ لاحق بناء على انشغالات ووضعيّة الزّبون</li>
        <li>الطّلبيّة <b>في حلة تنبيه</b> وتستدعي تدخلكم , نظرا لتعذّر الإتصال بالزبون أو إلتباس في نقطة معيّنة . تمّ إضافة هذه الحالة من أجل تواصل أحسن و تحسين مردوديّتكم خاصة معدّل الإلغاءات</li>
        <li>الطّلبيّة <b>ملفاة</b> لأحد الأسباب المدرجة في مقتضى سيرورة العمل</li>
      </ul>
      <p>
         نشدّ إنتباهكم إلى أنّه يمكن للطّلبيّة أن يتم شحنها عدّة مرات  ومن خلال عدّة محاولات .  يمكنكم دائما الإطّلاع على هذه المعلومة عبر الأرشيف المتوفّر في صفحة تفاصيل الطّلبيّة
      </p></>,
      "new-starter-statuses-explan": <>
      <p>
      مسار الطّلبيّة مشابه بنسبة كبيرة للفيديو التّفصيليّ المتوفّر في الأرشيف. الرّجاء الإطّلاع عليه من خلال النّقر <a href="https://storage.googleapis.com/stores_video/Statuts%20MD%20-%20Video.mp4" target="blank">هنا</a>
      </p>
      <p>
      بعد <b>إنشاء</b> الطّلبيّة في نظامنا,وتجهيزها من طرفكم (من تغليف و إلصاق قسيمة التّتبّع), يمكنكم إمّا إحضار الطّلبيّة إلى أقرب مستودع أو مكتب إليكم, أو إدراجها ضمن طلب تجميع (والذي يمكن أن يتضمن عدّة طلبيّات معا). في الحالة الثانية تمرّ الطّلبيّة عبر مسار التّجميع المؤطّر بالأخضر
      </p>
      <p>
      بناء على المستودع الذي تمّ فيه استقبال الطّرد و المستودع الوجهة, تصبح الطّلبيّة إمّا <b>منتظرة للتّحويل</b> (في مستودع غير المستودع الوجهة), إمّا في حالة <b>جاهزة للتّوصيل</b>. الحالة الأولى ستستلزم حدوث <b>تحويل</b> بين المستودعات و الذي ينتهي به المطاف في نفس مآل الحالة الثانية
      </p>
      
      <p>
        على هذا المستوى , الطّلبيّة الآن جاهزة و يتمّ شحنها مع عامل توصيل. يوجد عدّة إحتمالات ممكنة  
      </p>
      <ul>
        <li>الطّلبيّة <b>موصّلة</b> بنجاح للزبون , وهي الحالة المثلى والأبسط</li>
        <li>الطّلبيّة <b>مؤجّلة</b> لتاريخ لاحق بناء على انشغالات ووضعيّة الزّبون</li>
        <li>الطّلبيّة <b>في حلة تنبيه</b> وتستدعي تدخلكم , نظرا لتعذّر الإتصال بالزبون أو إلتباس في نقطة معيّنة . تمّ إضافة هذه الحالة من أجل تواصل أحسن و تحسين مردوديّتكم خاصة معدّل الإلغاءات</li>
        <li>الطّلبيّة <b>ملفاة</b> لأحد الأسباب المدرجة في مقتضى سيرورة العمل</li>
      </ul>
      <p>
         نشدّ إنتباهكم إلى أنّه يمكن للطّلبيّة أن يتم شحنها عدّة مرات  ومن خلال عدّة محاولات .  يمكنكم دائما الإطّلاع على هذه المعلومة عبر الأرشيف المتوفّر في صفحة تفاصيل الطّلبيّة
      </p></>,
    },
}

export const translate_section=(key,lang)=>{

    return MULTI_LANG_PARAGRAPHS[lang]?MULTI_LANG_PARAGRAPHS[lang][key]: ""

}