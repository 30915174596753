import React,{Component} from 'react'
import { connect } from 'react-redux'
import {Translate} from '../../../utils/lang/translate'
import {UnderDevPage} from '../_Common/UnderDevPage'
class IntegrationsPage extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    render(){
        return(
            <div className="ClSidTh StBrdTb FlWd StBgbrds RlPs lytWdp stbd2 StDrc">
                <div className="stbd2">
                    <h3 className="DlMg">{Translate('settings','integrations')}</h3>
                </div>
                <UnderDevPage/>
            </div>
        )
    }
    
}
function mapState(state) {
     
}

const actionCreators = {
    
};

const connectedIntegrationsPage = connect(mapState, actionCreators)(IntegrationsPage);
export { connectedIntegrationsPage as IntegrationsPage };