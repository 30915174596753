import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import { ordersActions, productActions } from "../../../actions";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import Loader from "../../_Common/Loader";
import BtnLg from "../_Common/BtnLg";
import { Link, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
import XLSX from "xlsx";

//import FormData from "form-data";
//import fs from "fs";
var FormData = require("form-data");
var fs = require("fs");

class ImportProductPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DataTable: null,
      prodcut: "",
      errPrd: "",
      radio: "",
      not_created: ["Rania bakalam / 0555101010"],
      file: null,
    };
    this.submit = false;
    this.readxlsfile = this.readxlsfile.bind(this);
    this.blockEnter = this.blockEnter.bind(this);
    this.dropFile = this.dropFile.bind(this);
    this.getFileExtension = this.getFileExtension.bind(this);
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "importexcel");
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { DataImport, ImportingPrd } = nextProps;
    if (ImportingPrd === "1" && this.submit) {
      this.submit = false;
      this.setState({
        radio: DataImport.data.ratio_created + "%",
        DataTable: null,
      });
    }
  }
  showDropHigh(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.remove("hidElem");
  }
  hideDropHigh(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.add("hidElem");
  }

  // program to get the file extension

  getFileExtension(filename) {
    // get file extension
    const extension = filename.split(".").pop();
    return extension;
  }

  dropFile(e) {
    e.preventDefault();
    e.stopPropagation();
    var dropIteam = document.getElementById("Drag_Drop_Iteam");
    dropIteam.classList.add("hidElem");
    let dataTra = e.dataTransfer;
    this.readxlsfile({ target: dataTra });
  }

  readxlsfile(e) {
    this.setState({ file: e.target.files[0] });
    var reader = new FileReader();
    var bindthis = this;

    reader.readAsArrayBuffer(e.target.files[0]);

    reader.onload = function () {
      var data = new Uint8Array(reader.result);

      var arr = [];
      for (var i = 0; i !== data.length; ++i)
        arr[i] = String.fromCharCode(data[i]);
      var DataBook = arr.join("");
      var workbook = XLSX.read(DataBook, {
        type: "binary",
      });
      var first_sheet_name = workbook.SheetNames[0];

      var worksheet = workbook.Sheets[first_sheet_name];

      var JsonData = XLSX.utils.sheet_to_json(worksheet);
      bindthis.setState({
        DataTable: JsonData,
      });
    };
  }

  ClearData() {
    this.setState({
      DataTable: null,
    });
  }
  SendOrders(e) {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", this.state.file, "MOCK_DATA.xlsx");
    const result1 = this.getFileExtension(this.state.file.name);
    if (result1 === "xlsx" || result1 === "csv") {
      this.props.ImportProduct(this.state.DataTable);
    }
  }
  blockEnter(e) {
    var keyCode = e.which || e.keyCode;
    keyCode === 13 && e.preventDefault();
  }
  render() {
    const { DataImport, data, ImportingPrd } = this.props;
    if (ImportingPrd === "1") {
      return <Redirect to="/stock/products" />;
    }

    let styleDrop = (
      <style>
        {`.StBefoCn::before{
            content: "` +
          Translate("importorder", "dropher") +
          `";
            font-size: 1.7em;
            font-weight: bold;
            color:var(--fntClrInv);
        }`}
      </style>
    );
    return (
      <ContainerPage page_title={Translate("titles", "importprodexcel")}>
        <div className="ClSidTh StBrdTb SecnNivWid StBgbrds RlPs ">
          {ImportingPrd === "0" && <Loader />}
          {!Array.isArray(this.state.DataTable) ? (
            <Fragment>
              <div
                id="Cont_File_Loader"
                style={{ padding: "50px 20px" }}
                className="BrdBlDtd RlPs"
                onDrop={this.dropFile}
                onDragLeave={this.hideDropHigh}
                onDragEnter={this.showDropHigh}
                onDragOver={this.showDropHigh}
              >
                <div id="Drag_Drop_Iteam" className="hidElem">
                  <Helmet>{styleDrop}</Helmet>
                  <div
                    className="StAbs FlHg FlWd StTpNl StRgNl"
                    style={{ zIndex: "1" }}
                  >
                    <div className="RlPs InFlx FlWd FlHg AlgnItm JstfCnt StbdGTrn StBefoCn"></div>
                  </div>
                </div>
                <form>
                  <div className="InFlx AlgnItm  flxDrc responsFlx spcBtwn">
                    <div className="TxtCn RlPs">
                      <input
                        type="file"
                        id="File_Import_excel"
                        onChange={this.readxlsfile}
                        className="hidElem HdOvrfl StAbs"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      />
                      <label
                        htmlFor="File_Import_excel"
                        className="BdgBlcl DsBlc stbold CrsPoi StBrdRd StPdsml2"
                      >
                        {Translate("importorder", "filetext")}
                      </label>
                      <span style={{ paddingTop: "8px" }} className="DsBlc">
                        {Translate("importorder", "accpfil")}
                      </span>
                    </div>
                    <div className="RlPs" style={{ maxWidth: "65%" }}>
                      <p>
                        {Translate("importorder", "txtfil")}
                        <a
                          className="StBle"
                          href="/excel_model_add_product.xlsx"
                        >
                          {Translate("importorder", "modldown")}
                        </a>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="FlWd StdirLn">
                <h3 className="DlMg">{Translate("importorder", "prodlist")}</h3>
                {ImportingPrd === "2" ? (
                  <span className="DlMg StRedClr">
                    {Translate("error", "err50")}
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div
                id="ResultTable"
                className="SmMarS1"
                style={{ overflow: "auto" }}
              >
                <div>{this.state.file.name}</div>
              </div>
              <div className="InFlx flxDrc responsFlx" style={{ gap: "3vw" }}>
                <div
                  className="StClSvgD CrsPoi stbold"
                  onClick={this.ClearData.bind(this)}
                >
                  <BtnLg
                    classSet={"BrdBl BdgGc"}
                    style={{ padding: "0 30px" }}
                    text={Translate("importorder", "goback")}
                  />
                </div>
                <div
                  className="StAutMr StClSvgD CrsPoi stbold"
                  onClick={this.SendOrders.bind(this)}
                >
                  <BtnLg
                    style={{ padding: "0 30px" }}
                    text={Translate("importorder", "Envoyer")}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { ImportingPrd, DataImportProd } = state.UnlinkProduct;
  const { dtStoreinfo } = state.user;
  return { ImportingPrd, DataImportProd, dtStoreinfo };
}
const actionCreators = {
  ImportProduct: productActions.ImportProduct,
};

const connectedImportProductPage = connect(
  mapState,
  actionCreators
)(ImportProductPage);
export { connectedImportProductPage as ImportProductPage };
