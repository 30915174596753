import React, { Component } from "react";
import { connect } from "react-redux";
import {
  productActions,
  pickupsActions,
  commonActions,
  alertActions,
} from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link } from "react-router-dom";
import { initGAPg, Event, Select } from "../_Common";
import { CloseSvg } from "../_Common/IconSvg";
class AddStockPickupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [{ product_id: "", quantity: 1, logistical_description: "" }],
      allOrders:
        this.props.OrderGeting === "2"
          ? this.props.OrdersList.ressuc.list.results
          : [],
      orders: [],
      providername: "",
      providerphonenumber: "",
      pickup_address: "",
      commune: null,
      city: null,
      warehouse: null,
      notetodriver: "",
      vehicule: 2,
      error: {},
      notfound: false,
      nbproducts: 1,
      productnames: [],
      filtersource:
        this.props.PickupGetting === "2" ? this.props.PickupGetting.filter : "",
      titlepage: Translate("pickups", "addstockpickup"),
      titlebtn: Translate("pickups", "request"),
      tab_id: null,
      source: this.$_GET("source"),
      permmisionedit:
        this.props.dataProfile.is_store || this.props.dataProfile.change_order,
      permmisionadd:
        this.props.dataProfile.is_store ||
        this.props.dataProfile.add_STOCK_pickup,
    };
    this.isExternCity = null;
    this.isExternCommune = null;
    this.submit = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.AddField = this.AddField.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const {
      data,
      ProductFinded,
      PickupUpdating,
      PickupAdded,
      dataWilaya,
      GetigCommune,
      DataCommune,
      GetingWare,
      Waredata,
    } = nextProps;

    if (ProductFinded === "2") {
      this.setState({ productnames: data.list });
    }
    if (
      this.isExternCity &&
      this.props.GetingWilaya === "1" &&
      Array.isArray(dataWilaya)
    ) {
      let wilaya = dataWilaya.find((elem) => elem[1] === this.isExternCity);
      this.handleSelect(wilaya, "city");
      this.isExternCity = null;
    }
    if (
      this.isExternCommune &&
      GetigCommune === "1" &&
      this.props.GetigCommune === "0" &&
      Array.isArray(DataCommune)
    ) {
      let commune = DataCommune.find(
        (elem) => elem.id === this.isExternCommune
      );
      this.handleSelect(commune, "commune");
      this.isExternCommune = null;
    }
    if (
      this.warehouse &&
      GetingWare === "2" &&
      Array.isArray(Waredata?.list?.results)
    ) {
      let warehouse = Waredata.list.results.find(
        (elem) => elem.id === this.warehouse
      );
      this.handleSelect(warehouse, "warehouse");
      this.warehouse = null;
    }

    if (this.submit && (PickupAdded === "3" || PickupUpdating === "3")) {
      document.getElementById("Container-root").scrollTo(0, 0);
      this.submit = false;
      this.props.SendAlert(
        "50",
        Translate(
          "alert",
          this.state.mode === "updating" ? "failpickupupdated" : "pickupfail"
        ),
        ""
      );
    }

    if (this.submit && (PickupAdded === "2" || PickupUpdating === "2")) {
      this.submit = false;
      this.props.SendAlert(
        "41",
        Translate(
          "alert",
          this.state.mode === "updating"
            ? "successpickupupdated"
            : "pickupadded"
        ),
        ""
      );
      this.props.history.push("/pickups");
    }
  }
  componentWillUnmount() {
    this.props.ClearStore("CLEAR_COMMON");
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "pickups");
    initGAPg();
    const {
      match: { params },
    } = this.props;
    let pickupIdParam = params.idpickup;
    if (pickupIdParam !== "new") {
      var targetPickup = null;
      var externTitle, FilterSource;
      const { PickupGetting, PickupsList } = this.props;
      if (PickupGetting !== "2") {
        this.props.history.push("/pickups");
      } else {
        targetPickup = PickupsList.ressuc.results.find(
          (elem) => elem.id === pickupIdParam
        );
        if (!targetPickup) {
          this.props.history.push("/pickups");
        } else {
          FilterSource = PickupsList.filter;
          this.isExternCommune = targetPickup.commune;
          externTitle = (
            <div className="StDrc">
              <Link className="StOpcVal" to={"/pickups" + FilterSource}>
                {Translate("titles", "pickups")}
              </Link>
              <div className="Inlflx StWdDst">
                {Translate("orders", "flech")}
              </div>
              <div className="Inlflx">{Translate("pickups", "editpickup")}</div>
              <div className="Inlflx StWdDst">#</div>
              <div className="Inlflx">{targetPickup.display_id}</div>
            </div>
          );
          this.isExternCity = targetPickup.wilaya;
          this.warehouse = targetPickup.warehouse_destination;
          this.setState({
            id: targetPickup.id,
            display_id: targetPickup.display_id,
            products: targetPickup.products.map((el) => ({
              ...el,
              quantity: el.quantity_demanded,
            })),
            providername:
              targetPickup.customer_name ||
              this.props.dataProfile.full_name ||
              this.props.dtStoreinfo.name,
            providerphonenumber:
              targetPickup.customer_phone || this.props.dtStoreinfo.phone,
            pickup_address: targetPickup.pickup_address,
            car_type: targetPickup.car_type,
            warehouse: { id: targetPickup.warehouse_destination },
            notetodriver: targetPickup.note_to_driver,
            status: targetPickup.status,
            titlepage: externTitle,
            titlebtn: Translate("orders", "save"),
            filtersource: FilterSource,
            mode: "updating",
          });
        }
      }
    } else {
      this.setState({
        providername: this.props.dataProfile.full_name || "",
        providerphonenumber: this.props.dtStoreinfo.phone,
        pickup_address: this.props.dtStoreinfo.address,
      });
    }
    this.props.Getwilayas();
    this.props.GetWarehouse();
    this.props.GetVehiculeTypes();
    if (this.props.ProductFinded !== "2") {
      this.props.GetAllProduct();
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleChangeProduct(e) {
    const { name, value } = e.target;
    let product = this.state.products;
    let tabn = name.split("_");
    let indx = parseInt(tabn[1]);
    if (tabn[0] === "productname") {
      let key_elem = this.state.productnames.filter(
        (elem) => elem.logistical_description === value
      )[0];
      if (key_elem) {
        product[indx - 1].product_id = key_elem.id;
        product[indx - 1].logistical_description = value;
        this.setState({
          products: product,
          notfound: false,
        });
      }
      if (!key_elem) {
        product[indx - 1].logistical_description = value;
        product[indx - 1].product_id = "";
        this.setState({
          notfound: true,
          products: product,
        });
      }
    } else {
      product[indx - 1].quantity = value;
      this.setState({
        products: product,
      });
    }
  }

  handleSelect(elem, name) {
    if (elem) {
      if (
        name === "city" &&
        ((this.state.city && this.state.city[0] !== elem[0]) ||
          !this.state.city)
      ) {
        this.setState({ commune: null });
        this.props.Getcommunes("?wilaya=" + elem[0]);
      }
    }
    this.setState({
      [name]: elem,
    });
  }
  AddField(type) {
    Event("ADD_STOCK_PICKUP", "USE_ADD_PRODUCT_BUTTON", "CLICK_EVENT");
    if (type === "product") {
      let nb = this.state.nbproducts + 1;
      let products = this.state.products;
      products.push({ product_id: "", quantity: 1 });
      this.setState({
        nbproducts: nb,
        products: products,
      });
    }
    if (type === "order") {
      let nb = this.state.nbproducts + 1;
      let orders = this.state.orders;
      orders.push("");
      this.setState({
        nbproducts: nb,
        orders: orders,
      });
    }
  }
  RemoveFieldProduct(pos) {
    Event("ADD_STOCK_PICKUP", "USE_REMOVE_PRODUCT_BUTTON", "CLICK_EVENT");
    let nb = this.state.nbproducts;
    let products = this.state.products;
    if (nb > 1) {
      products.splice(pos, 1);
      this.setState({
        nbproducts: nb - 1,
        products: products,
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();
    const { dtStoreinfo } = this.props;
    let contin = true;
    let pickup = {},
      error = {};
    pickup.store = dtStoreinfo.id;
    if (this.state.city && this.state.city[0]) {
      pickup.wilaya = this.state.city[0];
    } else {
      error.ercity = Translate("error", "eo1");
      contin = false;
    }
    if (this.state.warehouse && this.state.warehouse.id) {
      pickup.warehouse_destination = this.state.warehouse.id;
    } else {
      error.errware = Translate("error", "e15");
      contin = false;
    }
    if (this.state.vehicule && this.state.vehicule) {
      pickup.car_type = this.state.vehicule;
    } else {
      error.errvehicule = Translate("error", "e15");
      contin = false;
    }
    if (this.state.commune && this.state.commune.id) {
      pickup.commune = this.state.commune.id;
    } else {
      error.ercommune = Translate("error", "eo2");
      contin = false;
    }
    if (this.state.pickup_address) {
      pickup.pickup_address = this.state.pickup_address;
    } else {
      error.eradress = Translate("error", "eo7");
      contin = false;
    }
    if (this.state.providerphonenumber) {
      pickup.customer_phone = this.state.providerphonenumber;
    } else {
      error.erphone = Translate("error", "eo5");
      contin = false;
    }
    if (this.state.providername) {
      pickup.customer_name = this.state.providername;
    } else {
      error.ername = Translate("error", "eo4");
      contin = false;
    }

    /************ */
    if (contin) {
      this.submit = true;
      pickup.source = 1;
      if (this.state.status === 50) {
        pickup.status = 11;
      }
      pickup.note_to_driver = this.state.notetodriver;
      pickup.products = this.state.products.map((el) => ({
        ...el,
        product: el.product_id,
        quantity_demanded: el.quantity,
        quantity_provided: el.quantity,
      }));

      // Submit the request
      if (this.state.permmisionadd && !this.state.source) {
        this.props.AddPickup(pickup, "premium");
      }
    } else {
      Event(
        "ADD_STOCK_PICKUP",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(error).join(",")}]`
      );
      this.setState({
        error: error,
      });
    }
  }
  render() {
    const {
      PickupAdded,
      PickupUpdating,
      ProductFinded,
      dataAddPickup,
      dataWilaya,
      DataCommune,
      GetingWilaya,
      GetigCommune,
      GetingWare,
      Waredata,
      GettingTypes,
      VehiculesData,
      dtStoreinfo,
    } = this.props;

    let Alertfail = "";
    if ((PickupAdded === "3" || PickupUpdating === "3") && this.submit) {
      if (
        dataAddPickup &&
        dataAddPickup.response &&
        dataAddPickup.response.data
      ) {
        Alertfail = Translate("error", dataAddPickup.response.data.detail);
      }

      this.submit = false;
      this.setState({
        error: {},
      });
      this.props.SendAlert("50", Translate("alert", "pickupfail"), "a02");
    }

    let typesOptions = [];
    if (VehiculesData && VehiculesData.list)
      typesOptions = VehiculesData.list.map((vType) => ({
        text: Translate("vehiculetypes", vType.id + ""),
        val: vType.id,
      }));

    if (!this.state.permmisionadd && !this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "addneworder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    if (!this.state.permmisionedit && this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "editorder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    return (
      <ContainerPage page_title={this.state.titlepage}>
        {(PickupAdded === "1" || PickupUpdating === "1") && <Loader />}
        <div className="ClSidTh StBrdTb StBgbrds widthAdd">
          <div className="FlWd TxtCn StRedClr">{Alertfail}</div>
          <form method="post" onSubmit={this.handleSubmit}>
            {dtStoreinfo.stock_managed && (
              <ContainerPage
                subContainer
                page_title={Translate("pickups", "pickupinfos")}
                small_title={true}
              >
                {Array.isArray(this.state.products) &&
                  this.state.products.map((product, key) => {
                    return (
                      <div>
                        <div className="FlWd InFlx responsFlx flxDrc" key={key}>
                          <div className="Hlwd">
                            <SelectFormSearch
                              id={"productname_" + (key + 1)}
                              name={"productname_" + (key + 1)}
                              index={"logistical_description"}
                              option={this.state.productnames}
                              text={Translate("product", "productname")}
                              workfun={this.handleChangeProduct}
                              placeholder={
                                ProductFinded === "2"
                                  ? Translate("product", "productname")
                                  : Translate("alert", "load")
                              }
                              value={
                                this.state.products[key].logistical_description
                              }
                            />
                          </div>
                          <div className="Hlwd Mrg2 RlPs">
                            <div className="Flwd InFlx flxDrc AlgnItm">
                              <GroupeForm
                                id={"quantity_" + (key + 1)}
                                name={"quantity_" + (key + 1)}
                                placeholder={Translate("orders", "quantity")}
                                text={Translate("orders", "quantity")}
                                type={"number"}
                                value={this.state.products[key].quantity}
                                min={1}
                                workfun={this.handleChangeProduct}
                                style={{ width: "80%" }}
                              />
                              {this.state.nbproducts > 1 ? (
                                <div
                                  className="CrsPoi InFlx JstfCnt"
                                  style={{
                                    alignItems: "flex-end",
                                    margin: "5% 0% 0% 2%",
                                  }}
                                  onClick={() => this.RemoveFieldProduct(key)}
                                >
                                  {CloseSvg}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <span>{this.state.error.erproduct}</span>
                {this.state.notfound ? (
                  <div className="FlWd StdirLn">
                    <span className="CrsPoi">
                      {" " + Translate("product", "prdntfond")}
                    </span>
                    <Link to="/products/add" className="CrsPoi StBle">
                      {" " + Translate("product", "addnewproduct")}
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                <div className="FlWd StdirLn">
                  <span
                    className="StBle CrsPoi"
                    onClick={() => this.AddField("product")}
                  >
                    +{" " + Translate("orders", "addanotherproduct")}
                  </span>
                </div>
              </ContainerPage>
            )}

            <ContainerPage
              subContainer
              page_title={Translate("pickups", "providerinfos")}
              small_title={true}
            >
              <div className="FlWd InFlx responsFlx flxDrc">
                <div className="Hlwd">
                  <GroupeForm
                    id={"providername"}
                    name={"providername"}
                    placeholder={Translate("auths", "fullname")}
                    text={Translate("auths", "fullname")}
                    type={"text"}
                    value={this.state.providername}
                    workfun={this.handleChange}
                    error={this.state.error.ername}
                  />
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm
                    id={"providerphonenumber"}
                    name={"providerphonenumber"}
                    placeholder={Translate("auths", "phone")}
                    text={Translate("auths", "phone")}
                    type={"tel"}
                    pattern={"[0]{1}[0-9]{9}"}
                    value={this.state.providerphonenumber}
                    workfun={this.handleChange}
                    error={this.state.error.erphone}
                  />
                </div>
              </div>
            </ContainerPage>
            <ContainerPage
              subContainer
              withOvrl={true}
              page_title={Translate("pickups", "details")}
              small_title={true}
            >
              <div
                className="FlWd InFlx responsFlx flxDrc"
                style={{ height: "100%" }}
              >
                <div className="Hlwd">
                  <GroupeForm
                    id={"pickup_address"}
                    name={"pickup_address"}
                    placeholder={Translate("pickups", "adress")}
                    text={Translate("pickups", "adress")}
                    type={"text"}
                    value={this.state.pickup_address}
                    workfun={this.handleChange}
                    error={this.state.error.eradress}
                  />
                  <div
                    className="FlWd InFlx responsFlx flxDrc"
                    style={{ margin: "28px 0 28px 0" }}
                  >
                    <div className="Hlwd">
                      <Select
                        label={Translate("orders", "city")}
                        placholder={Translate("orders", "citySearch")}
                        search={true}
                        Options={dataWilaya}
                        fieldShow={1}
                        name="city"
                        loading={GetingWilaya === "0"}
                        value={this.state.city}
                        onChange={this.handleSelect}
                        maxSize="200px"
                      />
                      <div className="StRedClr">{this.state.error.ercity}</div>
                    </div>
                    <div className="Hlwd Mrg2">
                      <Select
                        label={Translate("orders", "district")}
                        placholder={Translate("orders", "districtSearch")}
                        search={true}
                        Options={DataCommune}
                        fieldShow={"name"}
                        name="commune"
                        loading={GetigCommune === "0"}
                        value={this.state.commune}
                        onChange={this.handleSelect}
                        maxSize="200px"
                      />
                      <div className="StRedClr">
                        {this.state.error.ercommune}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm
                    id={"notetodriver"}
                    name={"notetodriver"}
                    placeholder={Translate("orders", "notetodriver")}
                    text={Translate("orders", "notetodriver")}
                    type={"text"}
                    value={this.state.notetodriver}
                    workfun={this.handleChange}
                    textarea={true}
                  />
                </div>
              </div>
              <div
                className="FlWd InFlx responsFlx flxDrc"
                style={{ height: "100%" }}
              >
                <div className="Hlwd">
                  <SelectForm
                    id={"vehicule"}
                    name={"vehicule"}
                    option={typesOptions}
                    value={this.state.vehicule}
                    text={Translate("pickups", "suitablevehicule")}
                    loading={GettingTypes !== "2"}
                    workfun={this.handleChange}
                  />
                </div>
                <div className="Hlwd Mrg2">
                  <Select
                    label={Translate("pickups", "warehouse")}
                    placholder={Translate("pickups", "searchwarehouse")}
                    search={true}
                    Options={
                      (Waredata && Waredata.list && Waredata.list.results) || []
                    }
                    fieldShow={"name"}
                    name="warehouse"
                    loading={GetingWare !== "2"}
                    value={this.state.warehouse}
                    onChange={this.handleSelect}
                  />
                  <div className="StRedClr">{this.state.error.errware}</div>
                </div>
              </div>
            </ContainerPage>
            <span
              className="DsBlc FlWd"
              style={{
                height: "1px",
                backgroundColor: "var(--fntClr)",
                opacity: ".2",
              }}
            ></span>

            <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
              <Button BtnText={this.state.titlebtn} />
            </div>
          </form>
        </div>
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href
      .replace(window.location.hash, "")
      .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
        vars[key] = value !== undefined ? value : "";
      });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GetingWare,
    Waredata,
    GettingTypes,
    VehiculesData,
  } = state.common;
  const { ProductFinded, data } = state.product;
  const {
    PickupAdded,
    dataAddPickup,
    PickupGetting,
    PickupsList,
    PickupUpdating,
    DataUpdatePickup,
  } = state.pickups;
  const { dtStoreinfo, dataProfile } = state.user;

  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GetingWare,
    Waredata,
    GettingTypes,
    VehiculesData,
    data,
    ProductFinded,
    PickupAdded,
    dataAddPickup,
    PickupGetting,
    PickupsList,
    PickupUpdating,
    DataUpdatePickup,
    dtStoreinfo,
    dataProfile,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetWarehouse: commonActions.GetWarehouse,
  GetVehiculeTypes: commonActions.GetVehiculeTypes,
  GetAllProduct: productActions.GetAllProduct,
  AddPickup: pickupsActions.AddPickup,
  UpdatePickup: pickupsActions.UpdatePickup,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
};

const connectedAddStockPickupPage = connect(
  mapState,
  actionCreators
)(AddStockPickupPage);
export { connectedAddStockPickupPage as AddStockPickupPage };
