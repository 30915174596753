import React, { Component } from "react";
import PropTypes from "prop-types";

export default class StatsElement extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  };

  render() {
    let {subcontent}=this.props;
    return (
      <div className="TxtCn">
        <div className="BtNset StMrtp1">
          <span className="DsBlc StSmlS TxtWht" style={{fontSize: "20px",opacity: 0.9}} >
            {this.props.title}
          </span>
          <span className="StWdDst DsBlc stbold StSmlS TxtCn" style={{fontSize: "26px"}}>
                {this.props.value}
          </span>
          {subcontent && 
          <span className="DsBlc StSmlS StOpcVal TxtCn" >
            {subcontent}
          </span>
          }
        </div>
        
      </div>
    );
  }
}
