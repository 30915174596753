import React from "react";
import { useState } from "react";
import ReactTooltip from "react-tooltip";

export default function GroupeBtnSp(props) {
  const [subMenuButtons, setSubMenuButtons] = useState(
    props.data_btn.map((elem, key) => {
      if (Array.isArray(elem.subElements) && elem.svgbtn)
        return {
          id: `subMenuButton-${key}`,
          isOpen: false,
        };
      return null;
    })
  );
  ReactTooltip.rebuild();
  return (
    <>
      {Array.isArray(props.data_btn) &&
        props.data_btn.map(
          (elem, key) =>
            elem.tooltip && (
              <ReactTooltip
                key={`${elem.tooltip}-tooltip-${key}`}
                place="top"
                id={`${elem.tooltip}-tooltip-${key}`}
              >
                {elem.tooltip}
              </ReactTooltip>
            )
        )}
      <div className="MrAot" style={props.style}>
        {Array.isArray(props.data_btn) &&
          props.data_btn.map((elem, key) => (
            <>
              {Array.isArray(elem.subElements) && elem.svgbtn ? (
                <div
                  key={`subMenuButton-${key}`}
                  data-tip
                  className={
                    "Inlflx StWdDst StBrdRdS stBdp CrsPoi RlPs CrsPoi " +
                    elem.type
                  }
                  onClick={() => {
                    setSubMenuButtons((prevState) => {
                      if (prevState)
                        return prevState.map((elem2) => {
                          if (elem2?.id === `subMenuButton-${key}`) {
                            return { ...elem2, isOpen: !elem2.isOpen };
                          }

                          return elem2;
                        });
                    });
                  }}
                >
                  {elem.svgbtn}
                  {subMenuButtons.find(
                    (elem2) => elem2?.id === `subMenuButton-${key}`
                  )?.isOpen && (
                    <div
                      className="subElementGroupeBtn"
                      style={{
                        "--x-position": `0px`,
                        "--y-position": "calc(100% + 5px)",
                      }}
                    >
                      {elem.subElements.map((elem2, key) => (
                        <div
                          className="CrsPoi fntSzExSml InFlx AlgnItm"
                          key={`${elem.tooltip}-sub-element-${key}`}
                          onClick={elem2.action}
                        >
                          <div style={{ transform: "scale(0.75)" }}>
                            {elem2.svg}
                          </div>
                          {elem2.text}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                elem.svgbtn && (
                  <div
                    key={`${elem.tooltip}-tooltip-${key}`}
                    data-tip
                    data-for={`${elem.tooltip}-tooltip-${key}`}
                    className={
                      "Inlflx StWdDst StBrdRdS stBdp CrsPoi " + elem.type
                    }
                    onClick={elem.action}
                  >
                    {elem.svgbtn}
                  </div>
                )
              )}
            </>
          ))}
      </div>
    </>
  );
}
