import React from "react";
import { isArray } from "jquery";
export default function SelectFormvCommune(props) {
  const { options } = props;

  const dataMap = Array.isArray(options)
    ? options?.map((data, index) => {
        return { key: data.id, value: data.name };
      })
    : null;
  const listItems = dataMap?.map((option, index) => {
    return (
      <option key={option.key} value={option.key}>
        {option.value}
      </option>
    );
  });

  return (
    <div className="grp-fm StdirLn StFnt">
      <div className="SsGrpRm RlPs">
        <label htmlFor={props.id}>{props.text}</label>
        <div className="StAbs StLanNl StTpNl StOpcVal">{props.more}</div>
      </div>
      <div className="SsGrpRm StMrg RlPs">
        <select
          id={props.id}
          defaultValue={props.valuee}
          onChange={props.onChange}
          name={props.name}
          autoComplete="off"
          className={
            "FntfMin FlWd Fntcl RgSel StdirLn FrInp StSizLn StHgInp StBrdRd StOpcVal " +
            (props.stclass ? props.stclass : "")
          }
        >
          {props.loading && <option selected={true}>{"Loading"}</option>}
          <option value="" disabled selected>
            Select Commune
          </option>
          {listItems}
        </select>
      </div>
    </div>
  );
}
