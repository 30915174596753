import React from "react";
import { closeIcon } from "../../../_Common/IconSvg";
import "./TestWebhook.scss";
const TestWebhook = (props) => {
  const { closeModal } = props;
  return (
    <article className="testWebhook">
      <header>
        <h1>Webhook test</h1>
        <button onClick={closeModal}>{closeIcon}</button>
      </header>
      <main>
        <section>
          <p>An example webhook request has been sent</p>
          <button type="submit" onClick={closeModal}>
            Done
          </button>
        </section>
      </main>
    </article>
  );
};

export default TestWebhook;
