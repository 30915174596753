import { Translate } from "./lang/translate";

export const NEW_FEATURES=[
    {
        title: Translate("announcements","feat-exchrefund"),
        stores:"all",
        media: "video",
        link :"https://storage.googleapis.com/stores_video/Echange-remboursement%20MD%20-%20new%20(1).mp4"
    },
    {
        title: Translate("announcements","feat-pickup"),
        stores:"starter",
        media: "video",
        link :"https://storage.googleapis.com/stores_video/Statuts%20MD%20-%20Video.mp4"
    },
    {
        title: Translate("announcements","new-statuses"),
        stores:"premium",
        media: "img",
        link :"/assets/img/new-premium-statuses.png"
    },
    {
        title: Translate("announcements","new-statuses"),
        stores:"starter",
        media: "img",
        link :"/assets/img/new-starter-statuses.png"
    },
]
export const PROMOTION={
        title: Translate("announcements","promotiontitle"),
        desc: "",
        enddate: "12-01-2021",
        media: "image",
        link :"/assets/img/promotion.jpg"
}
    