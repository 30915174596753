import { notifsConstants } from '../constants';

export function notifications(state , action) {
  switch (action.type) {
    case notifsConstants.GET_NOTIF_REQUEST:
      return {
        GettingNotifs:"1",
        dataNotifs: action.payload
      };
    case notifsConstants.GET_NOTIF_SUCCESS:
      return {
        GettingNotifs:"2",
        dataNotifs: action.payload
      };
    case notifsConstants.GET_NOTIF_FAILURE:
      return {
        GettingNotifs:"3",
        dataNotifs: action.payload
      };
    case notifsConstants.UPDATE_NOTIF_REQUEST:
      return {
        UpdatingNotif:"1",
        dataUpdateNotif: action.payload
      };
    case notifsConstants.UPDATE_NOTIF_SUCCESS:
      return {
        UpdatingNotif:"2",
        dataUpdateNotif: action.payload
      };
    case notifsConstants.UPDATE_NOTIF_FAILURE:
      return {
        UpdatingNotif:"3",
        dataUpdateNotif: action.payload
      };

    case notifsConstants.UPDATE_STATUS_NOTIF_REQUEST:
      return {
        MarkingAsSeen:"1",
        dataUpdateNotif: action.payload
      };
    case notifsConstants.UPDATE_STATUS_NOTIF_SUCCESS:
      return {
        MarkingAsSeen:"2",
        dataUpdateNotif: action.payload
      };
    case notifsConstants.UPDATE_STATUS_NOTIF_FAILURE:
      return {
        MarkingAsSeen:"3",
        dataUpdateNotif: action.payload
      };
    
    case "CLEAR":
      return {}
    default:
        return {...state}
    }
}
