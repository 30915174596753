/*import React from 'react'
export default function Table(props){
    return(
        <div className="StBgbrds HdOvrfl StBrdTb">
            <table id={props.idtab && props.idtab} className={props.ntdir?"FlWd HdOvrfl":"FlWd HdOvrfl StDrc"}>
                <thead className="FlWd StSmlS FrBdEl TbClbd">
                    <tr>{Array.isArray(props.thead_elem) && props.thead_elem.map((elm,key)=>{
                        return(<td key={key}>{elm}</td>)
                    })}
                    </tr>
                </thead>
                <tbody className="FlWd StSmlS FrBdEl2 ClSidTh ">
                    {Array.isArray(props.tbody_elem) && props.tbody_elem.map((data,key)=>{
                        let len=data.length
                        let allow=(data[len-1] && data[len-1].type==="forindex") ? data[len-1].blocked : false
                        return(<tr key={key} id={typeof data[0]==="number" ? data[0] : ''} className={props.goTo && "CrsPoi"}>{
                                data.map((elem,key)=>{
                                if(key===len-1 && elem.type==="forindex"){
                                    return 
                                }
                                return(<td key={key} onClick={allow &&  allow.indexOf(key)===-1 && props.goTo?() => props.goTo(data[len-1].idaction?data[len-1].idaction: data[0]):null}>{elem}</td>)
                            })
                            }  
                        </tr>)
                    })}
                </tbody>
            </table>
        </div>
    )
}*/
import React from "react";
export default function Table(props) {
  return (
    <div className="StBgbrds HdOvrfl">
      <div className="HdOvFlwY OvfAtoX">
        <table
          id={props.idtab}
          className={(props.ntdir ? "" : "StDrc") + " StWhSnwr StdirLn FlWd"}
        >
          <thead className="FlWd StSmlS FrBdEl TxTrnf TbClbd">
            <tr
              className={
                "Stwd-stck Rgl-wdtb frCl-ss foryh_reglt " +
                (props.stickySecnd ? "frCl-scnd3" : "")
              }
            >
              {Array.isArray(props.thead_elem) &&
                props.thead_elem.map((elm, key) => {
                  return <th key={key}>{elm}</th>;
                })}
            </tr>
          </thead>
          <tbody
            className={
              "FlWd StSmlS FrBdEl2 ClSidTh " +
              (props.stickySecnd ? "frCl-scnd3" : "")
            }
          >
            {Array.isArray(props.tbody_elem) &&
              props.tbody_elem.map((data, key) => {
                let len = data.length;
                let allow =
                  data[len - 1] && data[len - 1].type === "forindex"
                    ? data[len - 1].blocked
                    : false;
                return (
                  <tr
                    key={key}
                    id={typeof data[0] === "number" ? data[0] : ""}
                    className={
                      (props.goTo ? "CrsPoi" : "") +
                      " Stwd-stck Rgl-wdtb frCl-ss2"
                    }
                  >
                    {data.map((elem, key) => {
                      if (key === len - 1 && elem.type === "forindex") {
                        return undefined;
                      }
                      return (
                        <td
                          key={key}
                          onClick={
                            allow && allow.indexOf(key) === -1 && props.goTo
                              ? () =>
                                  props.goTo(
                                    data[len - 1].idaction
                                      ? data[len - 1].idaction
                                      : data[0]
                                  )
                              : null
                          }
                        >
                          {elem}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
