import React from 'react'
import PropTypes from 'prop-types'
import { Translate } from '../../../utils/lang/translate'
import CountDown from '../_Common/CountDown'
import { date_difference } from '../../../utils/functions'

function PromotionCountDown(props) {
    const distance=date_difference(new Date(props.expirationDate),new Date())
    return (
        distance.difference>0 ? 
        <div className="FlWd Inlflx Stclmnf AlgnItm JstfCnt">
            <h2>{Translate("announcements","promotionends")}</h2>
            <CountDown 
            expirationDate={props.expirationDate}

            />
            
        </div>:
        <div></div>
    )
}

PromotionCountDown.propTypes = {
    expirationDate: PropTypes.string.isRequired

}

export default PromotionCountDown

