import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import ContainerPage from '../_Common/ContainerPage';
import { connect } from 'react-redux';
import { FeatureLoked } from '../_Common/FeatureLoked';
import IconButton from '../_Common/IconButton';
import { CheckSvg, CloseSvg, FilterSvg2, FlechDwnSvg, OutSvg, PrintSvg, RefreshSvg } from '../_Common/IconSvg';
import Cookies from 'js-cookie';
import InlinedElement from '../_Common/InlinedElement';
import { NumberParam, StringParam, decodeDelimitedNumericArray, encodeDelimitedNumericArray, encodeQueryParams, useQueryParams } from 'use-query-params';
import { FormatDate, Translate } from '../../../utils/lang/translate';
import CheckBoxForm from '../../_Common/CheckBoxForm';
import { refund_status_list } from '../../../constants/refund.constants';
import { EmptyScreen } from '../_Common/EmptyScreen';
import CustomTable, { TableColumnProps } from '../_Common/CustomTable/CustomTable';
import PaginationBar from '../_Common/PaginationBar';
import { alertActions, exchangeActions } from '../../../actions';
import { RequestStatus } from '../../../types';
import { ExchangeRefundListItem } from './types';
import { stringify } from 'query-string';
import DropedList from '../_Common/DropedList';
import { RouterChildContext, useHistory, useParams } from 'react-router-dom';
import { exchange_status_list, userConstants } from '../../../constants';
import GroupeBtn from '../_Common/GroupeBtn';
import { Event } from "../_Common";
import ExchangePrintModal from './ExchangePrintModal';

const lang = Cookies.get("lang")

const CommaArrayParam = {
  encode: (array: (number | null)[] | null | undefined) =>
    encodeDelimitedNumericArray(array, ','),

  decode: (arrayStr: string | (string | null)[] | null | undefined) =>
    decodeDelimitedNumericArray(arrayStr, ',')
};

interface ExchangeRefundPageProps {
  dataProfile: any,
  GettingExchanges: RequestStatus,
  dataExchanges: { 
    count: number,
    results: ExchangeRefundListItem [],
    next: string | null,
    previous: string | null
  },
  SendAlert: (code: string, text: string, action: string) => void,
  GetExchanges: (filter?: string) => void,
  GenerateExchangesBordureauStarter: (exchanges_ids: string[], all_created: boolean) => void,
  dtStoreinfo: any,
  UpdateShowExchangePopups: (value: number) => void,
  UpdatingExchangePopups: RequestStatus,
  postingbordereau: RequestStatus,
  ClearBordereauState: () => void
}

const ExchangeRefundPage = ({
  dataProfile,
  GettingExchanges,
  dataExchanges,
  GetExchanges,
  GenerateExchangesBordureauStarter,
  dtStoreinfo,
  UpdateShowExchangePopups,
  UpdatingExchangePopups,
  SendAlert,
  postingbordereau,
  ClearBordereauState
}: ExchangeRefundPageProps) => {


  const { type } = useParams<{ type: "1" | "2" }>();

  const history = useHistory<RouterChildContext['router']['history']>();

  const FilterRef = useRef<HTMLDivElement>(null);
  const statusFilterRef= useRef<HTMLDivElement>(null);

  const [displayId, setDisplayId] = useState<string>("");
  const [refresh, setRefresh] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [exchangeToPrint, setExchangeToPrint] = useState<string | null>(null);

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    display_id_str__icontains: StringParam,
    status__in: CommaArrayParam,
    type: NumberParam	
  });

  const handleDisplayIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDisplayId(e.target.value);
  };

  const handleDisplayIdSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if(query.display_id_str__icontains) {
      setQuery(prev => ({ ...prev, display_id_str__icontains: undefined, page: undefined }));
      setDisplayId("");
    }
    else setQuery(prev => ({ ...prev, display_id_str__icontains: displayId, page: undefined }));
  };

  const clearFilters = () => {  
    setQuery(prev => ({ ...prev, status__in: undefined, page: undefined }));
  };

  const onChangeStatus = (status: number) => {
    setQuery(prev => {
      if(prev.status__in){
        if(prev.status__in.includes(status)) {
          if(prev.status__in.length === 1) return { ...prev, status__in: undefined, page: undefined };
          else return { ...prev, status__in: prev.status__in.filter(item => item !== status), page: undefined };
        }
        else return { ...prev, status__in: [...prev.status__in, status], page: undefined }
      }else return { ...prev, status__in: [status], page: undefined };
    });
  };

  const onChangePage = (pageNumber: number) => {
    setQuery(prev => ({ ...prev, page: pageNumber }));
  }

  const refreshPage = () => {
    setRefresh(prev => !prev)
  };

  const handlePrintActionClick = (exchangeId: string) => {
    Event("EXCHANGES_PAGE", "CLICK_PRINT_EXCHANGE_RETURN_BUTTON", "CLICK_EVENT");
    if(
      dtStoreinfo?.show_exchange_popups === userConstants.SHOW_BOTH_EXCHANGE_POPUPS
      || dtStoreinfo?.show_exchange_popups === userConstants.SHOW_ONLY_EXCHANE_MODAL_POPUP
    ){
      setExchangeToPrint(exchangeId);
      setShowPrintModal(true);
    }else{
      GenerateExchangesBordureauStarter([exchangeId], false);  
    }
  }

  const handleClickOutsideFilter = function(event: MouseEvent) {
    if (
      FilterRef &&
      FilterRef.current &&
      !FilterRef.current.contains(event.target as Node)
    ) {
      let clsList = document.getElementById("Filter_container");
      if (clsList && !clsList.classList.value.includes("hidElem2")){
        clsList?.classList.add("hidElem2");
      }
    }
  };

  const ShowFilter = function() {
    let clsList = document.getElementById("Filter_container");
    if (clsList && clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", handleClickOutsideFilter);
    } else {
      clsList?.classList.add("hidElem2");
      document.removeEventListener("mousedown", handleClickOutsideFilter);
    }
  };

  const ControlFilter = (filterId: string, ref: React.RefObject<HTMLDivElement>) => {
    let clsList = document.getElementById(filterId);
    console.log(ref)
    if(clsList){
      if (clsList.classList.value.indexOf("hidElem3") !== -1) {
        clsList.classList.remove("hidElem3");
        clsList.style.maxHeight = "400px";
        document.addEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      } else {
        clsList.style.maxHeight = "0px";
        clsList.classList.add("hidElem3");
        document.removeEventListener(
          "mousedown", 
          (e) => {
            if (
              ref &&
              ref.current &&
              !ref.current.contains(e.target as Node)
            ) {
              let clsList = document.getElementById(filterId);
              if(clsList && !clsList.classList.value.includes("hidElem3")){
                clsList.style.maxHeight = "0px";
                clsList.classList.add("hidElem3");
              }
            }
          }
        );
      }
    }
  };


  const table_cols: TableColumnProps<ExchangeRefundListItem, keyof ExchangeRefundListItem>[] = [
    {
      key: "display_id_str",
      title: Translate("product", "ID"),
      renderItem: (item) => (
        <div 
          className="CrsPoi"
          style={{ paddingRight: "20px" }} 
          onClick={() => history.push(`/exchange-refund/${item.id}`)}
        >
          {item.display_id_str}
        </div>
      ),
    },
    {
      key: "returned_products",
      title: Translate("exchanges", "returnedproducts"),
      renderItem: (item) => (
        item.returned_products.length > 1 ?
          <div className="CrsPoi">
            <DropedList
              title={
                <div className="InFlx AlgnItm">
                  <span>{item.returned_products.length}</span>
                  <span className="StPaddingVal">
                    {Translate("titles", "product")}
                  </span>
                  <span className="DsPlCnt">{FlechDwnSvg}</span>
                </div>
              }
              dataDrop={item.returned_products.map((product, index) => (
                <div key={index}>{`(${product.quantity}) ${product.logistical_description}`}</div>
              ))} 
            />  
          </div>
        :
          item.returned_products.length > 0
          ?
            <div>
              {`(${item.returned_products[0].quantity}) ${item.returned_products[0].logistical_description}`}
            </div>
          : null
      )
    },
    {
      key: "status",
      title: Translate("orders", "status"),
      renderItem: (item) => (
        <div 
          style={{ padding: "6px 15px" }}
          className={`CrsPoi StWht StBrdRd HdOvrfl TxtCn exchange_${item.status}`}
          onClick={() => history.push(`/exchange-refund/${item.id}`)}
        >
          {Translate("refunds", item.status)}
        </div>
      ),
      renderLoading: () => (
        <div 
          className="StBrdRd HdOvrfl animate-pulse" 
          style={{ 
            width: "150px", 
            height: "26px", 
            backgroundColor: "var(--bdgclInp)" 
          }}
        />
      )
    },
    {
      key: "wilaya",
      title: Translate("orders", "district") + " / " + Translate("product", "wilaya"),
      renderItem: (item) => (
        <div
          className="CrsPoi"
          onClick={() => history.push(`/exchange-refund/${item.id}`)}
        >
          {`${item.commune} / ${item.wilaya}`}
        </div>
      )
    },
    {
      key: "created_at",
      title: Translate("orders", "creationdate"),
      renderItem: (item) => (
        <div 
          className="CrsPoi"
          onClick={() => history.push(`/exchange-refund/${item.id}`)}
        >
          { FormatDate(item.created_at) }
        </div>
      )
    }
  ];

  const exchanges_table_cols: TableColumnProps<ExchangeRefundListItem, keyof ExchangeRefundListItem>[] = [
    ...table_cols,
    {
      key: "times_printed",
      title: Translate("product", "action"),
      renderItem: (item) => (
        <div className="InFlx" style={{ padding: "0 20px" }}>
          <GroupeBtn 
            data_btn={
              item.status === exchange_status_list.EXCHANGE_STATUS_CREATED ?
              [
                {
                  type: "BDgInpc",
                  action: () => handlePrintActionClick(item.id),
                  svgbtn: PrintSvg,
                  tooltip: Translate("tooltips", "printexchangereturnslip"),
                  notif: item.times_printed === 0
                }
              ] : []
            }
          />
        </div>
      ),
      renderLoading: () => (
        <div 
          className="StBrdRd MrAot HdOvrfl animate-pulse" 
          style={{ 
            width: "60px", 
            height: "32px", 
            backgroundColor: "var(--bdgclInp)" 
          }}
        />
      )
    }
  ]

  useEffect(() => {
    
    const { page, display_id_str__icontains, status__in } = query;

    const encodedQuery = encodeQueryParams({
      page: NumberParam,
      display_id_str__icontains: StringParam,
      status__in: CommaArrayParam,
      type: NumberParam	
    }, { 
      page, 
      display_id_str__icontains, 
      status__in, 
      type: isNaN(parseInt(type)) ? 1 : parseInt(type) 
    });
  
    GetExchanges(`?${stringify(encodedQuery)}`); 
  }, [query.page, query.display_id_str__icontains, query.status__in, type, refresh]);

  useEffect(() => {

    if(GettingExchanges === "3"){
      SendAlert("50", Translate("exchanges", "failedtofetchexchanges"), "");
    };

    if(UpdatingExchangePopups === "3"){
      SendAlert("50", "failed to update", "");
    };

    if(postingbordereau === "1") {
      SendAlert("31", Translate("alert", "exportreqstsent"), "");
    } else if(postingbordereau === "2") {
      SendAlert("41", Translate("alert", "succesexprt"), "");
    } else if(postingbordereau === "3") {
      SendAlert("50", Translate("alert", "tryagain"), "");
    };

    return () => ClearBordereauState();

  }, [GettingExchanges, UpdatingExchangePopups, postingbordereau]);

  return (
    <ContainerPage
      page_title={
        type === "1"
        ? Translate("refunds", "refunds")
        : Translate("exchanges", "exchanges")
      }
    >
      {
        (dataProfile.is_store || dataProfile.view_order)
        ?
          <div>
            <div
              className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc"
              style={{ height: "48px", padding: "7px 0", top: 0 }}
            >
              <div role="filter-data" className="FlHg InFlx StwdMaxC">
                <div
                  className="FlHg StMrtg2 InFlx AlgnItm"
                  ref={FilterRef}
                >
                  <IconButton 
                    clickHandler={ShowFilter} 
                    icon={FilterSvg2} 
                    className="BdgBlcl"
                  />
                  <div
                    id="Filter_container"
                    className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2"
                    style={{ width: "350px" }}
                  >
                    <div id="Sub_Container_filter">
                      <div style={{ marginTop: "8px" }}>
                        <div className="RlPs">
                          <InlinedElement
                            secnd={
                              <strong>{Translate("orders", "filter")}</strong>
                            }
                            leftElm={
                              <div
                                onClick={ShowFilter}
                                className="InFlx CrsPoi IcnSizSm"
                              >
                                {CloseSvg}
                              </div>
                            }
                            style={{ width: "100%", padding: "0px" }}
                          />
                        </div>
                        {
                          Object.keys(query).filter((key) => {
                            if(key === "page" || key === "display_id_str__icontains" || key === "type") return false;
                            if(key === "status__in" && !query.status__in ) return false;
                            else return true; 
                          }).length > 0
                          &&
                          <span
                            className="CrsPoi StBle DsBlc StdirLn"
                            onClick={clearFilters}
                          >
                            {Translate("orders", "resetfilter")}
                          </span>
                        }
                      </div>
                      <div
                        ref={statusFilterRef}
                        className="BdgClN StBrdRdS StPdsml SmMarS1"
                      >
                        <div
                          className="StHeivl RlPs CrsPoi"
                          onClick={() => ControlFilter("Status_Container", statusFilterRef)}
                        >
                          <InlinedElement
                            secnd={
                              <strong>
                                {
                                  type === "1" 
                                  ? Translate("refunds", "refundstatus")
                                  : Translate("exchanges", "exchangestatus")}
                              </strong>
                            }
                            leftElm={
                              <div className="InFlx">{FlechDwnSvg}</div>
                            }
                            style={{ width: "100%", padding: "0px" }}
                          />
                        </div>
                        <div
                          id="Status_Container"
                          className="hidElem3 stTranEs"
                          style={{ overflowY: "auto" }}
                        >
                          <div>
                          {
                            Object.values(
                              type === "1"
                              ? refund_status_list
                              : exchange_status_list
                            )
                            .map((value, index) => (
                              <CheckBoxForm
                                key={index}
                                id={"S" + value}
                                name={"S" + value}
                                text={Translate(type === "1" ? "refunds" : "exchanges", value)}
                                workfun={() => {
                                  onChangeStatus(value);
                                }}
                                check={query.status__in?.includes(value)}
                              />
                            ))
                          }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form onSubmit={handleDisplayIdSubmit}>
                  <div className="FlHg StBrdRdS ClSidTh">
                    <InlinedElement
                      style={{ padding: "0" }}
                      secnd={
                        <div>
                          <input
                            type="text"
                            autoComplete="off"
                            onChange={handleDisplayIdChange}
                            name="filterbyid"
                            style={{ width: "300px", maxWidth: "30vw" }}
                            className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                            value={query.display_id_str__icontains ?? displayId}
                            placeholder={"Search by id or external id"}
                            disabled={false}
                          />
                        </div>
                      }
                      leftElm={
                        <button
                          className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                          style={{ padding: "10px" }}
                        >
                          { query.display_id_str__icontains ? OutSvg : CheckSvg }
                        </button>
                      }
                    />
                  </div>
                </form>
              </div>
              <div className="FlWd">
                <div
                  className="InFlx FlHg"
                  style={
                    lang === "ar" ?
                    { gap: "12px", marginRight: "20px" }
                    : { gap: "12px", marginLeft: "20px" }
                  }
                >
                  <IconButton
                    clickHandler={refreshPage}
                    icon={RefreshSvg}
                  />
                </div>
              </div>
            </div>
            <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">
              {
                query.status__in && query.status__in.length > 0
                &&
                <>
                  <div className="stBdp StSmlS StwdMaxC">
                    {
                      type === "1"
                      ? Translate("refunds", "refundstatus")
                      : Translate("exchanges", "exchangestatus")
                    }
                  </div> 
                    {
                      query.status__in?.map((status, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            onChangeStatus(status ?? -1);
                          }}
                          className="stBdp CrsPoi SthgMaxC"
                        >
                          <InlinedElement
                            style={{
                              border: "2px solid #3498DB",
                              padding: "3px 6px",
                              backgroundColor: "#3498DB35",
                              borderRadius: "5px",
                            }}
                            secnd={
                              <div className="IcnSiz" style={{ zoom: ".48" }}>
                                {CloseSvg}
                              </div>
                            }
                            first={
                              <div className="StSmlS StwdMaxC">
                                {Translate(type === "1" ? "refunds" : "exchanges", status)}
                              </div>
                            }
                          />
                        </div>
                      ))
                    }
                </>
              }
            </div>
            {
              (GettingExchanges === "2" && dataExchanges.results.length === 0)
              ?
                <EmptyScreen
                  titleGlb={
                    type === "1"
                    ? Translate("refunds", "norefunds")
                    : Translate("exchanges", "noexchange")
                  }
                  TextShow={
                    type === "1"
                    ? Translate("refunds", "norefundsyet")
                    : Translate("exchanges", "noexchangeyet")
                  }
                  PictShow={"/assets/img/exchange.png"}
                  actionBtns={
                    Object.keys(query).filter(key => {
                      if(key === "page" || key === "type") return false; 
                      else if(key === "status__in" && !query.status__in) return false;
                      else if(key === "display_id_str__icontains" && !query.display_id_str__icontains) return false;
                      else return true;
                    }).length > 0
                    ?
                      [
                        {
                          text: Translate("orders", "resetfilter"),
                          to: `/exchange-refund-list/${type}`,
                        }
                      ]
                    :
                      null
                  }
                />
              :
                <>
                  <CustomTable 
                    columns={
                      type === "1"
                      ? table_cols
                      : exchanges_table_cols
                    } 
                    data={
                      GettingExchanges === "2"
                      ? dataExchanges.results
                      : []
                    } 
                    loading={GettingExchanges === "1"}
                    border={false}
                    sticky={true}
                  />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span className="d-large">
                        {
                          Translate("orders", "showing") +
                          " " +
                          ((query.page ?? 1) * 20 - 19) +
                          " - " +
                          (
                            (query.page ?? 1) * 20 < (dataExchanges?.count ?? 0)
                            ? (query.page ?? 1) * 20
                            : dataExchanges?.count ?? 0
                          ) +
                          " " +
                          Translate("orders", "of") +
                          " " +
                          dataExchanges?.count ?? 0
                        }
                      </span>
                      <PaginationBar
                        className="StAutMr"
                        NbPage={Math.trunc((dataExchanges?.count ?? 0) / 20) + 1}
                        currentPage={query.page ?? 1}
                        ChangePage={onChangePage}
                        blockNext={dataExchanges?.next}
                        blockPrev={dataExchanges?.previous}
                      />
                    </div>
                  </div>
                  { 
                    (showPrintModal && exchangeToPrint)
                    &&
                    <ExchangePrintModal
                      onClose={() => setShowPrintModal(false)}
                      onPrint={
                        (neverShowAgain: boolean) => {
                          GenerateExchangesBordureauStarter([exchangeToPrint], false);
                          if(neverShowAgain){
                            if(dtStoreinfo?.show_exchange_popups === userConstants.SHOW_BOTH_EXCHANGE_POPUPS){
                              UpdateShowExchangePopups(1);
                            }
                            if(dtStoreinfo?.show_exchange_popups === userConstants.SHOW_ONLY_EXCHANE_MODAL_POPUP){
                              UpdateShowExchangePopups(3);
                            }
                          }
                        }
                      }
                    />
                  }
                </>
            }
          </div>
        :
          <FeatureLoked/>
      }
    </ContainerPage>
  );
}
 
function mapState(state: any) {
  const { dataProfile, dtStoreinfo } = state.user;
  const { dataExchanges, GettingExchanges, UpdatingExchangePopups } = state.exchanges;
  const { postingbordereau } = state.orders;

  return { 
    dataProfile, 
    dataExchanges,
    GettingExchanges,
    dtStoreinfo,
    UpdatingExchangePopups,
    postingbordereau
  };
};

const actionCreators = {
  GetExchanges: exchangeActions.GetExchanges,
  SendAlert: alertActions.SendAlert,
  GenerateExchangesBordureauStarter: exchangeActions.GenerateExchangesBordureauStarter,
  UpdateShowExchangePopups: exchangeActions.UpdateShowExchangePopups,
  ClearBordereauState: exchangeActions.ClearBordereauState
}

const connectedExchangeRefundPage = connect(mapState, actionCreators)(ExchangeRefundPage);

export { connectedExchangeRefundPage as ExchangeRefundPage };