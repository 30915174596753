import React,{Component} from 'react'
import {Translate} from '../../../utils/lang/translate'
class FeatureLoked extends Component{
    render(){
        return(
            <div className="FlHg FlWd">
                <div className="FlHg FlWd AlgnItm JstfCnt InFlx">
                    <div className="TxtCn">
                        <img src="/assets/img/Locked.png" alt="under_dev_img" width="350"/>
                        <div className="TxtCn">
                            <h2>{Translate('titles','eaturelock')}</h2>
                            <p>{Translate('titles','txtlooked')}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    
}
export { FeatureLoked };