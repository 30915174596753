import React, { ReactNode } from "react";
import "./CustomTable.css";
import Cookies from "js-cookie";

const lang = Cookies.get("lang"); 

export interface TableColumnProps <T, K extends keyof T> {
  key: K,
  title: string,
  render?: () => ReactNode,
  renderItem?: (item: T) => ReactNode,
  renderLoading?: () => ReactNode,
}

interface CustomTableProps<T, K extends keyof T> {
  data: T[],
  columns: TableColumnProps<T, K>[],
  loading: boolean,
	emptyMessage?: string,
  border?: boolean,
  sticky?: boolean
}

const CustomTable = <T, K extends keyof T>({
  data,
  columns,
  loading,
	emptyMessage,
  border = true,
  sticky = true
}: CustomTableProps<T, K>) => {
  return (
    <div className="FlWd" style={{ border: border ? "1px solid gray" : "0px solid gray", overflowX: "auto", borderRadius: "4px" }}>
      <table className="custom-table-wrapper">
        <thead className="custom-table-header">
          <tr className={`${border ? "custom-table-row" : ""}`} style={{ whiteSpace: "nowrap" }}>
            {
              columns.map((column, index) => (
                <th 
                  key={index} 
                  className={`
                    custom-table-cell
                    ${(sticky && index === columns.length - 1) ? `StPs ${lang === "ar" ? "StlfNl" : "StRgNl"}` : ""}
                    ${(sticky && index === 0) ? `StPs ${lang === "ar" ? "StRgNl" : "StlfNl"}` : ""}
                  `} 
                  style={{ textAlign: index === columns.length - 1 ? "center": "start" }}
                > 
                  { column.render ? column.render() : column.title } 
                </th>
              ))
            }
          </tr>
        </thead>
        <tbody className="custom-table-body">
          {
            loading
            ?
              [0, 1, 2, 3].map((_, index) => (
                <tr key={index} className="custom-table-row">
                  {
                    columns.map((column, index) => (
                      <td 
                        key={index} 
                        className={`
                          custom-table-cell
                          ${(sticky && index === columns.length - 1) ? `StPs ${lang === "ar" ? "StlfNl" : "StRgNl"}` : ""}
                          ${(sticky && index === 0) ? `StPs ${lang === "ar" ? "StRgNl" : "StlfNl"}` : ""}
                        `}
                      > 
                        { 
                          column.renderLoading ? 
                            column.renderLoading() 
                          :
                            <div className="table-cell-loading-skeleton animate-pulse"/> 
                        } 
                      </td>
                    ))  
                  }
                </tr>
              ))
            :
              data.length > 0
              ?
								data.map((item, index) => (
                	<tr key={index} className="custom-table-row" style={{ whiteSpace: "nowrap" }}>
                  	{
                    	columns.map((column, index) => (
                      	<td 
                          key={index} 
                          className={`
                            custom-table-cell
                            ${(sticky && index === columns.length - 1) ? `StPs ${lang === "ar" ? "StlfNl" : "StRgNl"}` : ""}
                            ${(sticky && index === 0) ? `StPs ${lang === "ar" ? "StRgNl" : "StlfNl"}` : ""}
                          `}
                        > 
                        	{ 
                          	column.renderItem ? 
                            	column.renderItem(item) 
                          	: 
                          	item[column.key] as ReactNode
                        	} 
                      	</td>
                    	))  
                  	}
                	</tr>
              	))
							:
								<tr className={border ? "custom-table-row" : ""} style={{ whiteSpace: "nowrap" }}>
									<td className="custom-table-cell" colSpan={columns.length} style={{ textAlign: "center" }}>
										{emptyMessage ?? "No data to be shown!"}
									</td>
								</tr>
          }
        </tbody>
      </table>
    </div>
  );
}
 
export default CustomTable;