
export const whichWilayaHasPermissionToStopDesk = (code: any) => {
  return (
    code === 16 ||
    code === 1 ||
    code === 2 ||
    code === 3 ||
    code === 4 ||
    code === 5 ||
    code === 6 ||
    code === 7 ||
    code === 12 ||
    code === 13 ||
    code === 15 ||
    code === 17 ||
    code === 19 ||
    code === 23 ||
    code === 25 ||
    code === 30 ||
    code === 31 ||
    code === 39 ||
    code === 45 ||
    code === 47
  );
};

export const checkUserCanCreateOrderWithoutProduct = (id: string) => {
  return (
    id === "82bc5c9b-c65d-46ea-b426-c1237d83238e" ||
    id === "12895c73-990c-475b-8df6-533eaa9ea501" ||
    id === "3a2855aa-9319-481e-ac50-164083b8a297"
  );
};

export const getParamsFromUrl = (param: string) => {
  let vars: { [key: string]: string } = {};
  window.location.href
    .replace(window.location.hash, "")
    .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, (m, key, value) => {
      vars[key] = value ?? "";
      return "";
    });
  if (param) {
    return vars[param] ?? null;
  }
  return vars;
};

export const checkPhoneNumberValidity = (phoneNumber: string) => {
  const phoneNumberRegex = new RegExp(
    `^((0|\\+213)((5|6|7)(\\d){8}|(49|27|29|32|33|34|25|26|37|43|46|21|23|36|48|38|31|45|35|41|24)(\\d){6}))|((\\+216)?(((2|5|6|7|9)\\d)|(46))(\\d){6})$`
  );
  return phoneNumberRegex.test(phoneNumber);
};

export const getUnfCountFilters = (filter: string) => {
  return [
    getApprovedUnfFilter(filter),
    getCancelledUnfFilter(filter),
    getCallbackUnfFilter(filter),
    getPostponedUnfFilter(filter),
  ];
};

const getApprovedUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "1");
  urlParams.delete("is_callback");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getCancelledUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "2");
  urlParams.delete("is_callback");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getCallbackUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "0");
  urlParams.set("is_callback", "True");
  urlParams.delete("postponed");
  return "?" + urlParams.toString();
};

const getPostponedUnfFilter = (filter: string) => {
  const urlParams = new URLSearchParams(filter);
  urlParams.set("status", "0");
  urlParams.delete("is_callback");
  urlParams.set("postponed", "True");
  return "?" + urlParams.toString();
};

export const getCurrentDeliveriesArray = (
  num_deliveries: number, 
  deliveries_per_tier: number[]
): number[] => {

  let current_deliveries_array: number[] = [];
  let remaining = num_deliveries;

  deliveries_per_tier.forEach((value) => {
    if(value > remaining){
      current_deliveries_array = [...current_deliveries_array, remaining];
      remaining = 0;
    }else{
      if(remaining > 0){
        current_deliveries_array = [...current_deliveries_array, value];
        remaining -= value;
      }else{
        current_deliveries_array = [...current_deliveries_array, 0];
      }
    }
  });

  return current_deliveries_array;
};
