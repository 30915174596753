import React from 'react';

type CustomRadioButtonProps = {
  isSelected: boolean,
  handler?: () => void
}

const CustomRadioButton = ({ isSelected, handler }: CustomRadioButtonProps) => {
  
  return (
    <div
      className={`custom-radio-button ${isSelected ? 'selected' : ''}`}
      onClick={handler ? handler :  () => {}}
    >
      <div className="custom-radio-inner" />
    </div>
  );
};

export default CustomRadioButton;
