const CANARIES_STORES=[
    "a0c9547e-5e3f-4714-99ba-1bf0b26cca0c", // SOQ DZ
    "9b0e7c62-da12-4ad1-9c42-7c9450f425db", // SAHLA SHOP DZ
    "28ee606f-be98-4033-99ba-fdb6fc72cd01", // Nechri Online
    "b18ddddc-4633-4227-93b7-7e869fa810a9", // Buy in DZ 2
    "5e6c5643-2c7a-438c-8290-6bf737232f57", // BirkhademStore
    "da7bf687-ca32-4496-8eb0-d66024ec738d", // Beautilo
    "d6fa64b9-a9e6-4912-aa20-a8d0ddf77e25", // Partner
    "b93865df-93a0-4bf3-8dc7-f18468d84f5e", // Musk
    "544756fb-69da-42aa-a627-2aa6a85258e9", // Algerian store
    "86017627-d126-49b9-b5c1-faef7556e13b", // My shop dz
    "8acda2e6-f0c7-404c-84db-0742c78f25ad", // Alpha premium
    "e4e1df12-df1e-4ac1-b6c1-82c74aff886f", // Tera store
    "c0df8e55-bccf-4406-842e-5ed18e78c5b6", // Shopping Club
    "f36b100f-2ec0-4472-9ae5-94f582895af8", // Trendelle
    "93adcf92-5427-440f-b81c-9965de39df79", // Dania shop
    "9b355f8f-2591-47fd-9cde-8519bdd36d4e", // Galaxy shop
    "7ff0070b-f99f-4a42-9f46-e692293d6742", // Soqna shop
    "c9bc23b0-a8f0-4ccd-aabd-ab0c41ab8dd7", // Wts
    "875bb3a1-c95c-49c1-9c95-f5ea6b7af69d", // OneShop
    "d0404d98-1c91-4f83-bd73-8c5951b4ce55", // Japoni Shoping
    "9fec7914-48c5-4e28-ba59-0159cb4e8545", // Bio Belle
    "c8371799-76f4-42eb-9885-fa6f3e9a9c0e", // Morih
    "5e6f64ba-2405-4626-b1fd-7ee1aba6381b", // Boutique Shop
    "4787ca8b-10f0-4c52-a8ec-15d8b82148eb", // Dzairskin
    "f4389018-202f-48da-99ba-e4de20c573b4", // DmShop
    "f04373ec-d314-4277-b71e-1a9c0122a157", // Faystore
    "3a2855aa-9319-481e-ac50-164083b8a297", // Ardis

]

export function is_canary_store(storeId){
    return CANARIES_STORES.indexOf(storeId)>-1
}
const WINNERS_STORES=[
    "b93865df-93a0-4bf3-8dc7-f18468d84f5e", // Musk
    "efe91431-3d16-4d1a-a680-7fec4e06c8d7", // DZ-Beauty
    "8acda2e6-f0c7-404c-84db-0742c78f25ad", // Alpha premium
    "a0c9547e-5e3f-4714-99ba-1bf0b26cca0c", // SOQ DZ
    "b60851e8-e195-4481-a8d1-6676663289f5", // Satis
    "9fec7914-48c5-4e28-ba59-0159cb4e8545", // Bio Belle
    "9b0e7c62-da12-4ad1-9c42-7c9450f425db", // SAHLA SHOP DZ
    "4787ca8b-10f0-4c52-a8ec-15d8b82148eb", // Dzairskin
    "59d6bfc8-da37-4e04-9f94-d51ac5a816c5", // Kayeen
    "1196bb70-01fb-4eb5-87b3-87fc5f8278e3", // Clique Boutique
    "93adcf92-5427-440f-b81c-9965de39df79", // Dania shop
    "ad314d48-c4d0-4102-a0c3-2ce76f9173bd", // Faystore(dev)
    "3a2855aa-9319-481e-ac50-164083b8a297", // Ardis

]

export function is_winner_store(storeId){
    return WINNERS_STORES.indexOf(storeId)>-1
}