import React from "react";
import { DotSVg } from "./IconSvg";
import {FormatDate} from '../../../utils/lang/translate'

export default function TimeLineItem(props) {
  return (
    <React.Fragment>
      {props.timeline.map((elem, key) => {
        return (
          <div key={key} className="InFlx AlgnItm FlWd RlPs">
            <div className={"StHgVls20 timeline_stat_"+elem.status}>
              {DotSVg}
            </div>
            <div>{FormatDate(elem.date)}</div>
          </div>
        );
      })}
    </React.Fragment>
  );
}
