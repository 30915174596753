import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate, FormatDate } from "../../../utils/lang/translate";
import Table from "../_Common/Table";
import { pickupsActions, searchActions } from "../../../actions";
import {
  PackageSvg,
  LocationSvg,
  PersonSvg,
  CallSvg,
  ManagentSvg,
  WaitingSvg,
  DelivredSvg,
  CancledSvg,
  PostpondSvg,
  AcceptedSvg,
  StoreSvg,
  MoneySvg,
} from "../_Common/IconSvg";
import ListDetailIcon from "../_Common/ListDetailIcon";
import { Link } from "react-router-dom";
import Loader from "../../_Common/Loader";
import BtnLg from "../_Common/BtnLg";
import TabShow from "../_Common/TabShow";
import { initGAPg, Event } from "../_Common";
class StockPickupDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titlepage: (
        <Link className="StOpcVal" to="/pickups">
          {Translate("titles", "pickups")}
        </Link>
      ),
      productStr: [],
      products: [],
      deliveryprice: 0,
      maxdate: "",
      adress: "",
      clientname: "",
      providerphone: "",
      deliverystatus: "",
      id: "",
      status: "",
      pagesource: 1,
      alertdelet: null,
      agent: null,
      created_at: null,
      recived_by_maystro_at: null,
      shipped_by_agent_at: null,
    };
    this.IndexState = 0;
    this.GotToEdit = this.GotToEdit.bind(this);
    this.removeOrder = this.removeOrder.bind(this);
  }
  ConcatProduStr(products) {
    return (
      Array.isArray(products) &&
      products.map((elem) => {
        return [
          <div className="wharp_spce">
            ({elem.quantity_demanded}){elem.logistical_description}
          </div>,
        ];
      })
    );
  }
  componentWillReceiveProps(nextProps) {
    const {
      match: { params },
      PickupGetting,
      PickupsList,
      PickupOrdersGetting,
      PickupOrders,
    } = nextProps;
    if (this.props.PickupGetting === "1" && PickupGetting === "2") {
      let idpickup = params.idpickup;
      if (idpickup) {
        alert("");
        let pickup = PickupsList.ressuc.results.filter(
          (elem) => elem.display_id === idpickup
        )[0];
        if (pickup) {
          this.setState({
            titlepage: (
              <div className="StDrc">
                <Link className="StOpcVal" to={"/pickups" + PickupsList.filter}>
                  {Translate("titles", "pickups")}
                </Link>
                <div className="Inlflx StWdDst">
                  {Translate("orders", "flech")}
                </div>
                <div className="Inlflx">{Translate("pickups", "details")}</div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{idpickup}</div>
              </div>
            ),
            productStr: this.ConcatProduStr(pickup.products),
            deliveryprice: pickup.price,
            maxdate: pickup.max_delivery_date,
            wilaya: pickup.wilaya,
            commune_name: pickup.commune_name,
            adress: pickup.pickup_address,
            clientname: pickup.customer_name,
            providerphone: pickup.customer_phone,
            deliverystatus: pickup.status,
            id: pickup.id,
            display_id: pickup.display_id,
            status: pickup.status,
            agent: pickup.agent,
            created_at: pickup.created_at,
            recived_by_maystro_at: pickup.recived_by_maystro_at,
            shipped_by_agent_at: pickup.shipped_by_agent_at,
            dataCancel: pickup.aborted_at,
          });
        }
      } else {
        this.props.history.push("/pickups" + this.state.pagesource);
      }
    }
    if (this.props.PickupOrdersGetting === "1" && PickupOrdersGetting === "2") {
      this.setState({
        orders: PickupOrders.ressuc.map((order) => order.display_id),
      });
    }
  }
  componentDidUpdate() {
    var UpdateStatus = document.querySelector(
      ".HidLstELm_" + (this.IndexState - 1)
    );
    if (UpdateStatus.style) {
      UpdateStatus.style.visibility = "hidden";
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "pickups");
    initGAPg();
    const {
      match: { params },
    } = this.props;
    var idpickup = params.idpickup;
    var source = this.$_GET("source");
    var targetPickup = null;
    var externTitle, FilterSource;
    if (source === "pickups") {
      const { PickupGetting, PickupsList } = this.props;
      if (PickupGetting !== "2") {
        this.props.history.push("/pickups");
      } else {
        targetPickup = PickupsList?.ressuc?.results?.find(
          (elem) => elem?.display_id === parseInt(idpickup)
        );

        this.props.GetPickupOrders(targetPickup.id);

        FilterSource = PickupsList.filter;
        externTitle = (
          <div className="StDrc">
            <Link
              onClick={() =>
                Event(
                  "PICKUP_DETAIL",
                  "USE_BACK_BUTTON_TO_PICKUPS",
                  "CLICK_EVENT"
                )
              }
              className="StOpcVal"
              to={"/pickups"}
            >
              {Translate("titles", "pickups")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("pickups", "details")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{idpickup}</div>
          </div>
        );
      }
    } else {
      this.props.history.push("/pickups");
    }
    if (targetPickup) {
      this.setState({
        titlepage: externTitle,
        productStr: this.ConcatProduStr(targetPickup.products),
        wilaya: targetPickup.wilaya,
        notDrive: targetPickup.note_to_driver,
        commune_name: targetPickup.commune_name,
        adress: targetPickup.pickup_address,
        clientname: targetPickup.customer_name,
        providerphone: targetPickup.customer_phone,
        id: targetPickup.id,
        display_id: targetPickup.display_id,
        status: targetPickup.status,
        pagesource: FilterSource,
        agent: targetPickup.agent,
        created_at: targetPickup.created_at,
        recived_by_maystro_at: targetPickup.recived_by_maystro_at,
        shipped_by_agent_at: targetPickup.shipped_by_agent_at,
        cnlcreaison: targetPickup.abort_reason,
        dataCancel: targetPickup.aborted_at,
        source: source,
      });
    }
  }
  GotToEdit(path) {
    Event("PICKUP_DETAIL", "GO_TO_EDIT_FROM_DETAIL_PAGE", "CLICK_EVENT");
    this.props.history.push(path, { page: this.state.pagesource });
  }
  removeOrder(id) {
    Event("PICKUP_DETAIL", "REMOVE_PICKUP_FROM_DETAIL", "CLICK_EVENT");
    let idremove = this.state.alertdelet;
    if (idremove) {
      this.props.UpdateStaOrder({ status: 50 }, idremove);
      this.setState({
        alertdelet: null,
      });
    } else {
      this.setState({
        alertdelet: id,
      });
    }
  }
  showOpt(name) {
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }
  render() {
    const { PickupUpdateStatus, dtStoreinfo } = this.props;
    this.IndexState = 0;
    let statuship = [
      [
        <ListDetailIcon
          icon={WaitingSvg}
          Styleicon="ElmSvgWt"
          title={Translate("statuspickup", "5")}
          details={[
            FormatDate(this.state.created_at),
            <span
              className={
                "StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" +
                this.IndexState
              }
              style={{ height: "100%" }}
            ></span>,
          ]}
        />,
      ],
    ];
    this.IndexState += 1;

    if (this.state.affected_to_agent_at) {
      statuship.push([
        <ListDetailIcon
          icon={PostpondSvg}
          Styleicon="ElmSvgPs"
          title={Translate("statuspickup", "6")}
          details={[
            FormatDate(this.state.affected_to_agent_at),
            <span
              className={
                "StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" +
                this.IndexState
              }
              style={{ height: "100%" }}
            ></span>,
          ]}
        />,
      ]);
      this.IndexState += 1;
    }
    if (this.state.shipped_by_agent_at) {
      statuship.push([
        <ListDetailIcon
          icon={DelivredSvg}
          Styleicon="ElmSvgDl"
          title={Translate("statuspickup", "7")}
          details={[
            FormatDate(this.state.shipped_by_agent_at),
            <span
              className={
                "StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" +
                this.IndexState
              }
              style={{ height: "100%" }}
            ></span>,
          ]}
        />,
      ]);
      this.IndexState += 1;
    }
    if (this.state.recived_by_maystro_at) {
      statuship.push([
        <ListDetailIcon
          icon={AcceptedSvg}
          Styleicon={this.state.status === 40 ? "ElmSvgWarn" : "ElmSvgAs"}
          title={Translate("statuspickup", this.state.status)}
          details={[
            FormatDate(this.state.recived_by_maystro_at),
            <span
              className={
                "StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" +
                this.IndexState
              }
              style={{ height: "100%" }}
            ></span>,
          ]}
        />,
      ]);
      this.IndexState += 1;
    }
    if (this.state.status === "50" || this.state.status === "51") {
      statuship.push([
        <ListDetailIcon
          icon={CancledSvg}
          Styleicon="ElmSvgCn"
          title={Translate("statuspickup", this.state.status + "")}
          details={[
            FormatDate(this.state.dataCancel),
            <span
              className={
                "StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" +
                this.IndexState
              }
              style={{ height: "100%" }}
            ></span>,
          ]}
        />,
      ]);
      this.IndexState += 1;
    }
    return (
      <ContainerPage page_title={this.state.titlepage}>
        <div className="FlWd InFlx flxDrc d-large">
          {PickupUpdateStatus === "0" && <Loader />}
          <div className="Hlwd">
            <Table
              ntdir={true}
              thead_elem={[Translate("pickups", "details")]}
              tbody_elem={[
                [
                  <ListDetailIcon
                    icon={PackageSvg}
                    title={Translate("pickups", "products")}
                    details={this.state.productStr || []}
                  />,
                ],
                [
                  <div className="FlWd InFlx flxDrc">
                    <div className="Hlwd">
                      <ListDetailIcon
                        icon={LocationSvg}
                        title={Translate("pickups", "adress")}
                        details={[this.state.adress]}
                      />
                    </div>
                  </div>,
                ],
                [
                  this.state.providerphone ? (
                    <div className="FlWd InFlx flxDrc">
                      <div className="Hlwd">
                        <ListDetailIcon
                          icon={PersonSvg}
                          title={Translate("pickups", "providername")}
                          details={[this.state.clientname]}
                        />
                      </div>
                      <div className="Hlwd Mrg2">
                        <ListDetailIcon
                          icon={CallSvg}
                          title={Translate("pickups", "providerphonenumber")}
                          details={[this.state.providerphone]}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  ),
                ],
                [
                  <div className="FlWd InFlx flxDrc">
                    <div className="Hlwd">
                      <ListDetailIcon
                        icon={StoreSvg}
                        title={Translate("pickups", "store")}
                        details={[dtStoreinfo.name]}
                      />
                    </div>
                    <div className="Hlwd Mrg2">
                      <ListDetailIcon
                        icon={CallSvg}
                        title={Translate("pickups", "storephone")}
                        details={[dtStoreinfo.phone]}
                      />
                    </div>
                  </div>,
                ],
              ]}
            />
            <div className="StMrtp">
              <Table
                ntdir={true}
                thead_elem={[Translate("orders", "notes")]}
                tbody_elem={[
                  [
                    <ListDetailIcon
                      icon={
                        <i className="InFlx StBlFil st_smpld">{StoreSvg}</i>
                      }
                      title={Translate("orders", "notetodriver")}
                      details={[
                        <div className="wharp_spce">{this.state.notDrive}</div>,
                      ]}
                    />,
                  ],
                ]}
              />
            </div>
          </div>
          <div className="Hlwd Mrg2">
            <div>
              <Table
                ntdir={true}
                thead_elem={[Translate("pickups", "timeline")]}
                tbody_elem={statuship}
              />
            </div>
            {this.state.agent && (
              <div className="StMrtp">
                <Table
                  ntdir={true}
                  thead_elem={[Translate("orders", "deliveryagent")]}
                  tbody_elem={[
                    [
                      <ListDetailIcon
                        icon={ManagentSvg}
                        title={Translate("auths", "fullname")}
                        details={[
                          this.state.agent.first_name +
                            " " +
                            this.state.agent.last_name,
                        ]}
                      />,
                    ],
                    [
                      <ListDetailIcon
                        icon={CallSvg}
                        title={Translate("auths", "phone")}
                        details={[this.state.agent.phone]}
                      />,
                    ],
                  ]}
                />
              </div>
            )}

            {(this.state.price || true) && (
              <div className="StMrtp">
                <Table
                  ntdir={true}
                  thead_elem={[Translate("profile", "paymenntinfo")]}
                  tbody_elem={[
                    [
                      <div className="FlWd InFlx flxDrc">
                        <div className="Hlwd">
                          <ListDetailIcon
                            icon={MoneySvg}
                            title={
                              <React.Fragment>
                                <span>{Translate("orders", "totalprice")}</span>
                                <span>
                                  {" "}
                                  ({Translate("exchanges", "extracharge")}){" "}
                                </span>
                              </React.Fragment>
                            }
                            details={[this.state.price]}
                          />
                        </div>
                        {/* <div className="Hlwd Mrg2"><ListDetailIcon icon={TruckSvg} title={Translate("orders", "deliveryprice")} details={[this.state.deliveryprice]}/></div> */}
                      </div>,
                    ],
                  ]}
                />
              </div>
            )}
          </div>
        </div>
        <div className="d-sm">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {PickupUpdateStatus === "0" && <Loader />}
            <div className="PickWdth">
              <Table
                ntdir={true}
                thead_elem={[Translate("pickups", "details")]}
                tbody_elem={[
                  [
                    <ListDetailIcon
                      icon={PackageSvg}
                      title={Translate("pickups", "products")}
                      details={this.state.productStr || []}
                    />,
                  ],
                  [
                    <div className="FlWd InFlx flxDrc">
                      <div className="Hlwd">
                        <ListDetailIcon
                          icon={LocationSvg}
                          title={Translate("pickups", "adress")}
                          details={[this.state.adress]}
                        />
                      </div>
                    </div>,
                  ],
                  [
                    this.state.providerphone ? (
                      <div className="FlWd InFlx flxDrc">
                        <div className="Hlwd">
                          <ListDetailIcon
                            icon={PersonSvg}
                            title={Translate("pickups", "providername")}
                            details={[this.state.clientname]}
                          />
                        </div>
                        <div className="Hlwd Mrg2">
                          <ListDetailIcon
                            icon={CallSvg}
                            title={Translate("pickups", "providerphonenumber")}
                            details={[this.state.providerphone]}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    ),
                  ],

                  [
                    <div className="FlWd InFlx flxDrc">
                      <div className="Hlwd">
                        <ListDetailIcon
                          icon={
                            <i className="InFlx StBlFil st_smpld">{StoreSvg}</i>
                          }
                          title={Translate("pickups", "store")}
                          details={[dtStoreinfo.name]}
                        />
                      </div>
                      <div className="Hlwd Mrg2 d-large">
                        <ListDetailIcon
                          icon={CallSvg}
                          title={Translate("pickups", "storephone")}
                          details={[dtStoreinfo.phone]}
                        />
                      </div>
                      ,
                    </div>,
                  ],
                  [
                    <div className="Hlwd Mrg2 d-sm">
                      <ListDetailIcon
                        icon={
                          <i className="InFlx StBlFil st_smpld">{CallSvg}</i>
                        }
                        title={Translate("pickups", "storephone")}
                        details={[dtStoreinfo.phone]}
                      />
                    </div>,
                  ],
                ]}
              />
              <div className="StMrtp">
                <Table
                  ntdir={true}
                  thead_elem={[Translate("orders", "notes")]}
                  tbody_elem={[
                    [
                      <ListDetailIcon
                        icon={
                          <i className="InFlx StBlFil st_smpld">{StoreSvg}</i>
                        }
                        title={Translate("orders", "notetodriver")}
                        details={[
                          <div className="wharp_spce">
                            {this.state.notDrive}
                          </div>,
                        ]}
                      />,
                    ],
                  ]}
                />
              </div>
            </div>
            <div className="PickWdth">
              <div>
                <Table
                  ntdir={true}
                  thead_elem={[Translate("pickups", "timeline")]}
                  tbody_elem={statuship}
                />
              </div>
              {this.state.agent && (
                <div className="StMrtp">
                  <Table
                    ntdir={true}
                    thead_elem={[Translate("orders", "deliveryagent")]}
                    tbody_elem={[
                      [
                        <ListDetailIcon
                          icon={ManagentSvg}
                          title={Translate("auths", "fullname")}
                          details={[
                            this.state.agent.first_name +
                              " " +
                              this.state.agent.last_name,
                          ]}
                        />,
                      ],
                      [
                        <ListDetailIcon
                          icon={CallSvg}
                          title={Translate("auths", "phone")}
                          details={[this.state.agent.phone]}
                        />,
                      ],
                    ]}
                  />
                </div>
              )}

              {(this.state.price || true) && (
                <div className="StMrtp">
                  <Table
                    ntdir={true}
                    thead_elem={[Translate("profile", "paymenntinfo")]}
                    tbody_elem={[
                      [
                        <div className="FlWd InFlx flxDrc">
                          <div className="Hlwd">
                            <ListDetailIcon
                              icon={MoneySvg}
                              title={
                                <React.Fragment>
                                  <span>
                                    {Translate("orders", "totalprice")}
                                  </span>
                                  <span>
                                    {" "}
                                    ({Translate(
                                      "exchanges",
                                      "extracharge"
                                    )}){" "}
                                  </span>
                                </React.Fragment>
                              }
                              details={[this.state.price]}
                            />
                          </div>
                          {/* <div className="Hlwd Mrg2"><ListDetailIcon icon={TruckSvg} title={Translate("orders", "deliveryprice")} details={[this.state.deliveryprice]}/></div> */}
                        </div>,
                      ],
                    ]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {this.state.alertdelet && (
          <TabShow
            tab_title={Translate("profile", "confirmaction")}
            wdth="550px"
            NotCls={true}
          >
            <div className="MrAot" style={{ width: "94%" }}>
              <p className="StdirLn FlWd DltTpMar">
                {Translate("orders", "confrmdelete")}
              </p>
              <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                <div
                  className="StwdMaxC CrsPoi StWdDst"
                  style={{ height: "30px" }}
                  onClick={() => this.showOpt("alertdelet")}
                >
                  <BtnLg text={Translate("profile", "cancel")} classSet={""} />
                </div>
                <div
                  className="StwdMaxC CrsPoi StWdDst"
                  style={{ height: "30px" }}
                  onClick={this.removeOrder}
                >
                  <BtnLg
                    text={Translate("profile", "delete")}
                    classSet={"TxDng"}
                  />
                </div>
              </div>
            </div>
          </TabShow>
        )}
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href
      .replace(window.location.hash, "")
      .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
        vars[key] = value !== undefined ? value : "";
      });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const {
    PickupGetting,
    PickupsList,
    PickupUpdateStatus,
    DataUpdateStatus,
    PickupOrdersGetting,
    PickupOrders,
  } = state.pickups;
  const { dtStoreinfo } = state.user;
  const { Searching, DataSearch } = state.search;
  return {
    dtStoreinfo,
    PickupGetting,
    PickupsList,
    PickupUpdateStatus,
    DataUpdateStatus,
    PickupOrdersGetting,
    PickupOrders,
    Searching,
    DataSearch,
  };
}
const actionCreators = {
  GetPickups: pickupsActions.GetPickups,
  GetPickupOrders: pickupsActions.GetPickupOrders,
  UpdateStaOrder: pickupsActions.UpdatePickupStatus,
  SearchOrders: searchActions.SearchOrders,
};

const connectedStockPickupDetailPage = connect(
  mapState,
  actionCreators
)(StockPickupDetailPage);
export { connectedStockPickupDetailPage as StockPickupDetailPage };
