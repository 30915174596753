export const is_exclusive_test_store = (store) =>
  store.use_new_status && Boolean(store.use_new_status);

export const CONFIRMATION_HELPERS = {
  mapStatusToCode: (status) => {
    const statusCodes = {
      PENDING: 10,
      CALLBACK: 20,
      POSTPONED: 30,
      CONFIRMED: 40,
      CANCELLED: 100,
    };

    return statusCodes[status.toUpperCase()] || status;
  },
  
  getStatusIconColor: (status) => {
    const statusColors = {
      10: "status_11",
      20: "status_31",
      30: "status_42",
      40: "status_41",
      100: "status_50",
    };

    return statusColors[status] || "status_31";
  },
};

export const ORDER_HELPERS = {
   mapStatusToCode: (status) => {
    const statusCodes = new Map([
      ["PENDING", 10],
      ["CALLBACK", 20],
      ["POSTPONED", 30],
      ["CONFIRMED", 40],
      ["CANCELLED", 100],
    ]);

    return statusCodes.get(status.toUpperCase()) || status;
  },
  
  getStatusIconColor: (status) => {
    const statusColors = new Map([
      [10, "ElmSvgWt"],
      [20, "StBlFil"],
      [30, "ElmSvgPs"],
      [40, "ElmSvgDl"],
      [100, "ElmSvgCn"],
    ]);

    return statusColors.get(status) || "ElmSvgWt";
  },
};
