import { searchConstants } from '../constants';
export function search(state , action) {
  switch (action.type) {
    case searchConstants.SEARCH_ORDER_REQUEST:
        return{
            Searching:"0",
            DataSearch: action.payload
        };
    case searchConstants.SEARCH_ORDER_SUCCESS:
        return{
            Searching:"1",
            DataSearch: action.payload
        };
    case searchConstants.SEARCH_ORDER_FAILURE:
        return{
            Searching:"2",
            DataSearch: action.payload
        };
    case "CLEAR_SEARCH":
        return {}
    default:
        return {...state}
    }
}