import React, { Component } from "react";
import { Link } from "react-router-dom";
import TabShow from "../_Common/TabShow";
import GroupeForm from "../../_Common/GroupeForm";
import Button from "../../_Common/Button";
import { Translate } from "../../../utils/lang/translate";
import { UnderDevPage } from "../_Common/UnderDevPage";
import { CloseSvg } from "../_Common/IconSvg";
class ProductAlert extends Component {
  render() {
    return (
      <TabShow tab_title={Translate("product", "elotoalert")} wdth="550px">
        <div className="MrAot" style={{ width: "94%" }}>
          <div className="RlPs" style={{ height: "450px", zoom: ".8" }}>
            <UnderDevPage />
          </div>
        </div>
      </TabShow>
    );
  }
}
const StockSettingsAlert = (props) => {
  return (
    <TabShow>
      <div className="FlWd Inlflx AlgnItm StDrc spcBtwn">
        <div className="StdirLn">
          <h3 className="DlMg">{Translate("titles", "setlowstockalert")}</h3>
        </div>

        <div onClick={props.onClose}>{CloseSvg}</div>
      </div>
      <div className="SmMarS2">
        <div>
          <p className="stfSize">{Translate("product", "setminstockqt")}</p>
        </div>
        <div
          className="MrAot"
          style={{ width: "88%" }}
          onSubmit={props.onSubmit}
        >
          <form method="post">
            <GroupeForm
              id={"quantity"}
              name={"quantity"}
              defaultValue={props.defaultValue}
              text={Translate("product", "qt")}
              type={"number"}
              min={0}
              workfun={props.onChange}
              stclass={"ClSidTh"}
            />

            <div className="Hlwd MrAot StMarMx StMrtp">
              <Button BtnText={Translate("orders", "save")} />
            </div>
          </form>
        </div>
      </div>
    </TabShow>
  );
};

export { ProductAlert, StockSettingsAlert };
