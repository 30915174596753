import React from "react";

const DeliveryAgent = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="24"
      fill="none"
      viewBox="0 0 22 24"
      {...props}
    >
      <path
        d="M4.24105 4.90488V2.06958C4.24129 1.76899 4.36086 1.48079 4.57349 1.26833C4.78612 1.05587 5.07441 0.936523 5.375 0.936523H10.2453C10.5459 0.936523 10.8342 1.05587 11.0468 1.26833C11.2595 1.48079 11.379 1.76899 11.3793 2.06958V2.71372H11.622C13.4545 2.71372 13.5455 3.72187 13.5455 3.72187H11.3784V4.90488C11.3784 5.85135 11.0024 6.75906 10.3332 7.42831C9.6639 8.09757 8.75619 8.47355 7.80972 8.47355C6.86325 8.47355 5.95555 8.09757 5.28629 7.42831C4.61704 6.75906 4.24105 5.85135 4.24105 4.90488ZM11.1268 16.9661C11.1266 16.5545 10.9629 16.1599 10.6718 15.869C10.3807 15.578 9.98599 15.4146 9.57443 15.4146H4.88609L3.8824 11.233L5.58555 14.5278H8.94188V13.1628H14.8882L14.1655 10.1526C14.0837 9.8142 13.8902 9.51322 13.6163 9.29826C13.3425 9.0833 13.0041 8.96688 12.656 8.96781V8.95443H2.11413V8.96871C1.99116 8.96704 1.86841 8.97961 1.74834 9.00618C1.54999 9.05383 1.36298 9.1401 1.198 9.26007C1.03301 9.38004 0.893293 9.53135 0.786822 9.70535C0.680351 9.87935 0.609218 10.0726 0.577488 10.2741C0.545759 10.4756 0.554054 10.6814 0.601903 10.8797L2.14981 17.3301C2.23181 17.6691 2.4256 17.9707 2.69995 18.1861C2.9743 18.4016 3.31321 18.5183 3.66204 18.5176H9.57354C9.98525 18.5176 10.3801 18.354 10.6712 18.0629C10.9624 17.7718 11.1259 17.3769 11.1259 16.9652L11.1268 16.9661ZM12.8487 13.6062H9.38529V14.5278H9.57354C10.2203 14.5278 10.8406 14.7847 11.298 15.2421C11.7553 15.6994 12.0123 16.3197 12.0123 16.9665C12.0123 17.6133 11.7553 18.2336 11.298 18.691C10.8406 19.1483 10.2203 19.4053 9.57354 19.4053H9.38529V19.6604H19.8326V13.6053H12.8514L12.8487 13.6062ZM9.38618 20.1038H8.9401V19.4044H4.51852L4.77457 20.5169V23.0319H12.656V20.1038H9.38618ZM20.276 15.4325V18.5015C20.657 18.4636 21.0103 18.2854 21.2673 18.0016C21.5243 17.7178 21.6666 17.3485 21.6666 16.9656C21.6666 16.5828 21.5243 16.2135 21.2673 15.9297C21.0103 15.6459 20.657 15.4677 20.276 15.4298V15.4325Z"
        fill={props.fill ? props.fill : "#008AFF"}
      />
    </svg>
  );
}
 
export default DeliveryAgent;