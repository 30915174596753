import React, { Component } from "react";
import { connect } from "react-redux";
import { productActions } from "../../../actions";
import TabShow from "../_Common/TabShow";
import Table from "../_Common/Table";
import { Translate } from "../../../utils/lang/translate";
import Button from "../../_Common/Button";
import TableLoader from "../_Common/TableLoader";
import { Event } from "../_Common";
class ProductAvail extends Component {
  constructor(props) {
    super(props);
    this.title = "";
  }
  ObjetToTab(dataset, el1, el2) {
    let tab2 = [];
    Array.isArray(dataset) &&
      dataset.forEach((elem) => {
        tab2.push([elem[el1], elem[el2]]);
      });
    return tab2;
  }
  componentDidMount() {
    const {
      GetPrdPage,
      PrdPageData,
      match: { params },
    } = this.props;
    if (
      GetPrdPage === "1" &&
      PrdPageData &&
      PrdPageData.list &&
      Array.isArray(PrdPageData.list.results)
    ) {
      let product = PrdPageData.list.results.find(
        (product) => product.display_id === params.idproduct
      );
      if (product) {
        this.title = product.logistical_description;
        this.props.ViewStockProduct(product.id);
      } else {
        this.props.history.push("/stock/products");
      }
    } else {
      this.props.history.push("/stock/products");
    }
  }
  GoBack() {
    Event("STOCK", "USE_GO_BACK_BUTTON", "CLICK_EVENT");
    window.history.back();
  }
  RedirectTo(path) {
    this.props.history.push(path);
  }
  render() {
    const { StockLoadded, DataStock } = this.props;
    let title = Translate("alert", "load");
    let dataProd = [];
    if (StockLoadded === "2" && DataStock && DataStock.list) {
      dataProd = this.ObjetToTab(DataStock.list, "warehouse__name", "quantity");
      title = this.title;
    }
    return (
      <TabShow
        tab_title={
          <div>
            <div>{Translate("product", "productavail")}</div>
            <div className="StSmlS StPdsml StOpcVal">{title}</div>
          </div>
        }
        wdth="550px"
      >
        <div className="MrAot" style={{ width: "94%" }}>
          {StockLoadded === "1" ? (
            <TableLoader />
          ) : (
            StockLoadded === "2" && (
              <Table
                thead_elem={[
                  Translate("product", "whrhs").toUpperCase(),
                  Translate("product", "qt").toUpperCase(),
                ]}
                tbody_elem={dataProd}
              />
            )
          )}
          <div className="Hlwd MrAot StMrtp" onClick={this.GoBack}>
            <Button BtnText={Translate("product", "close")} />
          </div>
        </div>
      </TabShow>
    );
  }
}
function mapState(state) {
  const { StockLoadded, DataStock } = state.Stock;
  const { GetPrdPage, PrdPageData } = state.UnlinkProduct;
  return { StockLoadded, DataStock, PrdPageData, GetPrdPage };
}
const actionCreators = {
  ViewStockProduct: productActions.ViewStockProduct,
};
const connectedProductAvail = connect(mapState, actionCreators)(ProductAvail);

const WarehousesAvail = (props) => {
  return (
    <div className="FlWd">
      {props.loading ? (
        <TableLoader />
      ) : (
        <Table
          thead_elem={[
            Translate("product", "whrhs"),
            Translate("product", "qt"),
            Translate("product", "availableqt"),
            Translate("product", "defective"),
          ]}
          tbody_elem={props.data}
        />
      )}
    </div>
  );
};
export { connectedProductAvail as ProductAvail, WarehousesAvail };
