import React, { useEffect, useState } from 'react';
import ContainerPage from '../_Common/ContainerPage';
import { Translate } from '../../../utils/lang/translate';
import Card from '../_Common/Card/Card';
import { Order2Submit, RequestStatus, OrderDetails } from '../../../types';
import { alertActions, ordersActions } from '../../../actions';
import { connect } from 'react-redux';
import { Link, RouterChildContext, useHistory, useParams } from 'react-router-dom';
import { ProductsForm } from './components/ProductsForm';
import CustomerInfoForm from './components/CustomerInfoForm';
import { DeliveryDetailsForm } from './components/DeliveryDetailsForm';
import Stepper, { StepType } from './components/Stepper';

type AddOrderProps = {
  SendAlert: (code: string, text: string, action: string) => void,
  dataProfile: any,
  dtStoreinfo: any,
  AddOrder: (order: Order2Submit) => void,
  OrderAdded: RequestStatus,
  dataAddOrder: OrderDetails,
  ClearAddOrder: () => void,
  GettingOrderById: RequestStatus,
  orderData: OrderDetails,
  GetOrder: (order_id: string) => void,
  UpdateOrder: (data: Order2Submit, order_id: string) => void,
  OrderUpdating: RequestStatus,
  DataUpdateOrder: OrderDetails 
};

const AddOrder = ({
  dataProfile,
  dtStoreinfo,
  SendAlert,
  AddOrder,
  OrderAdded,
  dataAddOrder,
  ClearAddOrder,
  GetOrder,
  GettingOrderById,
  orderData,
  UpdateOrder,
  OrderUpdating,
  DataUpdateOrder
}: AddOrderProps) => {

  const INITIAL_ORDER: Order2Submit = {
    commune: null,
    customer_name: null,
    customer_phone: null,
    destination_text: null,
    products: [],
    total_price: null,
    express: false,
    pickup_point: null,
    external_order_id: null,
    note_to_driver: null,
    delivery_type: 1,
    source: 1,
    use_points: false,
    wilaya: null,
    is_exchange: false,
    old_order: null
  }

  const history = useHistory<RouterChildContext['router']['history']>();

  const { orderId } = useParams<{ orderId: string }>();
  
  const [order, setOrder] = useState<Order2Submit>(INITIAL_ORDER);

  const [currentStep, setCurrentStep] = useState<StepType>("PRODUCT_DETAILS");
  
  useEffect(() => {
    if(orderId !== "new"){
      GetOrder(orderId);
    }
  }, [orderId])

  useEffect(() => {
    
    if(OrderAdded === "2") {
      ClearAddOrder();
      SendAlert("41", "Successfuly added new order!", "");
      history.push(`/orders?display_id=${dataAddOrder.display_id}`);
    }
    else if(OrderAdded === "3") {
      ClearAddOrder();
      SendAlert("50", "Failed to add new order!", "");
    };

    if(GettingOrderById === '2') {
      if(orderId !== "new") {
        setOrder({
          commune: parseInt(`${orderData.commune}`),
          customer_name: orderData.customer_name,
          customer_phone: orderData.customer_phone,
          delivery_type: orderData.delivery_type,
          destination_text: orderData.destination_text,
          express: orderData.express ?? false,
          external_order_id: orderData.external_order_id,
          pickup_point: orderData.pickup_point,
          note_to_driver: orderData.note_to_driver,
          products: orderData.products,
          source: orderData.source,
          total_price: 
            orderData.total_price !== null 
            ? orderData.total_price 
            : `${Number(orderData.product_price) + orderData.price}`,
          use_points: orderData.use_points,
          wilaya: parseInt(orderData.wilaya_id ?? "0"),
          is_exchange: orderData.is_exchange,
          old_order: null,
        });
      }else {
        setOrder({ ...INITIAL_ORDER });
      }
    };

    if(GettingOrderById === '3') {
      SendAlert("50", "Failed to fetch order data!", "");
    };

    if(OrderUpdating === '2') {
      SendAlert("41", "Successfuly updated order data!", "");
      history.push(`/orders?display_id=${DataUpdateOrder.display_id}`);
    }

    if(OrderUpdating === "3") {
      SendAlert("50", "Failed to update order data!", "");
    };
    
  }, [OrderAdded, GettingOrderById, OrderUpdating, orderId]);

  return (
    <ContainerPage 
      page_title={
        orderId === "new" ? 
          Translate("orders", "addneworder") 
        : 
          <div className="StDrc">
            <Link className="StOpcVal" to={"/orders"}> {/** needs to point to /orders + source filter */}
              {Translate("titles", "order")}
            </Link>
            <div className="Inlflx StWdDst">
              {Translate("orders", "flech")}
            </div>
            <div className="Inlflx">{Translate("orders", "editorder")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{orderData?.display_id ?? ""}</div>
          </div>
      }
    >
      {
        orderId !== "new" && GettingOrderById === '1'
        ?
          <div className='InFlx AlgnItm JstfCnt' style={{ minHeight: "600px" }}>
            <div className='custom-spinner-loader'/>
          </div>
        :
          <Card style={{ gap: "30px" }}>
            
            <Stepper currentStep={currentStep} onStepChange={setCurrentStep}/>

            <ProductsForm
              active={currentStep === 'PRODUCT_DETAILS'} 
              orderProductsData={
                {
                  orderProducts : order.products.map(
                    (item, _) => ({ 
                      product_id: item.product_id, 
                      logistical_description: item.logistical_description ?? "", 
                      quantity: item.quantity,
                      is_exchange: item.is_exchange 
                    })
                  ),
                  external_order_id: order.external_order_id ?? null
                }
              }
              onFormSubmit={
                (data) => {
                  setOrder(prev => ({ ...prev, products: data.products, external_order_id: data.external_order_id }));
                  setCurrentStep('CLIENT_INFO');
                }
              } 
            />
            
            <CustomerInfoForm
              active={currentStep === 'CLIENT_INFO'}
              customerInfo={
                order.customer_name && order.customer_phone
                ?
                {
                  name: order.customer_name,
                  phone: order.customer_phone,
                }
                : null
              }
              onFormSubmit={
                (data) => {
                  setOrder(prev => ({ ...prev, customer_name: data.name, customer_phone: data.phone }));
                  setCurrentStep('DELIVERY_DETAIL');
                }
              }
            />
            
            <DeliveryDetailsForm
              active={currentStep === 'DELIVERY_DETAIL'}
              onFormSubmit={(data) => {
                if(orderId !== "new") {
                  UpdateOrder({ ...order, ...data }, orderId);
                }else{
                  AddOrder({ ...order, ...data });
                }
                setOrder(prev => ({ ...prev, ...data }));
              }}
              loading={OrderAdded === '1' || OrderUpdating === '1'}
              orderDeliveryDetails={
                order.commune && 
                order.wilaya 
                ? { ...order }
                : null
              }
              is_exchange={order.is_exchange}
            />
          
          </Card>
      } 
    </ContainerPage>
  );
}
 
function mapState(state: any) {

  const { dataProfile, dtStoreinfo } = state.user;
  const { dataAddOrder, OrderAdded, GettingOrderById, orderData, OrderUpdating, DataUpdateOrder } = state.orders

  return {
    dataProfile,
    dtStoreinfo,
    OrderAdded,
    dataAddOrder,
    GettingOrderById,
    orderData,
    OrderUpdating,
    DataUpdateOrder
  };
}

const actionCreators = {
  SendAlert: alertActions.SendAlert,
  AddOrder: ordersActions.AddOrder,
  ClearAddOrder: ordersActions.ClearAddOrder,
  GetOrder: ordersActions.GetOrder,
  UpdateOrder: ordersActions.UpdateOrder
};

const connectedAddOrder = connect(mapState, actionCreators)(AddOrder);

export { connectedAddOrder as AddOrder };
 
