import React,{Component} from 'react'
import {
  Route,
  Switch
} from 'react-router-dom';
import {Translate} from '../../../utils/lang/translate'
import {HomePayment} from './HomePayment'
import {FeatureLoked} from '../_Common/FeatureLoked'
import ContainerPage from '../_Common/ContainerPage'
import {FacturePayment} from './FacturePayment'
import {connect} from 'react-redux'
class PaymentsPage extends Component{
    componentDidMount() {
        document.title = 'Maystro Delivery - '+Translate("titles","payements");
    }
    render(){
        if(this.props.dataProfile.is_store || this.props.dataProfile.view_payment){
            return(
                <Switch>
                    <Route path="/payments/facture/:action" component={FacturePayment} />
                    <Route path="/payments" component={HomePayment} />
                </Switch>
            )
        }else{
            return (
                <ContainerPage page_title={Translate("titles","payements")}>
                    <FeatureLoked/>
                </ContainerPage>
            )
        }
    }  
}
function mapState(state) {
    const {GetingUser,dataProfile}=state.user
    return { GetingUser,dataProfile };
}
const connectedPaymentsPage = connect(mapState)(PaymentsPage);
export { connectedPaymentsPage as PaymentsPage };