import React, { useEffect, useState } from "react";
export default function ElmentStatus(props) {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <div className="ClSidTh StBrdRd pdnVldas FlHg StBrdTb InFlx AlgnItm FlWd sTbxSz StBgbrds">
      <div
        className={
          windowSize.innerWidth >= 789
            ? props.grid
              ? "RlPs Inlflx FlWd spctwB FlHg AlgnItm"
              : "RlPs Inlflx FlWd cntrlm FlHg AlgnItm"
            : "RlPs Inlflx FlWd spctwB FlHg AlgnItm"
        }
      >
        <div
          className={
            windowSize.innerWidth >= 789
              ? props.grid
                ? "FlHg InFlx Stclmnf spctwB"
                : "FlHg InFlx Stclmnf cntlm"
              : "FlHg InFlx Stclmnf spctwB"
          }
        >
          <span
            className={
              windowSize.innerWidth >= 789
                ? "DsBlc StSmlS StOpcVal TxtCn"
                : "DsBlc StSmlS StOpcVal"
            }
          >
            {props.dataText}
          </span>
          <strong style={{ marginTop: "5px" }} className="DsBlc SetBigSiz">
            {props.dataNumber}
          </strong>
        </div>
        <div className="InFlx">
          <div
            style={{ padding: "1px" }}
            className={"StClSvgD StBrdRd status_" + props.status}
          >
            {props.SvgStatus}
          </div>
        </div>
      </div>
    </div>
  );
}

function getWindowSize() {
  const { innerWidth } = window;
  return { innerWidth };
}
