import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TabShow from "../_Common/TabShow";
import { Translate } from "../../../utils/lang/translate";
import { CloseSvg, SocialMediaIcons } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";
import PromotionCountDown from "./PromotionCountDown";
import { PROMOTION } from "../../../utils/new-features";
import { initGAPg, Event } from "../_Common";

function PromotionModal(props) {
  useEffect(()=>{
    initGAPg();

  },[])
  const handleSocialLinkClick=(platform)=>{
    Event("SOCIAL_MEDIA_FOLLOW", "FOLLOW_ON_"+platform, "CLICK_FROM_PROMOTION")

  }
  return (
    <TabShow NotCls style={{ minWidth: "80vw" }}>
      <div
        className="FlWd Inlflx AlgnItm StDrc spcBtwn"
        style={{ marginBottom: "3rem" }}
      >
        <div className="StdirLn">
          <h3 className="DlMg">{Translate("titles", "newpromotion")}</h3>
        </div>

        <div className="CrsPoi" onClick={props.onClose}>
          {CloseSvg}
        </div>
      </div>
      <PromotionCountDown 
      expirationDate={PROMOTION.enddate}
      />
      <div
        className="SmMarS FlWd Inlflx AlgnItm JstfCnt"
      >
        <img src={PROMOTION.link} alt="" style={{maxWidth: "60vw"}}/>

      </div>
      <div
        className="SmMarS FlWd Inlflx Stclmnf AlgnItm JstfCnt"
      >
        <p>{Translate("announcements","dontmisspromot")}</p>

        <div className="FlWd Inlflx JstfCnt">
          <div className="Inlflx Stclmnf AlgnItm JstfCnt" style={{margin: "0 2%"}}>
            <p>Like us on Facebook</p>
            <a target="blank" href="https://www.facebook.com/maystro.delivery/" onClick={()=>handleSocialLinkClick("FACEBOOK")}>
              {SocialMediaIcons.md.Facebook}

            </a>

          </div>
          <div className="Inlflx Stclmnf AlgnItm JstfCnt" style={{margin: "0 2%"}}>
            <p>Follow us on IG</p>
            <a target="blank" href="https://instagram.com/maystro_delivery" onClick={()=>handleSocialLinkClick("IG")}>
              {SocialMediaIcons.md.Instagram}

            </a>

          </div>
          <div className="Inlflx Stclmnf AlgnItm JstfCnt" style={{margin: "0 2%"}}>
            <p>Watch us on TikTok</p>
            <a target="blank" href="https://vm.tiktok.com/ZSeBoQBRt/" onClick={()=>handleSocialLinkClick("TIKTOK")}>
              {SocialMediaIcons.md.TikTok}

            </a>

          </div>
          

        </div>
        
        

      </div>
      <div
        className="SmMarS FlWd Inlflx AlgnItm JstfCnt CrsPoi"
        onClick={props.onClose}
      >
        <BtnLg
          style={{
            minHeight: "40px",
            minWidth: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          text={Translate("titles", "done")}
        />
      </div>
    </TabShow>
  );
}

PromotionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PromotionModal;
