export const exchangeConstants = {
    ADD_EXCHANGE_SUCCESS: 'ADD_EXCHANGE_SUCCESS',
    ADD_EXCHANGE_FAILURE: 'ADD_EXCHANGE_FAILURE',
    ADD_EXCHANGE_REQUEST: 'ADD_EXCHANGE_REQUEST',

    UPDATE_EXCHANGE_SUCCESS: 'UPDATE_EXCHANGE_SUCCESS',
    UPDATE_EXCHANGE_FAILURE: 'UPDATE_EXCHANGE_FAILURE',
    UPDATE_EXCHANGE_REQUEST: 'UPDATE_EXCHANGE_REQUEST',

    GET_EXCHANGE_SUCCESS: 'GET_EXCHANGE_SUCCESS',
    GET_EXCHANGE_FAILURE: 'GET_EXCHANGE_FAILURE',
    GET_EXCHANGE_REQUEST: 'GET_EXCHANGE_REQUEST',

    ADD_REFUND_SUCCESS: 'ADD_REFUND_SUCCESS',
    ADD_REFUND_FAILURE: 'ADD_REFUND_FAILURE',
    ADD_REFUND_REQUEST: 'ADD_REFUND_REQUEST',

    GET_EXCHANGE_BY_ID_REQUEST: 'GET_EXCHANGE_BY_ID_REQUEST',
    GET_EXCHANGE_BY_ID_SUCCESS: 'GET_EXCHANGE_BY_ID_SUCCESS',
    GET_EXCHANGE_BY_ID_FAILURE: 'GET_EXCHANGE_BY_ID_FAILURE',

    GET_UNPRINTED_EXCHANGES_COUNT_REQUEST: 'GET_UNPRINTED_EXCHANGES_COUNT_REQUEST',
    GET_UNPRINTED_EXCHANGES_COUNT_SUCCESS: 'GET_UNPRINTED_EXCHANGES_COUNT_SUCCESS',
    GET_UNPRINTED_EXCHANGES_COUNT_FAILURE: 'GET_UNPRINTED_EXCHANGES_COUNT_FAILURE',

    UPDATE_SHOW_EXCHANGE_POPUPS_REQUEST: 'UPDATE_SHOW_EXCHANGE_POPUPS_REQUEST',
    UPDATE_SHOW_EXCHANGE_POPUPS_SUCCESS: 'UPDATE_SHOW_EXCHANGE_POPUPS_SUCCESS',
    UPDATE_SHOW_EXCHANGE_POPUPS_FAILURE: 'UPDATE_SHOW_EXCHANGE_POPUPS_FAILURE',

    UPDATE_SHOW_EXCHANGE_GUIDE_MODAL: 'UPDATE_SHOW_EXCHANGE_GUIDE_MODAL',

    UPDATE_PRINTED_EXCHANGE_SLIPS: "UPDATE_PRINTED_EXCHANGE_SLIPS",

    CLEAR_ADD_EXCHANGE_DATA: "CLEAR_ADD_EXCHANGE_DATA",

};

export const exchange_status_list = {
    EXCHANGE_STATUS_CREATED: 4,
    EXCHANGE_STATUS_IN_PROGRESS: 5,
    EXCHANGE_STATUS_COLLECTED_BY_AGENT: 10,
    EXCHANGE_STATUS_IN_HUB: 20,
    EXCHANGE_STATUS_WAITING_TRANSIT: 25,
    EXCHANGE_STATUS_IN_TRANSIT: 29,
    EXCHANGE_STATUS_READY_TO_BE_RETURNED: 33,
    EXCHANGE_STATUS_COLLECTED_BY_STORE: 41,
    EXCHANGE_STATUS_ABORTED: 50,
    EXCHANGE_STATUS_NOT_RECEIVED_FROM_TRANSIT: 53
};
