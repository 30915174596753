import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { pickupsActions, commonActions, alertActions } from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { FormatDate, Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link } from "react-router-dom";
import { initGAPg, Event, Select } from "../_Common";
import TableLoader from "../_Common/TableLoader";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import Table from "../_Common/Table";
import DropedList from "../_Common/DropedList";
import SmallText from "../_Common/SmallText";
import { AddSvg, CloseSvg, FlechDwnSvg } from "../_Common/IconSvg";
import InlinedElement from "../_Common/InlinedElement";

const TABLE_HEAD = [
  Translate("product", "id"),
  Translate("product", "productname"),
  Translate("orders", "customername"),
  Translate("orders", "district") + " / " + Translate("product", "wilaya"),
  Translate("orders", "creationdate"),
];

class AddPickupPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // allOrders: this.props.GettingPossibleOrders==="2"?this.props.OrdersList.ressuc.list:[],
      allOrders: [],
      orders: [],
      providername: "",
      providerphonenumber: "",
      pickup_address: "",
      commune: null,
      city: null,
      car_type: null,
      notetodriver: "",
      error: {},
      filtersource:
        this.props.PickupGetting === "2" ? this.props.PickupGetting.filter : "",
      titlepage: Translate("pickups", "addpickup"),
      titlebtn: Translate("pickups", "request"),
      source: this.$_GET("source"),
      permmisionedit:
        this.props.dataProfile.is_store || this.props.dataProfile.change_order,
      permmisionadd:
        this.props.dataProfile.is_store || this.props.dataProfile.add_pickup,
    };
    this.isExternCity = null;
    this.isExternCommune = null;
    this.submit = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
    this.handleUseSelect = this.handleUseSelect.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.props.GetVehiculeTypes();
    this.props.Getcommunes("?wilaya=16"); // Pickup for starter is currently exclusive in Algiers
  }
  componentWillReceiveProps(nextProps) {
    const {
      PickupUpdating,
      PickupAdded,
      GettingPossibleOrders,
      OrdersList,
      dataWilaya,
      GetigCommune,
      DataCommune,
    } = nextProps;

    if (
      this.isExternCity &&
      this.props.GetingWilaya === "1" &&
      Array.isArray(dataWilaya)
    ) {
      let wilaya = dataWilaya.find((elem) => elem[1] === this.isExternCity);
      this.handleSelect(wilaya, "city");
      this.isExternCity = null;
    }
    if (
      this.isExternCommune &&
      GetigCommune === "1" &&
      this.props.GetigCommune === "0" &&
      Array.isArray(DataCommune)
    ) {
      let commune = DataCommune.find(
        (elem) => elem.id === this.isExternCommune
      );
      this.handleSelect(commune, "commune");
      this.isExternCommune = null;
    }

    if (
      this.props.GettingPossibleOrders === "1" &&
      GettingPossibleOrders === "2" &&
      OrdersList &&
      OrdersList.ressuc &&
      OrdersList.ressuc.list
    ) {
      this.setState({
        allOrders: OrdersList.ressuc.list,
      });
    }

    if (this.submit && (PickupAdded === "3" || PickupUpdating === "3")) {
      document.getElementById("Container-root").scrollTo(0, 0);
      this.submit = false;
      this.props.SendAlert(
        "50",
        Translate(
          "alert",
          this.state.mode === "updating" ? "failpickupupdated" : "pickupfail"
        ),
        ""
      );
    }

    if (this.submit && (PickupAdded === "2" || PickupUpdating === "2")) {
      this.submit = false;
      this.props.SendAlert(
        "41",
        Translate(
          "alert",
          this.state.mode === "updating"
            ? "successpickupupdated"
            : "pickupadded"
        ),
        ""
      );
      this.props.history.push("/pickups");
    }
  }
  componentWillUnmount() {
    this.props.ClearStore("CLEAR_COMMON");
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "pickups");
    initGAPg();
    const {
      match: { params },
    } = this.props;
    let pickupIdParam = params.idpickup;
    if (pickupIdParam !== "new") {
      var targetPickup = null;
      var externTitle, FilterSource;
      const { PickupGetting, PickupsList } = this.props;
      if (PickupGetting !== "2") {
        this.props.history.push("/pickups");
      } else {
        targetPickup = PickupsList.ressuc.results.find(
          (elem) => elem.id === pickupIdParam
        );
        if (!targetPickup) {
          this.props.history.push("/pickups");
        } else {
          FilterSource = PickupsList.filter;
          this.isExternCommune = targetPickup.commune;
          externTitle = (
            <div className="StDrc">
              <Link className="StOpcVal" to={"/pickups" + FilterSource}>
                {Translate("titles", "pickups")}
              </Link>
              <div className="Inlflx StWdDst">
                {Translate("orders", "flech")}
              </div>
              <div className="Inlflx">{Translate("pickups", "editpickup")}</div>
              <div className="Inlflx StWdDst">#</div>
              <div className="Inlflx">{targetPickup.display_id}</div>
            </div>
          );
          this.isExternCity = targetPickup.wilaya;
          this.setState({
            id: targetPickup.id,
            display_id: targetPickup.display_id,
            orders: targetPickup.orders.map((el) => ({
              ...el,
              display_id: el.display_id_order,
            })),
            originalorders: targetPickup.orders.map((el) => ({
              ...el,
              display_id: el.display_id_order,
            })),
            providername:
              targetPickup.customer_name ||
              this.props.dataProfile.full_name ||
              this.props.dtStoreinfo.name,
            providerphonenumber:
              targetPickup.customer_phone || this.props.dtStoreinfo.phone,
            pickup_address: targetPickup.pickup_address,
            car_type: targetPickup.car_type,
            notetodriver: targetPickup.note_to_driver,
            status: targetPickup.status,
            titlepage: externTitle,
            titlebtn: Translate("orders", "save"),
            filtersource: FilterSource,
            mode: "updating",
          });
        }
      }
    } else {
      this.setState({
        providername:
          this.props.dataProfile.full_name || this.props.dtStoreinfo.name,
        providerphonenumber: this.props.dtStoreinfo.phone,
        pickup_address: this.props.dtStoreinfo.address,
      });
    }
    this.props.Getwilayas();
    this.props.GetOrders("?status=4&pagination=false", false);
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSelectAll() {
    const { allSelected, originalorders, orders, allOrders, mode } = this.state;
    if (allSelected) {
      this.setState({
        orders: mode === "updating" ? originalorders : [],
        allSelected: false,
      });
    } else {
      const remainedFromOriginal =
        mode === "updating"
          ? originalorders.filter((el) =>
              orders.find((elem) => elem.id === el.id)
            )
          : [];
      this.setState({
        // orders:remainedFromOriginal.concat(allOrders.map(el=>el.display_id)),
        orders: remainedFromOriginal.concat(
          allOrders.map((el) => ({ display_id: el.display_id, id: el.id }))
        ),
        allSelected: true,
      });
    }
  }

  handleUseSelect(e) {
    let current = this.state.orders;
    let index = current.findIndex((el) => el.id === e.id);
    if (index === -1) {
      current.push(e);
    } else {
      current.splice(index, 1);
    }
    this.selectIdlist = current;
    if (this.state.allSelected) {
      this.setState({
        allSelected: null,
        orders: current,
      });
    } else {
      this.setState({
        orders: current,
      });
    }
  }
  editIncludedORders(e) {
    let current = this.state.orders;
    let index = current.findIndex((el) => el.display_id === e.display_id);
    if (index === -1) {
      current.push(e);
    } else {
      current.splice(index, 1);
    }
    this.selectIdlist = current;
    if (this.state.allSelected) {
      this.setState({
        allSelected: null,
        orders: current,
      });
    } else {
      this.setState({
        orders: current,
      });
    }
  }

  handleSelect(elem, name) {
    if (elem) {
      if (
        name === "city" &&
        ((this.state.city && this.state.city[0] !== elem[0]) ||
          !this.state.city)
      ) {
        this.setState({ commune: null });
        this.props.Getcommunes("?wilaya=" + elem[0]);
      }
    }
    this.setState({
      [name]: elem,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const { dtStoreinfo } = this.props;
    let kkepGoing = true;
    let pickup = {},
      error = {};
    pickup.store = dtStoreinfo.id;
    // if (this.state.city && this.state.city[0]) {
    //   pickup.wilaya = this.state.city[0];
    // } else {
    //   error.ercity = Translate("error", "eo1");
    //   kkepGoing = false;
    // }
    if (this.state.commune && this.state.commune.id) {
      pickup.commune = this.state.commune.id;
    } else {
      // error.ercommune = Translate("error", "eo2");
      // kkepGoing = false;
      pickup.commune = this.props.dtStoreinfo.commune;
    }
    if (this.state.pickup_address) {
      pickup.pickup_address = this.state.pickup_address;
    } else {
      // error.eradress = Translate("error", "eo7");
      // kkepGoing = false;
      pickup.pickup_address = this.props.dtStoreinfo.address;
    }
    // if (this.state.providerphonenumber) {
    //   pickup.customer_phone = this.state.providerphonenumber;
    // } else {
    //   error.erphone = Translate("error", "eo5");
    //   kkepGoing = false;
    // }
    // if (this.state.providername) {
    //   pickup.customer_name = this.state.providername;
    // } else {
    //   error.ername = Translate("error", "eo4");
    //   kkepGoing = false;
    // }
    if (this.state.orders.length) {
      pickup.orders = this.state.orders.map((el) => el.id);
    } else {
      error.errorders = "Pickup request should include at least one order";
      kkepGoing = false;
    }

    if (kkepGoing) {
      this.submit = true;
      this.setState({ error: {} });

      pickup.note_to_driver = this.state.notetodriver;
      if (this.state.mode === "updating") {
        if (this.state.permmisionedit) {
          Event("ADD_PICKUP", "USE_UPDATE_PICKUP", "CLICK_EVENT");
          this.props.UpdatePickup(pickup, this.state.id);
        }
      } else {
        // Submit the request
        if (this.state.permmisionadd) {
          Event("ADD_PICKUP", "USE_ADD_PICKUP", "CLICK_EVENT");
          this.props.AddPickup(pickup);
        }
      }
    } else {
      Event(
        "ADD_PICKUP",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(error).join(",")}]`
      );
      this.setState({
        error: error,
      });
      document.getElementById("Container-root").scrollTo(0, 0);
    }
  }
  render() {
    const {
      PickupAdded,
      PickupUpdating,
      dataAddPickup,
      DataCommune,
      GetigCommune,
      VehiculesData,
      GettingPossibleOrders,
    } = this.props;

    let Alertfail = "";
    if ((PickupAdded === "3" || PickupUpdating === "3") && this.submit) {
      if (
        dataAddPickup &&
        dataAddPickup.response &&
        dataAddPickup.response.data
      ) {
        Alertfail = Translate("error", dataAddPickup.response.data.detail);
      }

      this.submit = false;
      this.setState({
        error: {},
      });
      this.props.SendAlert("50", Translate("alert", "failaddorde"), "a02");
    }

    if (!this.state.permmisionadd && !this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "addneworder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    if (!this.state.permmisionedit && this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "editorder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    const bodytab = this.state.allOrders.map((elem, index) => {
      const {
        display_id,
        product_name,
        customer_name,
        ordered_at,
        product_price,
        price,
        status,
        wilaya,
        commune_name,
        products,
        is_exchange,
        is_refund,
        exchanged_for_order,
        refunded_for_order,
      } = elem;
      let checked =
        this.state.orders.find((el) => el.id === elem.id) ||
        this.state.allSelected;
      let ordered_at2 = (
        <div style={{ minWidth: "95px" }}>{FormatDate(ordered_at)}</div>
      );

      let productsf = "";
      let blockindex = [0, 6, 8];
      if (Array.isArray(products) && products.length > 0) {
        if (products.length > 1) {
          let PrdctList = (
            <div
              className="InFlx Stclmnf"
              onClick={() => this.Gotodetail(display_id)}
            >
              {products.map((elem, key) => {
                return (
                  <div key={key} className="Inlflx TxTrnf Mrtpvl">
                    <strong className="StPaddingVal">
                      {" (" + elem.quantity + ") "}
                    </strong>
                    <SmallText>{elem.logistical_description}</SmallText>
                  </div>
                );
              })}
            </div>
          );
          productsf = (
            <DropedList
              props={{ ...this.props }}
              title={
                <div className="InFlx AlgnItm RlPs">
                  <span>{products.length}</span>
                  <span className="StPaddingVal">
                    {Translate("titles", "product")}
                  </span>
                  <span className="DsPlCnt">{FlechDwnSvg}</span>
                </div>
              }
              dataDrop={PrdctList}
            />
          );
          blockindex.push(2);
        } else {
          productsf = (
            <div className="Inlflx TxTrnf">
              <strong className="StPaddingVal">
                {" (" + products[0].quantity + ") "}
              </strong>
              <SmallText>{products[0].logistical_description}</SmallText>
            </div>
          );
        }
      } else {
        productsf = product_name;
      }
      let displayIdDiv = (
        <div className="Inlflx Stclmnf AlgnItm JstfCnt">
          <div>{display_id}</div>
        </div>
      );
      return [
        <CheckBoxForm
          name={display_id}
          id={"order_print_" + display_id}
          rmMarg={true}
          workfun={() => this.handleUseSelect(elem)}
          check={checked}
        />,
        displayIdDiv,
        productsf,
        customer_name,
        commune_name + " ( " + wilaya + " ) ",
        ordered_at2,
        { type: "forindex", blocked: blockindex, idaction: display_id },
      ];
    });
    let typesOptions = [];
    if (VehiculesData && VehiculesData.list)
      typesOptions = VehiculesData.list
        .map((vType) => ({
          text: Translate("vehiculetypes", vType.id + ""),
          val: vType.id,
        }))
        .slice(0, 3);

    return (
      <ContainerPage page_title={this.state.titlepage}>
        {(PickupAdded === "1" || PickupUpdating === "1") && <Loader />}
        <div className="ClSidTh StBrdTb StBgbrds widthAdd">
          <div className="FlWd TxtCn StRedClr">{Alertfail}</div>
          <form method="post" onSubmit={this.handleSubmit}>
            <ContainerPage
              subContainer
              page_title={Translate("titles", "order")}
              small_title={true}
            >
              {GettingPossibleOrders === "1" ? (
                <TableLoader />
              ) : (
                <div>
                  {(this.state.orders.length > 0 ||
                    this.state.originalorders) && (
                    <Fragment>
                      <div className="InFlx flxDrc">
                        <div className="stBdp StSmlS StwdMaxC">
                          {Translate("pickups", "includedorders")}
                        </div>
                      </div>
                      <div
                        className="InFlx flxDrc"
                        style={{ flexWrap: "wrap" }}
                      >
                        {/* Removed orders from the original list*/}
                        {/* {this.state.originalorders && this.state.originalorders.filter(el=>this.state.orders.indexOf(el)===-1).map((elem,key)=>{ */}
                        {this.state.originalorders &&
                          this.state.originalorders
                            .filter(
                              (el) =>
                                !this.state.orders.find(
                                  (elem) => elem.id === el.id
                                )
                            )
                            .map((elem, key) => {
                              return (
                                <div
                                  key={key}
                                  onClick={() => this.editIncludedORders(elem)}
                                  className="stBdp CrsPoi SthgMaxC"
                                >
                                  <InlinedElement
                                    style={{
                                      border: "2px solid #f44336",
                                      padding: "3px 6px",
                                      backgroundColor: "#f4433666",
                                      borderRadius: "5px",
                                    }}
                                    secnd={
                                      <div
                                        className="IcnSiz"
                                        style={{ zoom: ".8" }}
                                      >
                                        {AddSvg}
                                      </div>
                                    }
                                    first={
                                      <div className="StSmlS StwdMaxC">
                                        {elem.display_id}
                                      </div>
                                    }
                                  />
                                </div>
                              );
                            })}
                        {this.state.orders.map((elem, key) => {
                          return (
                            <div
                              key={key}
                              onClick={() => this.editIncludedORders(elem)}
                              className="stBdp CrsPoi SthgMaxC"
                            >
                              <InlinedElement
                                style={{
                                  border: "2px solid #3498DB",
                                  padding: "3px 6px",
                                  backgroundColor: "#3498DB35",
                                  borderRadius: "5px",
                                }}
                                secnd={
                                  <div
                                    className="IcnSiz"
                                    style={{ zoom: ".48" }}
                                  >
                                    {CloseSvg}
                                  </div>
                                }
                                first={
                                  <div className="StSmlS StwdMaxC">
                                    {elem.display_id}
                                  </div>
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Fragment>
                  )}
                  <Fragment>
                    <div className="InFlx flxDrc">
                      <div className="stBdp StSmlS StwdMaxC">
                        {Translate("pickups", "ordertoadd")}
                      </div>
                    </div>
                    {GettingPossibleOrders === "2" && (
                      <Table
                        thead_elem={[
                          <CheckBoxForm
                            rmMarg={true}
                            name="all_order"
                            labelClass="MrAot"
                            check={this.state.allSelected}
                            workfun={this.handleSelectAll}
                            id="select-all-orders-for-pickup"
                          />,
                          ...TABLE_HEAD,
                        ]}
                        tbody_elem={bodytab}
                      />
                    )}
                    {GettingPossibleOrders === "2" &&
                      this.state.allOrders.length === 0 && (
                        <div className="FlWd InFlx flxDrc JstfCnt TxtCn">
                          {Translate("orders", "noorder")}
                        </div>
                      )}
                    <div className="StRedClr">{this.state.error.errorders}</div>
                  </Fragment>
                </div>
              )}
            </ContainerPage>
            <ContainerPage
              subContainer
              page_title={Translate("pickups", "providerinfos")}
              small_title={true}
            >
              <div className="FlWd InFlx responsFlx flxDrc">
                <div className="Hlwd">
                  <GroupeForm
                    id={"providername"}
                    name={"providername"}
                    placeholder={Translate("auths", "fullname")}
                    text={Translate("auths", "fullname")}
                    type={"text"}
                    value={this.state.providername}
                    workfun={this.handleChange}
                    error={this.state.error.ername}
                  />
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm
                    id={"providerphonenumber"}
                    name={"providerphonenumber"}
                    placeholder={Translate("auths", "phone")}
                    text={Translate("auths", "phone")}
                    type={"tel"}
                    pattern={"[0]{1}[0-9]{9}"}
                    value={this.state.providerphonenumber}
                    workfun={this.handleChange}
                    error={this.state.error.erphone}
                  />
                </div>
              </div>
            </ContainerPage>
            <ContainerPage
              subContainer
              withOvrl={true}
              page_title={Translate("pickups", "details")}
              small_title={true}
            >
              <div
                className="FlWd InFlx responsFlx flxDrc"
                style={{ height: "100%" }}
              >
                <div className="Hlwd">
                  <GroupeForm
                    id={"pickup_address"}
                    name={"pickup_address"}
                    placeholder={Translate("pickups", "adress")}
                    text={Translate("pickups", "adress")}
                    type={"text"}
                    value={this.state.pickup_address}
                    workfun={this.handleChange}
                    error={this.state.error.eradress}
                  />
                  <div
                    className="FlWd InFlx responsFlx flxDrc"
                    style={{ margin: "28px 0 28px 0" }}
                  >
                    <div className="Hlwd"></div>
                    <div className="Hlwd"></div>
                  </div>
                </div>
                <div className="Hlwd Mrg2">
                  <Select
                    label={Translate("orders", "district")}
                    placholder={Translate("orders", "districtSearch")}
                    search={true}
                    Options={DataCommune}
                    fieldShow={"name"}
                    name="commune"
                    loading={GetigCommune === "0"}
                    value={this.state.commune}
                    onChange={this.handleSelect}
                    maxSize="200px"
                  />
                </div>
              </div>
            </ContainerPage>

            <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
              <Button BtnText={this.state.titlebtn} />
            </div>
          </form>
        </div>
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href
      .replace(window.location.hash, "")
      .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
        vars[key] = value !== undefined ? value : "";
      });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GettingTypes,
    VehiculesData,
  } = state.common;
  const {
    PickupAdded,
    dataAddPickup,
    PickupGetting,
    PickupsList,
    PickupUpdating,
    DataUpdatePickup,
    GettingPossibleOrders,
    OrdersList,
  } = state.pickups;
  const { dtStoreinfo, dataProfile } = state.user;

  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GettingTypes,
    VehiculesData,
    PickupAdded,
    dataAddPickup,
    PickupGetting,
    PickupsList,
    PickupUpdating,
    DataUpdatePickup,
    dtStoreinfo,
    dataProfile,
    GettingPossibleOrders,
    OrdersList,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetVehiculeTypes: commonActions.GetVehiculeTypes,
  GetOrders: pickupsActions.GetPossibleOrders,
  AddPickup: pickupsActions.AddPickup,
  UpdatePickup: pickupsActions.UpdatePickup,
  GetPickups: pickupsActions.GetPickups,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
};

const connectedAddPickupPage = connect(mapState, actionCreators)(AddPickupPage);
export { connectedAddPickupPage as AddPickupPage };
