import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {SimpleFlechRightSvg} from './IconSvg'
import { Link } from 'react-router-dom'
import { initGAPg,Event } from '.'

function ActionsList(props) {
    useEffect(()=>{
        initGAPg();
    
      },[])
    const handleClick=(actionTitle)=>{
        Event("ACTIONS","CLICK_ON_ACTION_ITEM",actionTitle)
    }
    return (
        <div className="FlWd actList">
            {props.items.map((item,index)=>(
                item.to?(<Link to={item.to || ""} onClick={()=>handleClick(item.title)}>
                    <div className="FlWd ClSidTh InFlx StBrdTb" style={{margin: "1px 0",borderRadius: index===props.items.length-1?"0px 0px 14px 14px":index===0?"14px 14px 0px 0px":"0",...item.style}}>
                        {item.icon &&
                        <div className="InFlx AlgnItm JstfCnt" style={{flexGrow: 0.05}}>
                            {item.icon}
                            

                        </div>}
                        <div style={{flexGrow: 0.8, padding: "0 2%", marginBottom: "1px", fontSize: "1.4rem", letterSpacing: "0.3px"}}>
                            <h6>{item.title}</h6>
                            
                        </div>
                        <div className="InFlx AlgnItm StFlxStr" style={{flexGrow: 0.2}}>
                            {SimpleFlechRightSvg}
                            

                        </div>


                    </div>
                </Link>):(
                    <div onClick={item.onClick} className="FlWd ClSidTh InFlx StBrdTb CrsPoi" style={{margin: "1px 0",borderRadius: index===props.items.length-1?"0px 0px 14px 14px":index===0?"14px 14px 0px 0px":"0"}}>
                    {item.icon &&
                    <div className="InFlx AlgnItm JstfCnt" style={{flexGrow: 0.05}}>
                        {item.icon}
                        

                    </div>}
                    <div style={{flexGrow: 0.8, padding: "0 2%", marginBottom: "1px", fontSize: "1.4rem", letterSpacing: "0.3px"}}>
                        <h6>{item.title}</h6>
                        
                    </div>
                    <div className="InFlx AlgnItm StFlxStr" style={{flexGrow: 0.2}}>
                        {SimpleFlechRightSvg}
                        

                    </div>


                </div>
                )

            ))}
            
        </div>
    )
}

ActionsList.propTypes = {
    items: PropTypes.any.isRequired

}

export default ActionsList

