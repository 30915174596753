import { paymentConstants } from '../constants';

export function Viewpayment(state, action) {
  switch (action.type) {
    case paymentConstants.VIEW_PAYMENT_REQUEST:
      return {
        ViewPay:"0",
        ViewPaydata: action.payload
      };
    case paymentConstants.VIEW_PAYMENT_SUCCESS:
      return {
        ViewPay:"1",
        ViewPaydata: action.payload
      };
    case paymentConstants.VIEW_PAYMENT_FAILURE:
      return {
        ViewPay:"2",
        ViewPaydata: action.payload
      };
    default:
        return {...state}
    }
}

export function facturepayment(state, action) {
  switch (action.type) {
    case paymentConstants.GET_FACTURE_REQUEST:
      return {
        GetingFacture:"0",
        Facturedata: action.payload
      };
    case paymentConstants.GET_FACTURE_SUCCESS:
      return {
        GetingFacture:"1",
        Facturedata: action.payload
      };
    case paymentConstants.GET_FACTURE_FAILURE:
      return {
        GetingFacture:"1",
        Facturedata: action.payload
      };

    case paymentConstants.EXPORT_FACTURES_REQUEST:
      return {
        GetingAllFacture:"0",
        MixedFacturedata: action.payload
      };
    case paymentConstants.EXPORT_FACTURES_SUCCESS:
      return {
        GetingAllFacture:"1",
        MixedFacturedata: action.payload
      };
    case paymentConstants.EXPORT_FACTURES_FAILURE:
      return {
        GetingAllFacture:"1",
        MixedFacturedata: action.payload
      };

    case "CLEAR_FACTURE":
      return {}
    default:
        return {...state}
    }
}

export function paymentMethod(state,action){
  switch (action.type) {
    case paymentConstants.ADD_CCP_REQUEST:
      return {
        ...state,
        addingCcp:"0",
        CcpData: action.payload
      };
    case paymentConstants.ADD_CCP_SUCCESS:
      return {
        addingCcp:"1",
        CcpData: action.payload
      };
    case paymentConstants.ADD_CCP_FAILURE:
      return {
        ...state,
        addingCcp:"2",
        CcpData: action.payload
      };
    case paymentConstants.UPDATE_CCP_REQUEST:
      return {
        ...state,
        updatingCcp:"0",
        CcpUpdateData: action.payload
      };
    case paymentConstants.UPDATE_CCP_SUCCESS:
      return {
        updatingCcp:"1",
        CcpUpdateData: action.payload
      };
    case paymentConstants.UPDATE_CCP_FAILURE:
      return {
        ...state,
        updatingCcp:"2",
        CcpUpdateData: action.payload
      };
    case paymentConstants.GET_CCP_REQUEST:
      return {
        getingCcp:"0",
        getCcpData: action.payload
      };
    case paymentConstants.GET_CCP_SUCCESS:
      return {
        getingCcp:"1",
        getCcpData: action.payload
      };
    case paymentConstants.GET_CCP_FAILURE:
      return {
        getingCcp:"2",
        getCcpData: action.payload
      };
    default:
        return {...state}
    }
}