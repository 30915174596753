import { FunctionService } from '../services';
import { alertConstants } from '../constants';
export const alertActions = {
    SendAlert
};
function SendAlert(code,text,action){
    return dispatch => {
        switch(code){
            case "41":
                dispatch(FunctionService.succes({txt:text,action:action},alertConstants.SEND_ALERT_SUCCESS))
                break;
            case "50":
                dispatch(FunctionService.failure({txt:text,action:action},alertConstants.SEND_ALERT_FAILURE))
                break;
            case "31":
                dispatch(FunctionService.succes({txt:text,action:action},alertConstants.SEND_ALERT_REQUEST))
                break;
            default :
                dispatch(FunctionService.failure('',"Remove"))
        }
    }
}