import React from 'react'
import PropTypes from 'prop-types'

const getBgColor=(color)=>{
    switch(color){
        case "primary": return "#008AFF";
        case "secondary": return "#FF4F4F";
        case "danger": return "#FF4F4F";
        case "success": return "##44BE84";
        default: return "#FF4F4F";

    }
}
function IconBadge(props) {

    return (
        <div style={{width:"40px",height:"40px"}} className="RlPs">
            {props.count >0 && <div className="StAbs InFlx AlgnItm JstfCnt" style={{width:"20px",height:"20px",right:0,top:0,borderRadius:"50%",backgroundColor:getBgColor(props.bdgColor),fontSize: "10px"}}>{props.count}</div>}
            <div className="FlWd FlHg InFlx AlgnItm JstfCnt SCSvg" >{props.icon}</div>
            
        </div>
      
    )
}

IconBadge.propTypes = {
    icon: PropTypes.node.isRequired,
    bdgTxt: PropTypes.string,
    count: PropTypes.number,
    bdgColor: PropTypes.oneOf(["primary","secondary","light","danger","success"])

}

export default IconBadge

