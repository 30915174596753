import React from "react"
import ContentLoader from "react-content-loader"
const RateLoader = (props) => (
        <ContentLoader 
        speed={2}
        viewBox="0 0 400 150"
        backgroundColor="var(--bdgclInp)"
        foregroundColor="var(--defcl)"
        {...props}
    > 
        <rect x="10" y="30" rx="0" ry="0" style={{width:"90%"}} height="45" />
        <rect x="18" y="105" rx="0" ry="0" style={{width:"35%"}} height="30" />
        <rect x="220" y="105" rx="0" ry="0" style={{width:"35%"}} height="30" />
    </ContentLoader>
  )
  
  export default RateLoader

 