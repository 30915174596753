import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import TabShow from "../../_Common/TabShow";
import { CloseSvg2, closeIcon } from "../../_Common/IconSvg";
import BtnLg from "../../_Common/BtnLg";
import { Translate } from "../../../../utils/lang/translate";
import { ProductInterface, ProductType, StockReleaseItemProduct } from "../../../../types";
import { Select } from "../../_Common";
import GroupeForm from "../../../_Common/GroupeForm";

interface StockReleaseFormModalProps {
  onClose: () => void,
  onFormSubmit: (data: Omit<StockReleaseItemProduct, "product_name">[]) => void,
  releaseProducts?: Omit<StockReleaseItemProduct, "product_name">[],
  loading: boolean,
  productsList: ProductType[]
};

interface ProductFieldError { 
  quantity: string | null, 
  product_name: string | null,
};

const StockReleaseFormModal = ({
  onClose,
  onFormSubmit,
  releaseProducts,
  loading,
  productsList
}: StockReleaseFormModalProps) => {

  const modalRef = useRef<HTMLDivElement>(null);
  
  const initial_state = { product: "", quantity: 0 };
  const [productsData, setProductsData] = useState<Omit<StockReleaseItemProduct, "product_name">[]>([initial_state]);
  const initial_error_state = {product_name: null, quantity: null};
  const [errors, setErrors] = useState<ProductFieldError[]>([initial_error_state]);

  const handleProductChange = (product: ProductInterface, index: number) => {
    let new_data = [...productsData];
    if(product) {
      new_data[index].product = product.id;
      if(errors[index].product_name) {
        let new_errors = errors;
        new_errors[index].product_name = null;
        setErrors(new_errors);
      }
    }else{
      new_data[index].product = "";
    }
    setProductsData(new_data);
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    let new_data = [...productsData];
    let new_value = isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value); 
    new_data[index].quantity = new_value;
    if(new_value !== 0 && errors[index].quantity) {
      let new_errors = errors;
      new_errors[index].quantity = null;
      setErrors(new_errors);
    } 
    setProductsData(new_data);
  };

  const handleRemoveProduct = (product_index: number) => {
    let new_data = productsData.filter((_, index) => index !== product_index);
    let new_errors = errors.filter((_, index) => index !== product_index);
    setErrors(new_errors)
    setProductsData(new_data);
  };

  const AddFieldProduct = () => {
    setProductsData([ ...productsData, initial_state ]);
    setErrors([ ...errors, { product_name: null, quantity: null } ]);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if(modalRef.current && !modalRef.current?.contains(e.target as Node)){
      onClose();
    }
  };

  const handleSubmit = () => {
    let new_errors = errors;
    productsData.forEach((item, index) => {
      if(item.product === "") new_errors[index].product_name = Translate("error", "reqfield");
      if(item.quantity === 0) new_errors[index].quantity = Translate("error", "quantitycant0");
    });
    if(new_errors.some((err, _) => err.product_name || err.quantity)){
      setErrors([...new_errors]);
    }else {
      onFormSubmit(productsData);
    }
  }

  useEffect(() => {
    window.addEventListener('mouseup', handleClickOutside);
    return () => window.removeEventListener('mouseup', handleClickOutside);
  }, []);

  useEffect(() => {
    if(releaseProducts && releaseProducts.length > 0){
      setProductsData([...releaseProducts.map((item, _) => ({ ...item }))]);
      setErrors(
        releaseProducts.map((_, __) => initial_error_state)
      );
    }
  }, []);

  return (
    <TabShow
      darkBg
      noReturn
      noPadding={true}
      style={{
        borderRadius: "8px",
        backgroundColor: "var(--defcl)",
        width: "fit-content"
      }}
    >
      <div 
        ref={modalRef} 
        className="InFlx Stclmnf" 
        style={{ 
          paddingTop: "10px", 
          paddingBottom: "10px", 
          gap: "10px", 
          minWidth: "fit-content" 
        }}
      >
        <div className="InFlx spcBtwn AlgnItm" style={{ padding: "5px 15px" }}>
          <div className="add-team-member-modal-header-text">
            {
              releaseProducts?.every((item, _) => item.quantity !== 0) 
              ? 
                Translate("product", "updatestockrelease") 
              : 
                Translate("product", "addstockrelease")
            }
          </div>
          <div
            onClick={onClose} 
            className="InFlx AlgnItm sb4R CrsPoi Gray" 
            style={{ padding: "8px" }}
          >
            {closeIcon}
          </div>
        </div>
        <div className="add-staff-member-modal-divider"/>
        <div className="InFlx Stclmnf" style={{ gap: "20px", padding: "5px 15px" }}>
          {
            productsData.map((releaseProductItem, index) => (
              <div className="InFlx align-items-start spcBtwn" key={index} style={{ gap: "20px" }}>
                <Select
                  label={Translate("product", "productname")}
                  placholder={Translate("product", "searchforproduct")}
                  search={true}
                  Options={
                    productsList.filter(
                      (product, _) => !productsData.some(
                        (item, itemIndex) => item.product === product.id && index !== itemIndex
                      )
                    )
                  }
                  fieldShow={"logistical_description"}
                  name={"release-product-" + index}
                  loading={loading}
                  value={productsList.find((product, _) => product.id === releaseProductItem.product) ?? null}
                  onChange={(option: ProductInterface, _: string) => handleProductChange(option, index)}
                  disabled={loading}
                  containerClass={errors[index].product_name ? "borderError" : ""}
                  error={errors[index].product_name ? errors[index].product_name : null}
                />
                <GroupeForm
                  name={"release-quantity-" + index}
                  placeholder={Translate("orders", "quantity")}
                  text={Translate("orders", "quantity")}
                  type={"number"}
                  value={releaseProductItem.quantity}
                  min={1}
                  workfun={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, index)}
                  stclass={errors[index].quantity ? "borderError" : ""}
                  error={errors[index].quantity ? errors[index].quantity : null}
                  style={{ minWidth: "200px" }}
                />
                {
                  productsData.length > 1
                  &&
                  <div
                    onClick={() => handleRemoveProduct(index)} 
                    className="CrsPoi">
                    {CloseSvg2}
                  </div>
                }
              </div>
            ))
          }
          <div className="FlWd StdirLn">
            <span className="StBle CrsPoi" onClick={() => AddFieldProduct()}>
              {"+ " + Translate("orders", "addanotherproduct")}
            </span>
          </div>
        </div>
        <div
          className="Inlflx AlgnItm JstfCnt CrsPoi"
          onClick={handleSubmit}
        >
          <BtnLg
            disabled={loading}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 7px"
            }} 
            text={
              releaseProducts?.every((item, _) => item.quantity !== 0)
              ? Translate("orders", "save")
              : Translate("shop", "add")
            }
          />
        </div>
      </div>
    </TabShow>
  );
};
 
export default  StockReleaseFormModal;