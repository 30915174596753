import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ContainerPage from "../_Common/ContainerPage";
import { Translate } from "../../../utils/lang/translate";
import Table from "../_Common/Table";
import {
  productActions,
  commonActions,
  ordersActions,
  userActions,
} from "../../../actions";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import {
  PackageSvg,
  WarehousesTreeSvg,
  ViewSvg,
  DeletSvg,
  NotifSvg,
} from "../_Common/IconSvg";
import StatsElement from "../_Common/StatsElement";
import RateLoader from "../../StorePages/DashboardPage/RateLoader";
import TableLoader from "../_Common/TableLoader";
import BtnLg from "../_Common/BtnLg";
import TabShow from "../_Common/TabShow";
import DatePicker from "../../_Common/DatePicker";
import { WarehousesAvail } from "./ProductAvail";
import { StockSettingsAlert } from "./ProductAlert";
import ConfirmationAlert from "../../_Common/ConfirmationAlert";
import Loader from "../../_Common/Loader";
import { Link } from "react-router-dom";
import { initGAPg, Event } from "../_Common";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import ReactTooltip from "react-tooltip";
const urlPropsQueryConfig = {
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
  page: { type: UrlQueryParamTypes.number },
};

const ObjetToTab = (dataset, el1, el2) => {
  let tab2 = [];
  Array.isArray(dataset) &&
    dataset.forEach((elem) => {
      tab2.push([elem[el1], elem[el2]]);
    });
  return tab2;
};
export const ConfirmDeleteAlert = (props) => (
  <TabShow tab_title={Translate("profile", "confirmaction")} wdth="550px">
    {props.ProductDeleted === "1" && <Loader />}
    <div className="MrAot" style={{ width: "94%" }}>
      <p className="StdirLn FlWd DltTpMar">
        {Translate("product", "confrmproduct")}
      </p>
      <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
        <div
          className="StwdMaxC CrsPoi StWdDst"
          style={{ height: "30px" }}
          onClick={() => props.onCancel()}
        >
          <BtnLg text={Translate("profile", "cancel")} classSet={""} />
        </div>
        <div
          className="StwdMaxC CrsPoi StWdDst"
          style={{ height: "30px" }}
          onClick={() => props.Delete()}
        >
          <BtnLg text={Translate("profile", "delete")} classSet={"TxDng"} />
        </div>
      </div>
    </div>
  </TabShow>
);

class ProductDetailPage extends Component {
  constructor(props) {
    super(props);
    let statusList = [];
    if (this.props.dtStoreinfo)
      statusList = this.props.dtStoreinfo.stock_managed
        ? ["11", "31", "41", "50"]
        : ["4", "31", "41", "50"];
    this.state = {
      titlepage: (
        <div>
          <Link className="StOpcVal" to="/stock/products">
            {Translate("titles", "product")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>{" "}
          <span>{Translate("product", "productdetails")}</span>
        </div>
      ),
      product: null,
      statusList: statusList,
      chartData: Object.assign({}, ...statusList.map((el) => ({ [el]: null }))),
      showWarehouseDetails: false,
      showStockSettings: false,
      showMoreSettings: true,
      alertdelete: false,
      criticalLevel: 0,
    };
    this.IndexState = 0;
    this.CallProps = false;
    this.moreRef = React.createRef();
    this.handleClickOutsideMoreMenu =
      this.handleClickOutsideMoreMenu.bind(this);
    this.setLowStockLevel = this.setLowStockLevel.bind(this);
    this.GotToEdit = this.GotToEdit.bind(this);
  }
  static propTypes = {
    began: PropTypes.string,
    end: PropTypes.string,
    page: PropTypes.number,
    /********************** */
    onChangeBegan: PropTypes.func,
    onChangeEnd: PropTypes.func,
    onChangePage: PropTypes.func,
  };

  componentWillReceiveProps(nextProps) {
    const { idprod } = nextProps.match.params;

    const { began, end, ProductDeleted } = nextProps;
    let currentFilter = this.getFilterToState(this.props);
    let nextFilter = this.getFilterToState(nextProps);
    if (
      (this.props.began !== began ||
        this.props.end !== end ||
        this.CallProps) &&
      this.state.product &&
      nextFilter !== currentFilter
    ) {
      this.getStats(nextProps);
      this.CallProps = false;
    }
    if (this.props.ProductDeleted === "1" && ProductDeleted === "2") {
      this.setState({
        alertdelete: false,
      });
      this.props.history.push("/stock/products");
    }

    const { ProductFinded, data } = nextProps;
    if (
      this.props.ProductFinded !== "2" &&
      ProductFinded === "2" &&
      data &&
      data.list &&
      Array.isArray(data.list)
    ) {
      let product = data.list.find(
        (product) => product.display_id + "" === idprod + ""
      );
      if (product) {
        this.setState({
          product: product,
          criticalLevel: product.low_stock_level,
        });
        this.showdata = true;
        // this.props.ViewStockProduct(product.id)
        this.props.ViewStockDetails(product.id);
        this.props.GetProductHistory(product.id, this.getFilterToState());
        this.props.GetProductPerformance(product.id, this.getFilterToState());
        this.props.GetAvailableQuantity(product.id);
      } else {
        this.props.history.push("/stock/products");
      }
    }

    const { ProductUpdated, dataUpdate } = nextProps;
    if (this.props.ProductUpdated === "1" && ProductUpdated === "2") {
      this.setState({
        showStockSettings: false,
        criticalLevel: dataUpdate.data.low_stock_level,
      });
    }
  }
  resetFilter() {
    const { ClearOrderStore } = this.props;
    Event("PRODUCT_DETAIL", "RESET_ALL_FILTERS", "CLICK_EVENT");
    ClearOrderStore();
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  getStats(props) {
    this.props.GetProductHistory(
      this.state.product.id,
      this.getFilterToState(props)
    );
    this.props.GetProductPerformance(
      this.state.product.id,
      this.getFilterToState(props)
    );
  }

  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "product");
    initGAPg();

    this.props.GetAllProduct();
  }
  componentWillUnmount() {
    this.resetFilter();
    document.removeEventListener("mousedown", this.handleClickOutsideMoreMenu);
  }
  GotToEdit(path) {
    Event("PRODUCT_DETAIL", "GO_TO_EDIT_FROM_DETAIL_PAGE", "CLICK_EVENT");
    this.props.history.push(path, { page: this.state.pagesource });
  }
  controlExport() {
    let bindthis = this;
    Event("PRODUCT_DETAIL", "DROP_MORE_LIST", "CLICK_EVENT");
    let clsList = document.getElementById("more_options_div");

    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      // clsList.style.marginTop="50px"
      clsList.classList.add("hidElem2");
      document.removeEventListener(
        "mousedown",
        bindthis.handleClickOutsideMoreMenu
      );
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "auto";
      // clsList.style.marginTop="50px"
      document.addEventListener(
        "mousedown",
        bindthis.handleClickOutsideMoreMenu
      );
    }
  }

  controlMore() {
    Event("PRODUCT_DETAIL", "USE_IMPORT_BUTTON", "CLICK_EVENT");
    let clsList = document.getElementById("List-more-Ac");
    let clsList2 = document.getElementById("more_options_div").classList;
    if (clsList2.value.indexOf("hidElem2") === -1) {
      this.controlExport();
    }
    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      clsList.classList.add("hidElem2");
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "55px";
    }
  }
  handleClickOutsideMoreMenu(event) {
    if (this.moreRef && !this.moreRef.current.contains(event.target)) {
      this.controlExport();
    }
  }
  deleteProduct(confirmed) {
    if (confirmed) {
      Event("PRODUCT_DETAIL", "CONFIRM_REMOVE_PRODUCT", "CLICK_EVENT");
      this.props.DeletProduct(this.state.product.id);
    } else {
      Event("PRODUCT_DETAIL", "USE_REMOVE_PRODUCT", "CLICK_EVENT");
      this.setState({
        alertdelete: true,
      });
    }
  }
  toggleShowWdetails(value) {
    Event("PRODUCT_DETAIL", "TOGGLE_SHOW_STOCK_DETAILS", "CLICK_EVENT");
    this.setState({
      showWarehouseDetails: value,
      titlepage: (
        <div>
          <Link className="StOpcVal" to="/stock/products">
            {Translate("titles", "product")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span
            className="CrsPoi"
            onClick={() => this.toggleShowWdetails(false)}
          >
            {Translate("product", "productdetails")}
          </span>
          {value && (
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          )}
          {value && <span>{Translate("product", "stockdetails")}</span>}
        </div>
      ),
    });
  }
  setLowStockLevel(e) {
    e.preventDefault();
    Event("PRODUCT_DETAIL", "SET_LOW_STOCK_LEVEL", "SUBMIT_EVENT");
    this.props.UpdateProduct({
      id: this.state.product.id,
      low_stock_level: this.state.criticalLevel,
    });
  }

  render() {
    const {
      ProductFinded,
      StockLoadded,
      GettingAvailQty,
      AvailQtyData,
      DataStock,
      GettingProductHist,
      dataHist,
      GettingProductPerf,
      dataPerf,
      idprod,
    } = this.props;

    let warehousesData = [];
    if (StockLoadded === "2" && DataStock && Array.isArray(DataStock))
      warehousesData = DataStock.map((el) => [
        el.warehouse_name,
        el.quantity +
          (el.incoming_quantity ? ` (${el.incoming_quantity} Incoming)` : ""),
        el.available_quantity,
        el.defictive_quantity,
      ]);

    let MoreDiv = (
      <div className="Inlflx AlgnItm Stclmnf JstfCnt FlWd" ref={this.moreRef}>
        {/* {false && <div className="Inlflx AlgnItm StDrc JstfCnt FlWd" >
            <GroupeBtnSp 
                style={{transform:"scale(1.2)"}} 
                data_btn={[{type:"BDgInpc",action:()=>this.GotToEdit("/products/update/"+this.state.product.id),svgbtn:MoreHDotsSvg},{type:"BDgInpc",action:() => null,svgbtn:MergeSvg},{type:"BDgInpc",action:()=>this.controlExport(),svgbtn:MoreHDotsSvg}]}
            />
          </div>} */}
        {true && (
          <div className="Inlflx AlgnItm StDrc JstfCnt FlWd">
            <GroupeBtnSp
              style={{ transform: "scale(1.2)" }}
              data_btn={[
                {
                  type: "BDgInpc",
                  action: () => this.setState({ showStockSettings: true }),
                  svgbtn: NotifSvg,
                  tooltip: Translate("titles", "setlowstockalert"),
                },
              ]}
            />
          </div>
        )}
        <div className="RlPs FlWd">
          <div
            className="StAbs FlWd InFlx StDrc AlgnItm"
            style={{ zIndex: "2", marginTop: "10px" }}
          >
            <div
              id="more_options_div"
              className="hidElem2 HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1"
              style={{ margiTop: "30px", width: "80%" }}
            >
              <div
                className="CrsPoi"
                style={{ height: "55px" }}
                onClick={() => this.deleteProduct()}
              >
                <div className="InFlx flxDrc FlHg BtNseti AlgnItm">
                  <div style={{ flexGrow: 1 }}>
                    <div className="IcnSiz" style={{ zoom: "1.2" }}>
                      {DeletSvg}
                    </div>
                  </div>
                  <div style={{ flexGrow: 3 }}>
                    <div>{Translate("product", "removeproduct")}</div>
                  </div>
                </div>
              </div>
              <div
                className="CrsPoi"
                style={{ height: "55px" }}
                onClick={() => this.setState({ showStockSettings: true })}
              >
                <div className="InFlx flxDrc FlHg BtNseti AlgnItm">
                  <div style={{ flexGrow: 1 }}>
                    <div
                      className="IcnSiz"
                      style={{ zoom: "1.2" }}
                      data-tip
                      data-for={`low-stock-tooltip`}
                    >
                      {NotifSvg}
                    </div>
                    <ReactTooltip id={`low-stock-tooltip`}>
                      {Translate("titles", "setlowstockalert")}
                    </ReactTooltip>
                  </div>
                  <div style={{ flexGrow: 3 }}>
                    <div>{Translate("titles", "setlowstockalert")}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    const statisticStatus = {
      ordered: "4",
      shipped: "31",
      delivered: "41",
      cancled: "50",
    };

    return (
      <ContainerPage
        page_title={this.state.titlepage}
        data_top={
          <div className="d-large">
            <DatePicker
              forceUp={() => (this.CallProps = true)}
              initDate={"t2"}
              from={this.props.began}
              to={this.props.end}
              changeFrom={this.props.onChangeBegan}
              changeTo={this.props.onChangeEnd}
              chnageDate={this.props.onChangeDate}
            />
          </div>
        }
      >
        <div className="d-large">
          <div className="flex-grid FlWd StDrc spctwB">
            {this.state.alertdelete && (
              <ConfirmDeleteAlert
                onCancel={() => this.setState({ alertdelete: false })}
                Delete={() => this.deleteProduct(true)}
                ProductDeleted={this.props.ProductDeleted}
              />
            )}
            {this.state.showStockSettings && (
              <StockSettingsAlert
                defaultValue={this.state.criticalLevel}
                onChange={(e) =>
                  this.setState({ criticalLevel: e.target.value })
                }
                onSubmit={this.setLowStockLevel}
                onClose={() => this.setState({ showStockSettings: false })}
              />
            )}
            <div className="col" style={{ flexGrow: 0.25 }}>
              {ProductFinded === "2" && this.state.product && (
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%", minHeight: "20vh" }}
                >
                  <div className="FlWd InFlx JstfCnt AlgnItm">
                    <div className="FlWd InFlx Stclmnf JstfCnt AlgnItm">
                      {/* <img  src={"/assets/img/product.png"} alt="" style={{width: "8vw", height: "8vw", borderRadius: "50%"}}/> */}
                      <div
                        className="FlWd InFlx JstfCnt AlgnItm"
                        style={{ zoom: 3.5 }}
                      >
                        {PackageSvg}
                      </div>
                      <h3
                        className="StdirLn"
                        style={{ margin: "5% 0%", textAlign: "center" }}
                      >
                        {this.state.product.logistical_description}
                      </h3>
                    </div>
                  </div>
                  <div className="FlWd InFlx JstfCnt AlgnItm">{MoreDiv}</div>
                </div>
              )}
              {/* Warehouses quantities tab */}
              {StockLoadded === "1" && (
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%", margin: "3vh 0", minHeight: "20vh" }}
                >
                  <TableLoader />
                </div>
              )}
              {StockLoadded === "2" && DataStock && (
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%", margin: "3vh 0", minHeight: "20vh" }}
                >
                  <div className="FlWd InFlx spctwB AlgnItm">
                    <h3
                      className="StdirLn"
                      style={{ margin: "5% 0%", textAlign: "center" }}
                    >
                      {Translate("titles", "stock")}
                    </h3>
                    <div>
                      <GroupeBtnSp
                        style={{ transform: "scale(1.2)" }}
                        data_btn={[
                          {
                            type: "BDgInpc",
                            action: () => this.toggleShowWdetails(true),
                            svgbtn: WarehousesTreeSvg,
                            tooltip: Translate("product", "stockdetails"),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="FlWd">
                    <Table
                      thead_elem={[]}
                      tbody_elem={ObjetToTab(
                        DataStock,
                        "warehouse_name",
                        "quantity"
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Right side area */}
            {this.state.showWarehouseDetails && (
              <div className="col" style={{ flexGrow: 0.7 }}>
                <WarehousesAvail
                  loading={ProductFinded === "1"}
                  data={warehousesData}
                />
              </div>
            )}
            {!this.state.showWarehouseDetails && (
              <div className="col" style={{ flexGrow: 0.7 }}>
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%" }}
                >
                  <div className="FlWd InFlx Stclmnf JstfCnt AlgnItm">
                    <div className="FlWd">
                      <h3 className="StdirLn" style={{ margin: 0 }}>
                        {Translate("dashboard", "performance")}
                      </h3>
                    </div>
                    {GettingProductPerf === "2" && dataPerf && (
                      <div className="flex-grid FlWd StDrc spctwB">
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          <StatsElement
                            title={Translate("dashboard", "deliveryrate")}
                            value={
                              this.showdata
                                ? Math.floor(dataPerf.delivery_rate) + "%"
                                : "_"
                            }
                            subcontent={
                              <span>
                                <span
                                  style={{
                                    color:
                                      Math.floor(dataPerf.diff_delivery_rate) >
                                      0
                                        ? "#2ECC71"
                                        : "#FF4F4F",
                                    fontSize: "110%",
                                  }}
                                >
                                  {this.showdata
                                    ? (dataPerf.diff_delivery_rate > 0
                                        ? "+"
                                        : "") +
                                      Math.floor(dataPerf.diff_delivery_rate) +
                                      "%"
                                    : "_"}
                                </span>{" "}
                                {Translate("dashboard", "fromlastperiod")}
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          <StatsElement
                            title={Translate("product", "avgordersday")}
                            value={
                              this.showdata
                                ? Math.floor(dataPerf.avg_order_per_day)
                                    .toFixed(2)
                                    .toString()
                                : "_"
                            }
                            subcontent={
                              <span>
                                <span
                                  style={{
                                    color:
                                      Math.floor(
                                        dataPerf.diff_avg_order_per_day
                                      ) > 0
                                        ? "#2ECC71"
                                        : "#FF4F4F",
                                    fontSize: "110%",
                                  }}
                                >
                                  {this.showdata
                                    ? dataPerf.diff_avg_order_per_day > 0
                                      ? "+"
                                      : "" +
                                        Math.floor(
                                          dataPerf.diff_avg_order_per_day
                                        )
                                    : "_"}
                                </span>{" "}
                                {Translate("dashboard", "fromlastperiod")}
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          <StatsElement
                            title={Translate("dashboard", "cancellationrate")}
                            value={
                              this.showdata
                                ? Math.floor(dataPerf.retention_rate) + "%"
                                : "_"
                            }
                            subcontent={
                              <span>
                                <span
                                  style={{
                                    color:
                                      Math.floor(dataPerf.diff_retention_rate) >
                                      0
                                        ? "#2ECC71"
                                        : "#FF4F4F",
                                    fontSize: "110%",
                                  }}
                                >
                                  {this.showdata
                                    ? (dataPerf.diff_retention_rate > 0
                                        ? "+"
                                        : "") +
                                      Math.floor(dataPerf.diff_retention_rate) +
                                      "%"
                                    : "_"}
                                </span>{" "}
                                {Translate("dashboard", "fromlastperiod")}
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          {!(GettingAvailQty == "2" && AvailQtyData) ? (
                            <RateLoader />
                          ) : (
                            <StatsElement
                              title={Translate("product", "availableqt")}
                              value={AvailQtyData.availibale_quantity}
                              subcontent={<span></span>}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "2% 5%", margin: "3vh 0" }}
                >
                  <div className="FlWd InFlx spctwB AlgnItm">
                    <h3 className="StdirLn" style={{ textAlign: "center" }}>
                      {Translate("product", "ordershistory")}
                    </h3>
                    <div>
                      <GroupeBtnSp
                        style={{ transform: "scale(1.2)" }}
                        data_btn={[
                          {
                            type: "BDgInpc",
                            action: () =>
                              this.props.history.push(
                                "/orders?status=" +
                                  (this.state.statusList.join("%2C") +
                                    this.getFilterToState().replace("?", "&"))
                              ),
                            svgbtn: ViewSvg,
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="FlWd InFlx JstfCnt AlgnItm">
                    {GettingProductHist === "1" &&
                      [1, 2, 3, 4].map((el) => <RateLoader key={el} />)}
                    {GettingProductHist === "2" &&
                      dataHist &&
                      Object.keys(statisticStatus).map((status, idx) => {
                        return (
                          <div style={{ flexGrow: 0.2 }} key={idx}>
                            <div className="BtNset StMrtp1">
                              <div>
                                <span
                                  className="DsBlc StSmlS TxtWht"
                                  style={{ fontSize: "15px", opacity: 0.9 }}
                                >
                                  {Translate(
                                    "statusorder",
                                    statisticStatus[status]
                                  )}
                                </span>
                              </div>
                              <div>
                                <span
                                  className="StWdDst DsBlc stbold StSmlS"
                                  style={{ fontSize: "30px" }}
                                >
                                  {dataHist[status]}
                                </span>
                              </div>
                              {true && (
                                <span className="DsBlc StSmlS StOpcVal">
                                  <span>
                                    <span
                                      style={{
                                        color:
                                          Number(dataHist["diff_" + status]) > 0
                                            ? "#2ECC71"
                                            : "#FF4F4F",
                                        fontSize: "110%",
                                      }}
                                    >
                                      {Number(dataHist["diff_" + status]) > 0
                                        ? "+"
                                        : ""}
                                      {dataHist["diff_" + status]}
                                    </span>
                                    {" % "}
                                    {Translate("dashboard", "fromlastperiod")}
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="d-sm">
          <div style={{ marginTop: "10px", marginBottom: "20px" }}>
            <DatePicker
              forceUp={() => (this.CallProps = true)}
              initDate={"t2"}
              from={this.props.began}
              to={this.props.end}
              changeFrom={this.props.onChangeBegan}
              changeTo={this.props.onChangeEnd}
              chnageDate={this.props.onChangeDate}
            />
          </div>

          <div
            className=""
            style={{ display: "flex", flexDirection: "column" }}
          >
            {this.state.alertdelete && (
              <ConfirmDeleteAlert
                onCancel={() => this.setState({ alertdelete: false })}
                Delete={() => this.deleteProduct(true)}
                ProductDeleted={this.props.ProductDeleted}
              />
            )}
            {this.state.showStockSettings && (
              <StockSettingsAlert
                defaultValue={this.state.criticalLevel}
                onChange={(e) =>
                  this.setState({ criticalLevel: e.target.value })
                }
                onSubmit={this.setLowStockLevel}
                onClose={() => this.setState({ showStockSettings: false })}
              />
            )}
            <div className="col" style={{ flexGrow: 0.25 }}>
              {ProductFinded === "2" && this.state.product && (
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%", minHeight: "20vh" }}
                >
                  <div className="FlWd InFlx JstfCnt AlgnItm">
                    <div className="FlWd InFlx Stclmnf JstfCnt AlgnItm">
                      {/* <img  src={"/assets/img/product.png"} alt="" style={{width: "8vw", height: "8vw", borderRadius: "50%"}}/> */}
                      <div
                        className="FlWd InFlx JstfCnt AlgnItm"
                        style={{ zoom: 3.5 }}
                      >
                        {PackageSvg}
                      </div>
                      <h3
                        className="StdirLn"
                        style={{ margin: "5% 0%", textAlign: "center" }}
                      >
                        {this.state.product.logistical_description}
                      </h3>
                    </div>
                  </div>
                  <div className="FlWd InFlx JstfCnt AlgnItm">{MoreDiv}</div>
                </div>
              )}
              {/* Warehouses quantities tab */}
              {StockLoadded === "1" && (
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%", margin: "3vh 0", minHeight: "20vh" }}
                >
                  <TableLoader />
                </div>
              )}
              {StockLoadded === "2" && DataStock && (
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%", margin: "3vh 0", minHeight: "20vh" }}
                >
                  <div className="FlWd InFlx spctwB AlgnItm">
                    <h3
                      className="StdirLn"
                      style={{ margin: "5% 0%", textAlign: "center" }}
                    >
                      {Translate("titles", "stock")}
                    </h3>
                    <div>
                      <GroupeBtnSp
                        style={{ transform: "scale(1.2)" }}
                        data_btn={[
                          {
                            type: "BDgInpc",
                            action: () => this.toggleShowWdetails(true),
                            svgbtn: WarehousesTreeSvg,
                            tooltip: Translate("product", "stockdetails"),
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div className="FlWd">
                    <Table
                      thead_elem={[]}
                      tbody_elem={ObjetToTab(
                        DataStock,
                        "warehouse_name",
                        "quantity"
                      )}
                    />
                  </div>
                </div>
              )}
            </div>
            {/* Right side area */}
            {this.state.showWarehouseDetails && (
              <div className="col" style={{ flexGrow: 0.7 }}>
                <WarehousesAvail
                  loading={ProductFinded === "1"}
                  data={warehousesData}
                />
              </div>
            )}
            {!this.state.showWarehouseDetails && (
              <div className="col" style={{ flexGrow: 0.7 }}>
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "5%" }}
                >
                  <div
                    className=""
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <div className="FlWd">
                      <h3 className="StdirLn" style={{ margin: 0 }}>
                        {Translate("dashboard", "performance")}
                      </h3>
                    </div>
                    {GettingProductPerf === "2" && dataPerf && (
                      <div className="">
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          <StatsElement
                            title={Translate("dashboard", "deliveryrate")}
                            value={
                              this.showdata
                                ? Math.floor(dataPerf.delivery_rate) + "%"
                                : "_"
                            }
                            subcontent={
                              <span>
                                <span
                                  style={{
                                    color:
                                      Math.floor(dataPerf.diff_delivery_rate) >
                                      0
                                        ? "#2ECC71"
                                        : "#FF4F4F",
                                    fontSize: "110%",
                                  }}
                                >
                                  {this.showdata
                                    ? (dataPerf.diff_delivery_rate > 0
                                        ? "+"
                                        : "") +
                                      Math.floor(dataPerf.diff_delivery_rate) +
                                      "%"
                                    : "_"}
                                </span>{" "}
                                {Translate("dashboard", "fromlastperiod")}
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          <StatsElement
                            title={Translate("product", "avgordersday")}
                            value={
                              this.showdata
                                ? Math.floor(dataPerf.avg_order_per_day)
                                    .toFixed(2)
                                    .toString()
                                : "_"
                            }
                            subcontent={
                              <span>
                                <span
                                  style={{
                                    color:
                                      Math.floor(
                                        dataPerf.diff_avg_order_per_day
                                      ) > 0
                                        ? "#2ECC71"
                                        : "#FF4F4F",
                                    fontSize: "110%",
                                  }}
                                >
                                  {this.showdata
                                    ? dataPerf.diff_avg_order_per_day > 0
                                      ? "+"
                                      : "" +
                                        Math.floor(
                                          dataPerf.diff_avg_order_per_day
                                        )
                                    : "_"}
                                </span>{" "}
                                {Translate("dashboard", "fromlastperiod")}
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          <StatsElement
                            title={Translate("dashboard", "cancellationrate")}
                            value={
                              this.showdata
                                ? Math.floor(dataPerf.retention_rate) + "%"
                                : "_"
                            }
                            subcontent={
                              <span>
                                <span
                                  style={{
                                    color:
                                      Math.floor(dataPerf.diff_retention_rate) >
                                      0
                                        ? "#2ECC71"
                                        : "#FF4F4F",
                                    fontSize: "110%",
                                  }}
                                >
                                  {this.showdata
                                    ? (dataPerf.diff_retention_rate > 0
                                        ? "+"
                                        : "") +
                                      Math.floor(dataPerf.diff_retention_rate) +
                                      "%"
                                    : "_"}
                                </span>{" "}
                                {Translate("dashboard", "fromlastperiod")}
                              </span>
                            }
                          />
                        </div>
                        <div
                          style={{ flexGrow: 0.25 }}
                          className="InFlx JstfCnt AlgnItm"
                        >
                          {!(GettingAvailQty == "2" && AvailQtyData) ? (
                            <RateLoader />
                          ) : (
                            <StatsElement
                              title={Translate("product", "availableqt")}
                              value={AvailQtyData.availibale_quantity}
                              subcontent={<span></span>}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="StBrdTb StBgbrds ClSidTh SthgMaxC"
                  style={{ padding: "2% 5%", margin: "3vh 0" }}
                >
                  <div className="FlWd InFlx spctwB AlgnItm">
                    <h3 className="StdirLn" style={{ textAlign: "center" }}>
                      {Translate("product", "ordershistory")}
                    </h3>
                    <div>
                      <GroupeBtnSp
                        style={{ transform: "scale(1.2)" }}
                        data_btn={[
                          {
                            type: "BDgInpc",
                            action: () =>
                              this.props.history.push(
                                "/orders?status=" +
                                  (this.state.statusList.join("%2C") +
                                    this.getFilterToState().replace("?", "&"))
                              ),
                            svgbtn: ViewSvg,
                          },
                        ]}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {GettingProductHist === "1" &&
                      [1, 2, 3, 4].map((el) => <RateLoader key={el} />)}
                    {GettingProductHist === "2" &&
                      dataHist &&
                      Object.keys(statisticStatus).map((status, idx) => {
                        return (
                          <div style={{ flexGrow: 0.2 }} key={idx}>
                            <div className="BtNset StMrtp1">
                              <div>
                                <span
                                  className="DsBlc StSmlS TxtWht"
                                  style={{ fontSize: "15px", opacity: 0.9 }}
                                >
                                  {Translate(
                                    "statusorder",
                                    statisticStatus[status]
                                  )}
                                </span>
                              </div>
                              <div>
                                <span
                                  className="StWdDst DsBlc stbold StSmlS"
                                  style={{ fontSize: "30px" }}
                                >
                                  {dataHist[status]}
                                </span>
                              </div>
                              {true && (
                                <span className="DsBlc StSmlS StOpcVal">
                                  <span>
                                    <span
                                      style={{
                                        color:
                                          Number(dataHist["diff_" + status]) > 0
                                            ? "#2ECC71"
                                            : "#FF4F4F",
                                        fontSize: "110%",
                                      }}
                                    >
                                      {Number(dataHist["diff_" + status]) > 0
                                        ? "+"
                                        : ""}
                                      {dataHist["diff_" + status]}
                                    </span>
                                    {" % "}
                                    {Translate("dashboard", "fromlastperiod")}
                                  </span>
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href
      .replace(window.location.hash, "")
      .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
        vars[key] = value !== undefined ? value : "";
      });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const { GetPrdPage, PrdPageData } = state.UnlinkProduct;
  const {
    ProductAdded,
    dataProduct,
    ProductFinded,
    data,
    GettingProductHist,
    dataHist,
    GettingProductPerf,
    dataPerf,
  } = state.product;
  const {
    StockLoadded,
    DataStock,
    ProductDeleted,
    dataDelete,
    ProductUpdated,
    dataUpdate,
    GettingAvailQty,
    AvailQtyData,
  } = state.Stock;
  const { OrderGeting, OrdersList } = state.orders;
  const { dataProfile, dtStoreinfo } = state.user;
  return {
    GetPrdPage,
    PrdPageData,
    GettingProductHist,
    dataHist,
    GettingProductPerf,
    dataPerf,
    StockLoadded,
    GettingAvailQty,
    AvailQtyData,
    DataStock,
    ProductDeleted,
    dataDelete,
    ProductUpdated,
    dataUpdate,
    dataProfile,
    dtStoreinfo,
    ProductAdded,
    dataProduct,
    data,
    ProductFinded,
    OrderGeting,
    OrdersList,
  };
}

const actionCreators = {
  GetProductPage: productActions.GetProductPage,
  ViewStockProduct: productActions.ViewStockProduct,
  GetAvailableQuantity: productActions.GetAvailableQuantity,
  ViewStockDetails: productActions.ViewStockDetails,
  GetProductHistory: productActions.GetProductHistory,
  GetProductPerformance: productActions.GetProductPerformance,
  GetAllProduct: productActions.GetAllProduct,
  UpdateProduct: productActions.UpdateProduct,
  DeletProduct: productActions.DeletProduct,
  GetOrders: ordersActions.GetOrders,
  ClearOrderStore: ordersActions.ClearOrderStore,
  ClearStore: commonActions.ClearStore,
};

const connectedProductDetailPage = connect(
  mapState,
  actionCreators
)(ProductDetailPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(
  connectedProductDetailPage
);

export { QueryProps as ProductDetailPage };
