import React from 'react'
import PropTypes from 'prop-types'
import { translate_section } from './constantes';
import Cookies from 'js-cookie';
function NewsStatusesWorkFlow(props) {
    return (
        <div dir={Cookies.get("lang")==="ar"?"rtl":"ltr"} style={{padding: "1%", textAlign: "start"}}>
            {translate_section(`new-${props.starter?"starter":"premium"}-statuses-explan`,Cookies.get("lang"))}
            
        </div>
    )
}

NewsStatusesWorkFlow.propTypes = {
  starter: PropTypes.bool

}

export default NewsStatusesWorkFlow

