import React from 'react';

const ExchangeSvg = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      {...props}
    >
      <g transform="translate(-5298 -3796)">
        <g transform="translate(5301.45 3800)">
          <path
            className="b"
            d="M42.123,9.464a.7.7,0,0,0,1.009,0,.729.729,0,0,0,0-1.009l-.937-.937h4.686v5.515a.7.7,0,0,0,1.406,0V6.8a.7.7,0,0,0-.7-.7H42.177l.937-.937a.729.729,0,0,0,0-1.009.712.712,0,0,0-1.009,0L39.978,6.31a.729.729,0,0,0,0,1.009Z"
            transform="translate(-33.057 -3.937)"
          />
          <path
            className="b"
            d="M7.655,9.681V6.491A1,1,0,0,0,6.663,5.5H3.491a1,1,0,0,0-.991.991v3.19a1,1,0,0,0,.991.991h3.19A.985.985,0,0,0,7.655,9.681Z"
            transform="translate(-2.5 -5.218)"
          />
          <path
            className="b"
            d="M19.164,45.972a.729.729,0,0,0-1.009,0,.712.712,0,0,0,0,1.009l.937.937H14.406V42.4a.7.7,0,0,0-.7-.7.716.716,0,0,0-.7.7v6.218a.7.7,0,0,0,.7.7H19.11l-.937.937a.729.729,0,0,0,0,1.009.7.7,0,0,0,1.009,0l2.145-2.145a.729.729,0,0,0,0-1.009Z"
            transform="translate(-11.108 -34.894)"
          />
          <path
            className="b"
            d="M73.063,65.8H69.891a1,1,0,0,0-.991.991v3.19a1,1,0,0,0,.991.991h3.172a1,1,0,0,0,.991-.991v-3.19A1,1,0,0,0,73.063,65.8Z"
            transform="translate(-56.932 -54.65)"
          />
        </g>
      </g>
    </svg>
  );
}
 
export default ExchangeSvg;