import React,{Component} from 'react'
import {Translate} from '../../../utils/lang/translate'
import Button from '../../_Common/Button'
import {Link} from 'react-router-dom'
class UnderDevPage extends Component{
    render(){
        return(
            <div className="FlHg FlWd StTpNl StlfNl">
                <div className="AlgnItm JstfCnt InFlx">
                    <div>
                        <img src="/assets/img/underDev.png" alt="under_dev_img" width="350"/>
                        <div className="TxtCn">
                            <h2>{Translate('titles','underdev')}</h2>
                            <p>{Translate('titles','texUnder')}</p>
                        </div>
                        <Link to="/dashboard">
                            <div className="MrAot StMarMx" style={{width:"60%"}}>
                                <Button 
                                    BtnText={Translate('titles','godahsb')}
                                />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
    
}
export { UnderDevPage };