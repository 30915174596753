import Cookies from "js-cookie";
import React, { Component } from "react";
import { NextSvg, PrevSvg } from "./IconSvg";
export default class PaginationBar extends Component {
  constructor(props) {
    super(props);
    this.goTopage = this.goTopage.bind(this);
    this.prevPage = this.prevPage.bind(this);
    this.nextPage = this.nextPage.bind(this);
  }
  goTopage(e) {
    const datapage = parseInt(e.target.dataset.page);
    this.props.ChangePage(datapage);
  }
  prevPage() {
    const { blockPrev, ChangePage, currentPage } = this.props;
    if (blockPrev) {
      let prevpage = currentPage - 1;
      ChangePage(prevpage);
    }
  }
  nextPage() {
    const { blockNext, ChangePage, currentPage } = this.props;
    if (blockNext) {
      let nextpage = currentPage + 1;
      ChangePage(nextpage);
    }
  }
  render() {
    const { blockNext, blockPrev, currentPage, NbPage } = this.props;

    var PageData = [];
    if (currentPage > 3 && NbPage > 5) {
      PageData.push(
        <div
          key={0}
          className="sTpniTP CrsPoi sTpniPNb MngBrvl StGlbtr "
          data-page="1"
          onClick={this.goTopage}
        >
          1
        </div>,
        <div className="sTpniTP CrsPoi sTpniPNb MngBrvl ">...</div>
      );
    }
    for (
      var i = Math.min(
        currentPage > 3 ? currentPage : 1,
        Math.abs(NbPage > 4 ? NbPage - 4 : 1)
      );
      i <= Math.min(NbPage, currentPage === 3 ? 5 : currentPage + 4);
      i++
    ) {
      PageData.push(
        <div
          key={i}
          className={
            "sTpniTP CrsPoi sTpniPNb MngBrvl StGlbtr " +
            (currentPage === i ? "BdgBlcl Fntcl StZoom" : "")
          }
          data-page={i}
          onClick={this.goTopage}
        >
          {i}
        </div>
      );
    }
    i--;
    if (
      (i === currentPage + 4 || (currentPage === 3 && i === 5)) &&
      i < NbPage &&
      i !== NbPage - 1
    ) {
      PageData.push(
        <div key={i + 10} className="sTpniTP CrsPoi sTpniPNb MngBrvl StGlbtr ">
          ...
        </div>,
        <div
          className="sTpniTP CrsPoi sTpniPNb MngBrvl"
          data-page={NbPage}
          onClick={this.goTopage}
        >
          {NbPage}
        </div>
      );
    } else if (i === NbPage - 1) {
      PageData.push(
        <div
          key={i + 11}
          className="sTpniTP CrsPoi sTpniPNb StGlbtr MngBrvl"
          data-page={NbPage}
          onClick={this.goTopage}
        >
          {NbPage}
        </div>
      );
    }
    return (
      <div
        className={
          "InFlx flxDrc BDgInpc StwdMaxC AlgnItm StBrdRdS sTclPg StGlbtr " +
          this.props.className
        }
      >
        {blockPrev && (
          <div
            className="StScl2 InFlx CrsPoi sTpniTP sTpniP"
            onClick={this.prevPage}
          >
            <div className="InFlx StRotVl">
              {Cookies.get("lang") === "ar" ? NextSvg : PrevSvg}
            </div>
          </div>
        )}
        {PageData}
        {blockNext && (
          <div
            className="StScl2 InFlx CrsPoi sTpniTP sTpniP"
            onClick={this.nextPage}
          >
            <div className="InFlx StRotVl">
              {Cookies.get("lang") === "ar" ? PrevSvg : NextSvg}
            </div>
          </div>
        )}
      </div>
    );
  }
}
