import Cookies from 'js-cookie';
import { userConstants } from '../constants';
const tempUserInfos=Cookies.get("temp-user-infos")
const initialState={
  user: tempUserInfos? JSON.parse(tempUserInfos):null
}
export function registration(state=initialState, action) {
  switch (action.type) {
    case userConstants.REGISTER_SUCCESS:
      return {
        registered:"1",
        Sended:"1",
        data: action.payload
      };
    case userConstants.REGISTER_FAILURE:
      return {
        registered:"2",
        Sended:"1",
        user:action.payload.user,
        data: action.payload.err
      };
    case userConstants.REGISTER_REQUEST:
      return{
        registered:"3",
        Sended:"1",
        data: action.payload
      }
    case userConstants.REGISTER_SENTUSER:
      return{
        Sended:"0",
        user:action.payload
      }
    default:
      return state
  }
}