import React from "react";
import "../_Common/VarCss/RootHeadLog.css";

import logoSideBar from "../../../utils/img/maystro-blue.svg";
export default function HeadLog(props) {
  return (
    <div className="">
      <div className="">
        <img
          src={logoSideBar}
          alt="Maystro Delivery logo"
          width="400px"
          height="150px"
          style={{ maxWidth: "65vw" }}
        />
      </div>
      <div>
        <div className="StMar">
          <h2 className="DlMg">{props.titleHead}</h2>
        </div>
        <div className="StMar">
          <p className="DlMg">{props.descHead}</p>
        </div>
      </div>
    </div>
  );
}
