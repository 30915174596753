import React, { useEffect } from "react";
import PropTypes from "prop-types";
import TabShow from "../_Common/TabShow";
import { Translate } from "../../../utils/lang/translate";
import { CloseSvg, SocialMediaIcons } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";
import { initGAPg, Event } from "../_Common";

function CongratulationModal(props) {
  useEffect(()=>{
    initGAPg();

  },[])
  const handleSocialLinkClick=(platform)=>{
    Event("SOCIAL_MEDIA_FOLLOW", "FOLLOW_ON_"+platform, "CLICK_FROM_CONGRATS_POPUP")

  }
  return (
    <TabShow NotCls style={{ minWidth: "60vw" }}>
      <div
        className="FlWd Inlflx AlgnItm StDrc spcBtwn"
        style={{ marginBottom: "3rem" }}
      >
        <div className="StdirLn">
          <h3 className="DlMg">{Translate("titles", "congrats")}</h3>
        </div>

        <div
          className="CrsPoi"
          onClick={props.onClose}
        >
          {CloseSvg}
        </div>
      </div>

      {props.winnerName && 
      <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">
        <img src="/assets/img/party.png" alt="" style={{height: "24px",margin : "0 10px" }} />
        <h3>{Translate("announcements", "congrats")}  {props.winnerName}</h3>
        <img src="/assets/img/party.png" alt="" style={{height: "24px",margin : "0 10px" , transform: "scaleX(-1)"}} />
      </div>}
      {props.congratsContent && 
      <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">
        {props.congratsContent}
      </div>}

      <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">
        <img src={props.banner || "/assets/img/congrats.jpg"} alt=""  style={{maxWidth:"80%"}} />
      </div>
      <div className="SmMarS FlWd Inlflx Stclmnf JstfCnt">
        <p className="StOpcVal">{Translate("announcements","thanksback")}</p>
        <div className="FlWd Inlflx JstfCnt">
          <div className="Inlflx Stclmnf AlgnItm JstfCnt" style={{margin: "0 2%"}}>
            <a target="blank" href="https://www.facebook.com/maystro.delivery/" onClick={()=>handleSocialLinkClick("FACEBOOK")}>
              {SocialMediaIcons.sm.Facebook}

            </a>

          </div>
          <div className="Inlflx Stclmnf AlgnItm JstfCnt" style={{margin: "0 2%"}}>
            <a target="blank" href="https://instagram.com/maystro_delivery" onClick={()=>handleSocialLinkClick("IG")}>
              {SocialMediaIcons.sm.Instagram}

            </a>

          </div>
          

        </div>
      </div>
      <div
        className="SmMarS FlWd Inlflx AlgnItm JstfCnt CrsPoi"
        onClick={props.onClose}
      >
        <BtnLg
          style={{
            minHeight: "40px",
            minWidth: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          text={Translate("announcements", "thanks")}
        />
      </div>
    </TabShow>
  );
}

CongratulationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  banner: PropTypes.string,
  winnerName: PropTypes.string,
  congratsContent: PropTypes.any,
};

export default CongratulationModal;
