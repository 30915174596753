import React from "react";

const ProductIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill={props.fill ?? "#008AFF"}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.3718 9.86537V23.1944C12.3718 23.1944 5.07082 20.07 2.01114 18.7643L2.00253 18.7496V5.42922L2.01114 5.44562L12.3626 9.87577L12.3718 9.86537ZM12.199 1L2 5.42975V5.44097L12.3718 9.89152L22.75 5.42977L12.479 1.00003H12.3718H12.199V1ZM22.7329 5.42819L22.7243 5.44449L12.3721 9.87534V23.1952L22.7243 18.7643L22.7329 18.748V5.42819ZM17.1465 16.5843C17.2452 16.8149 17.1382 17.0818 16.9077 17.1805L14.3198 18.2878C14.0892 18.3865 13.8223 18.2795 13.7237 18.049C13.625 17.8184 13.7319 17.5515 13.9625 17.4528L16.5504 16.3455C16.781 16.2468 17.0479 16.3538 17.1465 16.5843ZM19.7344 17.2482C19.833 17.4787 19.7261 17.7456 19.4955 17.8443L16.9077 18.9518L14.3198 20.0592C14.0893 20.1579 13.8223 20.0509 13.7237 19.8204C13.625 19.5898 13.7319 19.3229 13.9625 19.2242L16.5504 18.1168L19.1382 17.0093C19.3688 16.9107 19.6357 17.0176 19.7344 17.2482ZM15.5719 2.33849L15.5081 2.36194L5.20578 6.81655V13.0729L7.03481 12.1707L8.83916 14.8772L8.83734 8.3762L19.1418 3.875L15.5719 2.33849Z"
      />
    </svg>
  );
}
 
export default ProductIcon;