import React,{ Component } from 'react';
import { connect } from 'react-redux';
import GroupeForm from '../../_Common/GroupeForm'
import HeadLog from '../_Common/HeadLog'
import ContainerLog from '../_Common/ContainerLog'
import Button from '../../_Common/Button'
import {Translate} from '../../../utils/lang/translate'
import {userActions,alertActions} from '../../../actions'
import Loader from '../../_Common/Loader'
import {Link, Redirect} from 'react-router-dom'
import Cookies from 'js-cookie';
class ResetPasswordPage  extends Component{
    constructor(props) {
        super(props);
        this.state = {
            phone:'',
            code_ver:'',
            password:'',
            confirmed_password:'',
            otp_code_sent:false
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.resendCode = this.resendCode.bind(this);
        this.handleSubmitReset = this.handleSubmitReset.bind(this);
    }
    componentDidMount() {
        document.title = 'Maystro Delivery - '+Translate("auths","resetpassword");
        if(Cookies.get("_otp")){
            this.setState({otp_code_sent: true})
        }
    }

    componentWillReceiveProps(nextProps){
        const {restsms,sendRest,restsmsData}=nextProps;

        if(this.props.restsms==="0" && restsms==="1"){
            Cookies.set("_otp",restsmsData)
            this.setState({otp_code_sent: true})
        }
        if(this.props.sendRest==="0" && sendRest==="1"){
            Cookies.remove("_otp")
        }
        // if(this.props.sendRest==="0" && sendRes==="2"){
        //     this.props.SendAlert("50",Translate("alert","updtpsfail"))
        // }
    }
    handleChange(e){
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }
    handleSubmit(e){
        e.preventDefault();
        let phone = {phone:this.state.phone} 
        this.props.resetSms(phone)
    }
    resendCode(e){
        e.preventDefault();
        let phone = {phone:this.state.phone} 
        this.props.resetSms(phone)
    }
    handleSubmitReset(e){
        e.preventDefault();
        const otpData=(this.props.restsms==="1" && this.props.restsmsData)?this.props.restsmsData:JSON.parse(Cookies.get("_otp"))
        let user = {user_id:otpData.user_id,token:this.state.code_ver,password:this.state.password,confirmed_password:this.state.confirmed_password} 
        this.props.resetPassword(user)
        // if(this.props.restsms==="1" && otpData){
        //     let user = {user_id:this.props.restsmsData.user_id,token:this.state.code_ver,password:this.state.password,confirmed_password:this.state.confirmed_password} 
        //     this.props.resetPassword(user)
        // }
    }
    render(){
        const {restsms,restsmsData,sendRest}=this.props
        if(sendRest==="1"){
            this.props.SendAlert("41",Translate('alert','resstsucfly'))
            return <Redirect to="/login"/>
        }
        return(
            <ContainerLog>
                {(restsms==="0" || sendRest==="0") && <Loader styl={{borderRadius:"24px"}}/>}
                {this.state.otp_code_sent===false? 
                <HeadLog
                    titleHead={Translate("auths","resetpassword")}
                    descHead={Translate("auths","resettext")}
                />:
                <HeadLog
                    titleHead={Translate("auths","resetpassword")}
                    descHead={Translate("auths","verificationtext")}
                />}
                {sendRest==="2" && 
                <div className="MrAot InFlx flxDrc JstfCnt AlgnItm status_50 StWht" style={{width:"80%",borderRadius: "10px"}}>
                    {Translate("alert","updtpsfail")}

                </div>

                }
                {restsms==="2" && restsmsData.response.statu===400 &&
                <div className="MrAot InFlx flxDrc JstfCnt AlgnItm status_50 StWht" style={{width:"80%",borderRadius: "10px"}}>
                    {Translate("alert","phonedoesntexist")}

                </div>
                }
                {this.state.otp_code_sent===false? 
                <form action="post" onSubmit={this.handleSubmit} className="StMarMx">
                    <GroupeForm 
                        id={"phone"}
                        name={"phone"}
                        placeholder={Translate("auths","phone")}
                        text={Translate("auths","phone")}   
                        type={"tel"}
                        pattern={"[0]{1}[0-9]{9}"}
                        value={this.state.phone}
                        workfun={this.handleChange}
                    />
                    <div className="SmtGrp MrAot StMarMx">
                        <Button 
                            BtnText={Translate("auths","reset")}
                        />
                    </div>
                </form> : 
                <form action="post" onSubmit={this.handleSubmitReset} className="StMarMx">
                    <GroupeForm 
                        id={"code_ver"}
                        name={"code_ver"}
                        placeholder={Translate("auths","codever")}
                        text={Translate("auths","codever")}   
                        type={"password"}
                        value={this.state.code_ver}
                        workfun={this.handleChange}
                        more={<span>Didn'te receive code ? <b className="StBle CrsPoi" onClick={this.resendCode}>Resend</b></span>}
                    />
                    <GroupeForm 
                        id={"password"}
                        name={"password"}
                        placeholder={Translate("auths","newpassword")}
                        text={Translate("auths","newpassword")}   
                        type={"password"}
                        value={this.state.password}
                        workfun={this.handleChange}
                    />
                    <GroupeForm 
                        id={"confirmed_password"}
                        name={"confirmed_password"}
                        placeholder={Translate("auths","confpassword")}
                        text={Translate("auths","confpassword")}   
                        type={"password"}
                        value={this.state.confirmed_password}
                        workfun={this.handleChange}
                    />
                    <div className="SmtGrp MrAot StMarMx">
                        <Button 
                            BtnText={Translate("product","submitrequest")}
                        />
                    </div>
                </form> }
                <div className="StOpcVal">
                    <div className="StMrg">
                        <Link className="StBle stbold StWdDst" to="/login">
                            {Translate("auths","logins")}
                        </Link>
                    </div>
                </div>
            </ContainerLog>
        )
    }
}
function mapState(state) {
    const {restsms,restsmsData,sendRest,sendRestData}=state.authentication
    return {restsms,restsmsData,sendRest,sendRestData};
}

const actionCreators = {
    resetSms:userActions.resetSms,
    resetPassword:userActions.resetPassword,
    SendAlert:alertActions.SendAlert
};

const connectedResetPasswordPage = connect(mapState, actionCreators)(ResetPasswordPage);
export { connectedResetPasswordPage as ResetPasswordPage };