export const FunctionService = {
    request,
    succes,
    failure
};

function request(constVal) { 
    return {
         type:constVal,
         payload:'loading'
    } 
}
function succes(res,constVal) { 
    return {
         type:constVal,
         payload:res
    } 
}
function failure(err,constVal){
    return {
         type:constVal,
         payload:err
    } 
}