import React from 'react';

import CustomRadioButton from "../../_Common/CustomRadioButton/CustomRadioButton";
import { Translate } from '../../../../utils/lang/translate';

type DeliveryOptionProps = {
  isSelected: boolean,
  text: string,
  handler: () => void,
  price: number,
  currency?: string,
  disabled?: boolean,
  children?: React.ReactNode,
  discount?: number | null
}

export const DeliveryOption = ({ 
  isSelected, 
  price, 
  currency, 
  text, 
  disabled = false, 
  handler,
  children = null,
  discount = null
}: DeliveryOptionProps) => {
  return (
    <div 
      onClick={disabled ? () => {} : handler}
      className={`RlPs StBrdRd InFlx Stclmnf${isSelected ? " Brdblue" : " border-inactive StOpcVal"}${disabled ? " notAllowed" : " CrsPoi"}`} 
      style={{ 
        padding: "12px", 
        background: isSelected ? "rgba(0, 138, 255, 0.08)" : "",
        gap: "8px" 
      }}
    >
      {
        discount !== null
        &&
        <div 
          className="StAbs sb4R" 
          style={{ 
            right: "5px", 
            top: "-20px", 
            padding: "4px 10px",
            fontSize: "14px",
            background: "linear-gradient(180deg, #008AFF 0%, #3263D2 100%)",
            fontWeight: 700 
          }}>
          {`${Translate("orders", "saveupto")} ${-discount} ${currency}`}
        </div>
      }
      <div className="InFlx AlgnItm spcBtwn">
        <div className="InFlx AlgnItm" style={{ gap: "8px" }}>
          <CustomRadioButton isSelected={isSelected} />
          <span>{text}</span>
        </div>
        {
          price !== null
          &&
          <div className="InFlx" style={{ gap: "4px" }}>
            <span>{price}</span>
            <span>{currency}</span>
          </div>
        }
      </div>
      { children }
    </div>
  );
};

interface DeliveryOptionSkeletonProps {
  isHomeDelivery?: boolean
}

export const DeliveryOptionSkeleton = ({ isHomeDelivery = false }: DeliveryOptionSkeletonProps) => (
  <div
  className={`RlPs StBrdRd InFlx Stclmnf${isHomeDelivery ? " Brdblue" : " border-inactive"}`}
    style={{ 
      padding: "12px", 
      gap: "8px",
      background: isHomeDelivery ? "rgba(0, 138, 255, 0.08)" : "", 
    }}
  >
    <div className="InFlx AlgnItm spcBtwn">
      <div className="InFlx AlgnItm" style={{ gap: "8px" }}>
        <CustomRadioButton isSelected={isHomeDelivery} />
        {
          isHomeDelivery
          ?
            <span>{Translate("orders", "home")}</span>
          :
            <span
              className="StBrdRd HdOvrfl animate-pulse" 
              style={{ 
                width: "150px", 
                height: "24px", 
                backgroundColor: "var(--bdgclInp)" 
              }} 
            />
        }
      </div>
      <div className="InFlx" style={{ gap: "4px" }}>
        <span
          className="StBrdRd HdOvrfl animate-pulse" 
          style={{ 
            width: "50px", 
            height: "24px", 
            backgroundColor: "var(--bdgclInp)" 
          }} 
        />
      </div>
    </div>
  </div>
)