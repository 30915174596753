import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ContainerPage from "../_Common/ContainerPage";
import Table from "../_Common/Table";
import GroupeBtn from "../_Common/GroupeBtn";
import { AddProduct } from "./AddProduct";
import { ProductAvail } from "./ProductAvail";
import { Switch, Route } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
import {
  ViewSvg,
  AddProductSvg,
  RefreshSvg,
  OutSvg,
  CheckSvg,
  ImportSvg,
} from "../_Common/IconSvg";
import { TakenProdSvg } from "../SideBar/SideBarSvg";
import SmallText from "../_Common/SmallText";
import PaginationBar from "../_Common/PaginationBar";
import { productActions, commonActions, alertActions } from "../../../actions";
import TableLoader from "../_Common/TableLoader";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import { initGAPg, Event } from "../_Common";
import { PRODUCTS_TABLE_HEADER } from "../../constants";
import InlinedElement from "../_Common/InlinedElement";
import IconButton from "../_Common/IconButton";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import StockReleaseFormModal from "./StockReleases/StockReleaseFormModal";

const urlPropsQueryConfig = {
  page: { type: UrlQueryParamTypes.number },
  logistical_description: { type: UrlQueryParamTypes.string }
};

class ProductsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      premmison:
        this.props.dataProfile.is_store || this.props.dataProfile.view_stock,
      logistical_description: "",
      allSelect: false,
      selected_ids: [],
      show_stock_release_modal: false,
    };
    this.crntfilter = "";
    this.action = {
      next: null,
      prev: null,
      count: 0,
    };
    this.refreshPage = this.refreshPage.bind(this);
    this.Gotodetail = this.Gotodetail.bind(this);
    this.CallPropsS = this.CallPropsS.bind(this);
    this.SetLogisticalDescription = this.SetLogisticalDescription.bind(this);
    this.ChangeLogistical_description = this.ChangeLogistical_description.bind(this);
    this.handleSelectProduct = this.handleSelectProduct.bind(this);
  }
  static defaultProps = {
    page: 1,
  };
  static propTypes = {
    page: PropTypes.number,
    /********************** */
    onChangePage: PropTypes.func,
    onChangeLogistical_description: PropTypes.func
  };
  componentWillReceiveProps(nextProps) {
    const { GetPrdPage, PrdPageData, ProductAdded } = nextProps;
    if (
      this.props.GetPrdPage === "0" &&
      GetPrdPage === "1" &&
      PrdPageData &&
      PrdPageData.list
    ) {
      this.action = {
        next: PrdPageData.list.next,
        prev: PrdPageData.list.previous,
        count: PrdPageData.list.count,
      };
    }
    if (this.props.ProductAdded === "1" && ProductAdded === "2") {
      this.exctuteFilterUrl(this.getFilterToState(), true);
    }
    if (nextProps.page !== this.props.page || this.CallProps) {
      this.CallProps = false;
      this.exctuteFilterUrl(this.getFilterToState(nextProps));
    }

  }

  componentDidUpdate(prevProps) {
    if(
      prevProps.AddingStockRelease !== this.props.AddingStockRelease 
      && this.props.AddingStockRelease === "2"
    ){
      this.props.SendAlert("41", "Successfuly created!", "");
      this.setState({ ...this.state, show_stock_release_modal: false });
      this.props.history.push(`/stock/stock-release?search=${this.props.dataAddStockRelease.display_id}`);
    }

    if(
      prevProps.AddingStockRelease !== this.props.AddingStockRelease 
      && this.props.AddingStockRelease === "3"
    ){
      this.props.SendAlert("50", "Failed to add request!", "");
    }
  }

  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "product");
    initGAPg();
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }
  componentWillUnmount() {
    this.props.ClearStore("REMOVE_PRODUCT_PAGE");
    this.props.ClearStockReleaseItem();
  }

  CallPropsS() {
    this.CallProps = true;
  }

  SetLogisticalDescription(e) {
    e.preventDefault();
    Event("PRODUCTS", "FILTER_BY_LOGISTICAL_DESCRIPTION", "CLICK_EVENT");
    this.CallProps = true;
    if (this.props.logistical_description) {
      this.props.onChangeLogistical_description(undefined);
      this.setState({
        logistical_description: ""
      });
    } else {
      this.props.onChangeLogistical_description(this.state.logistical_description);
    }
  }

  ChangeLogistical_description(e) {
    const { value } = e.target;
    this.setState({
      logistical_description: value
    });
  }

  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force) {
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || force) {
      this.crntfilter = nextFilter;
      this.props.GetProductPage(nextFilter);
    }
  }
  Gotodetail(id) {
    const { dtStoreinfo } = this.props;
    if (dtStoreinfo.stock_managed) {
      Event("PRODUCT", "GO_TO_PRODUCT_DETAILS_PAGE", "CLICK_EVENT");
      this.props.history.push("/products/detail/" + id);
    }
  }
  actionclick(path) {
    Event("PRODUCT", "ADD_PRODUCT_FROM_CLICK", "CLICK_EVENT");
    this.props.history.push(path);
  }

  refreshPage() {
    Event("PRODUCT", "REFRESH_PRODUCT", "CLICK_EVENT");
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }
  showOpt(name) {
    Event("PRODUCT", "CANCEL_DELETING_PRODUCT", "CLICK_EVENT");
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  };

  handleSelectProduct = (all_select, selected_id) => {
    if(all_select){
      this.setState({ ...this.state, allSelect: !this.state.allSelect });
    }else {
      if(this.state.selected_ids.indexOf(selected_id) !== -1) {
        this.setState({ 
          ...this.state, 
          selected_ids: this.state.selected_ids.filter((item, _) => item !== selected_id) 
        });
      }else{
        this.setState({ 
          ...this.state, 
          selected_ids: [...this.state.selected_ids, selected_id] 
        });
      }
    }
  };

  render() {
    const { GetPrdPage, PrdPageData, page, dtStoreinfo } =
      this.props;
    let bodytab = [];
    if (GetPrdPage === "1") {
      bodytab = PrdPageData.list.results.map((elem) => {
        let actionBtns = [];
        const {
          id,
          logistical_description,
          display_id,
          delivery_rate,
          cancelation_rate,
        } = elem;
        if (dtStoreinfo.stock_managed)
          actionBtns.push({
            type: "BDgInpc",
            action: () => this.Gotodetail(display_id),
            svgbtn: ViewSvg,
            tooltip: Translate("product", "productDetails"),
          });

        const common_table_body = [
          display_id,
          <SmallText lengthText={70}>{logistical_description}</SmallText>,
          delivery_rate + "%",
          cancelation_rate + "%",
        ];

        return dtStoreinfo?.stock_managed ? 
        [
          <CheckBoxForm
            name={"select_" + display_id}
            rmMarg={true}
            workfun={() => this.handleSelectProduct(false, id)}
            check={this.state.allSelect || this.state.selected_ids.indexOf(id) !== -1}
            id={"product_select_" + display_id}
          />,
          ...common_table_body,
          this.state.premmison ? <GroupeBtn data_btn={actionBtns} /> : "",
        ] : common_table_body
        ;
      });
    }
    return (
      <ContainerPage
        page_title={
          <div>
            <Link className="StOpcVal" to="/stock/receiveProduct">
              {Translate("titles", "receiveProduct")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <span> {Translate("titles", "product")}</span>
          </div>
        }
      >
        <div className="RlPs InFlx" 
          style={{ 
            zIndex: "2", 
            padding: "10px 0", 
            height: "48px",
            gap: "12px"
          }}
        >
          <form onSubmit={this.SetLogisticalDescription}>
            <div className="FlHg StBrdRdS ClSidTh FlHg">
              <InlinedElement
                style={{ padding: "0" }}
                secnd={
                  <div>
                    <input
                      type="text"
                      autoComplete="off"
                      onChange={this.ChangeLogistical_description}
                      name="filter_by_logistical_description"
                      style={{ width: "300px", maxWidth: "30vw" }}
                      className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl"
                      value={this.state.logistical_description}
                      placeholder={Translate("product", "filterbyname")}
                    />
                  </div>
                }
                leftElm={
                  <button
                    className="BdgGc DlBrd CrsPoi Inlflx StBgbrd"
                    style={{ padding: "10px" }}
                  >
                    {this.props.logistical_description ? OutSvg : CheckSvg}
                  </button>
                }
              />
              </div>
          </form>
          <div className="FlWd InFlx FlHg" style={{ gap: "12px" }}>
            <IconButton 
              clickHandler={this.refreshPage} 
              icon={RefreshSvg}
            />
            <IconButton 
              clickHandler={() => this.actionclick("/products/import")} 
              label={Translate("product", "import")} 
              icon={ImportSvg}
            />
            {
              (this.state.allSelect || this.state.selected_ids.length > 0)
              &&
              <IconButton
                clickHandler={() => this.setState({ ...this.state, show_stock_release_modal: true })}
                icon={TakenProdSvg}
                tooltip={Translate("product", "addstockrelease")}
              />
            }
            <IconButton 
              clickHandler={
                () =>
                  this.actionclick(
                    "/stock/products/add?page=" + this.props.page
                  )
              }
              className="BdgBlcl sTmftAu"
              icon={AddProductSvg}
              label={Translate("product", "addnewproduct")} 
            />
          </div>
        </div>

        <div>
          {GetPrdPage === "0" ? (
            <TableLoader />
          ) : (
            <Fragment>
              <Table
                thead_elem={
                  dtStoreinfo?.stock_managed
                  ?
                    [
                      <CheckBoxForm
                        name={"select_all"}
                        rmMarg={true}
                        workfun={() => this.handleSelectProduct(true, null)}
                        check={this.state.allSelect}
                        id={"product_select_all"}
                      />,
                      ...PRODUCTS_TABLE_HEADER,
                      this.state.premmison
                        ? Translate("product", "action").toUpperCase()
                        : "",
                    ]
                  :
                    PRODUCTS_TABLE_HEADER
                }
                tbody_elem={bodytab}
                goTo={this.Gotodetail}
              />
              <div role="pagination" className="lytWdp SmMarS1">
                <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                  <span>
                    {Translate("orders", "showing") +
                      " " +
                      (page * 20 - 19) +
                      " - " +
                      (page * 20 < this.action.count
                        ? page * 20
                        : this.action.count) +
                      " " +
                      Translate("orders", "of") +
                      " " +
                      this.action.count}
                  </span>
                  <PaginationBar
                    className="StAutMr"
                    NbPage={Math.trunc(this.action.count / 20) + 1}
                    currentPage={parseInt(page)}
                    ChangePage={this.props.onChangePage}
                    blockNext={this.action.next}
                    blockPrev={this.action.prev}
                  />
                </div>
              </div>
            </Fragment>
          )}
        </div>
        {
          this.state.show_stock_release_modal
          &&
          <StockReleaseFormModal
            onClose={() => this.setState({ ...this.state, show_stock_release_modal: false })}
            loading={GetPrdPage === "0" || this.props.AddingStockRelease === "1"}
            onFormSubmit={(new_request_data) => this.props.AddStockReleaseRequest({ products: new_request_data })}
            productsList={
              GetPrdPage === "1" ?
              PrdPageData?.list.results
              : []
            }
            releaseProducts={
              this.state.allSelect ?
              PrdPageData?.list.results.map((item, _) => ({ product: item.id, quantity: 0 }))
              : this.state.selected_ids.map((_id, _) => ({ product: _id, quantity: 0 }))
            }
          />
        }
        <Switch>
          <Route
            path="/stock/products/update/:idprod"
            component={AddProduct}
            exact
          />

          <Route path="/stock/products/add" component={AddProduct} exact />
          {this.state.premmison && (
            <Route
              path="/products/availibal/:idproduct"
              component={ProductAvail}
              exact
            />
          )}
        </Switch>
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { GetPrdPage, PrdPageData } = state.UnlinkProduct;
  
  const { 
    ProductAdded, 
    AddingStockRelease, 
    dataAddStockRelease 
  } = state.product;

  const { dataProfile, dtStoreinfo } = state.user;

  return {
    GetPrdPage,
    PrdPageData,
    dataProfile,
    dtStoreinfo,
    ProductAdded,
    AddingStockRelease,
    dataAddStockRelease
  };
}

const actionCreators = {
  GetProductPage: productActions.GetProductPage,
  ClearStore: commonActions.ClearStore,
  SendAlert: alertActions.SendAlert,
  AddStockReleaseRequest: productActions.AddStockReleaseRequest,
  ClearStockReleaseItem: productActions.ClearStockReleaseItem
};

const connectedProductsPage = connect(mapState, actionCreators)(ProductsPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedProductsPage);
export { QueryProps as ProductsPage };
