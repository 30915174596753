import React from 'react';

const PersonIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 40 40"
      fill={props.fill ?? "#008AFF"}
      { ...props }
    >
      <g transform="translate(-400 -539)">
        <path
          d="M220-10a6.5,6.5,0,0,0,6.5-6.5A6.5,6.5,0,0,0,220-23a6.5,6.5,0,0,0-6.5,6.5A6.5,6.5,0,0,0,220-10Zm0,3.25c-4.339,0-13,2.178-13,6.5V3h26V-.25c0-4.322-8.661-6.5-13-6.5Z"
          transform="translate(200 569)"
        />
      </g>
    </svg>
  );
}
 
export default PersonIcon;