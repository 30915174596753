import { searchConstants } from '../constants';
import { RequestService,FunctionService } from '../services';
import Cookies from 'js-cookie'
const token=Cookies.get('auth')
const store_id=Cookies.get('c_store')
export const searchActions = {
    SearchOrders
};
function SearchOrders(qeury){
    return dispatch => {
        dispatch(FunctionService.request(searchConstants.SEARCH_ORDER_REQUEST))
        RequestService.GetRequest("stores/search_order/"+qeury,{"Authorization":"Token "+token})
        .then(res=>{
            dispatch(FunctionService.succes({ressuc:res.data,filter:qeury},searchConstants.SEARCH_ORDER_SUCCESS))
        })
        .catch(err=>{
            dispatch(FunctionService.failure(err,searchConstants.SEARCH_ORDER_FAILURE))
        })
    }
}