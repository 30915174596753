import React, { useState,useEffect } from "react";
import { connect } from 'react-redux'
import PropTypes from "prop-types";
import {userActions} from '../../../actions'
import { Translate } from "../../../utils/lang/translate";
import TabShow from "../_Common/TabShow";
import BtnLg from "../_Common/BtnLg";
import GroupeForm from "../../_Common/GroupeForm";
import Button from "../../_Common/Button";
import Spinner from "../_Common/Spinner";
import Cookies from "js-cookie";
import { Event } from "../_Common";

function SecurityUpdateModal(props) {
  const [email, setEmail] = useState(props.email || "");
  const [phone, setPhone] = useState(props.phone || "");
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "email":
        setEmail(value);
        break;
      case "phone":
        setPhone(value);
        break;
      default:
        break;
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    Event("SECURITY_UPDATE", "USE_SUBMIT_BUTTON", "CLICK_EVENT")
    let data={email,phone};
    props.UpdateStore(data)
  };

  const downloadCredentials=(e)=>{
          var text=`
          Nom d'utilisateur/Username :\t ${props.user?props.user.username:"/"}\n
          Nom complet :\t ${props.user?props.user.full_name:"/"}\n
          Email :\t ${email}\n
          Téléphone :\t ${phone}\n
          `
          
          var a = document.createElement("a");
          var file = new Blob([text], {type: text});
          a.href = URL.createObjectURL(file);
          a.download = "maystro_account.txt";
          a.click();

          setDownloadSuccess(true)
          Event("SECURITY_UPDATE", "USE_DOWNLOAD_CREDENT", "CLICK_EVENT")


  }

  useEffect(()=>{
    if(props.UpdatingStore==="1"){
      setUpdateSuccess(true);
      localStorage.setItem("sec_chk","true")
      Event("SECURITY_UPDATE", "UPDATE_INFOS_SUBMIT", "SUCCESS")
    }

  },[props.UpdatingStore])
  return (
    <TabShow NotCls notCnter style={{ minWidth: "80vw" }}>
      <div
        className="FlWd Inlflx AlgnItm StDrc spcBtwn"
        style={{ marginBottom: "3rem" }}
        
      >
        <div className="StdirLn">
          <h3 className="DlMg">{Translate("titles", "securityimprove")}</h3>
        </div>

      </div>
      <div className="FlWd " dir={Cookies.get("lang")==="ar"?"rtl":"ltr"}>
        <div> 
          <p>{Translate("announcements","securitydesc")}</p>
        </div>
        <div> 
          <div className="FlWd Inlflx AlgnItm">
            <div style={{margin: "0 2%"}}> 
              <div className={"Inlflx AlgnItm JstfCnt "+(updateSuccess?"status_41":"status_31")} style={{borderRadius: "50%",padding: "2%",height:"5vh", width: "5vh"}}>1</div> 
            </div>
            <div style={{flex:8}}> 
              <div><h4>{Translate("actions","updateaccountinfos")}</h4></div> 
            </div>
          </div>

          <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">
            <form method="post" onSubmit={handleSubmit}>
              <div className="InFlx flxDrc responsFlx FlWd spctwB">
                <div className="Hlwd">
                  <GroupeForm
                    id={"email"}
                    name={"email"}
                    placeholder={Translate("auths", "email")}
                    text={Translate("auths", "email")}
                    type={"email"}
                    value={email}
                    workfun={handleChange}
                  />
                </div>
                <div className="Hlwd">
                  <GroupeForm
                    id={"phone"}
                    name={"phone"}
                    placeholder={Translate("auths", "phone")}
                    text={Translate("auths", "phone")}
                    type={"tem"}
                    value={phone}
                    workfun={handleChange}

                  />
                </div>
              </div>
              
              {props.UpdatingStore!=="0" ?
              <div className="MrAot StMarMx StMrtp" style={{ minWidth: "200px" }}>
                <Button BtnText={Translate("orders", "save")} />
              </div>:
              <div className="FlWd MrAot StMarMx StMrtp Inlflx AlgnItm JstfCnt" style={{ minWidth: "200px" }}>
                <Spinner variant="info"/>
              </div>
              }
            </form>
          </div>

        </div>
        <div> 
          <div className="FlWd Inlflx AlgnItm">
            <div style={{margin: "0 2%"}}> 
              <div className={"Inlflx AlgnItm JstfCnt "+(updateSuccess?downloadSuccess?"status_41":"status_31":"status_51")} style={{borderRadius: "50%",padding: "2%",height:"5vh", width: "5vh"}}>2</div> 
            </div>
            <div style={{flex:8}}> 
              <div><h4>{Translate("actions","downloadcredent")}</h4></div> 
            </div>
          </div>

          {updateSuccess && 
          <div className="SmMarS FlWd">
            <p style={{margin: "0 7%"}}>{Translate("actions","downloadcredentdesc")}</p>
            

            <div className="FlWd Inlflx AlgnItm JstfCnt" style={{margin: "3% 0"}}>
              <Button 
              BtnText={Translate("actions","downloadfile")} 
              onClick={downloadCredentials} 
              style={{padding: "3% 5%", minWidth: "200px"}}
              />

            </div>
            
          </div>}

        </div>

      </div>
      {updateSuccess && downloadSuccess &&
      <div
        className="SmMarS FlWd Inlflx AlgnItm JstfCnt CrsPoi"
        onClick={props.onClose}
      >
        <BtnLg
          style={{
            minHeight: "40px",
            minWidth: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          text={Translate("titles", "done")}
        />
      </div>}
     
    </TabShow>
  );
}

SecurityUpdateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string,
  phone: PropTypes.string,
  user: PropTypes.object
};


const mapStateToProps = (state) => ({
  UpdatingStore: state.user.UpdatingStore,
  dataUpdate: state.user.dataUpdate

  
})

const mapDispatchToProps = {
  UpdateStore: userActions.UpdateStore,
  
}

export default connect(mapStateToProps, mapDispatchToProps)(SecurityUpdateModal)