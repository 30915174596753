import { notifsConstants, messageConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
export const notificationsActions = {
  GetNotifications,
  MarkNotifAsRead,
  MarkAllAsSeen,
  GetAnnouncement,
};

function GetNotifications(filter) {
  return (dispatch) => {
    dispatch(FunctionService.request(notifsConstants.GET_NOTIF_REQUEST));
    RequestService.GetRequest("shared/notification/" + (filter || ""), {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: filter },
            notifsConstants.GET_NOTIF_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            notifsConstants.GET_NOTIF_FAILURE
          )
        );
      });
  };
}
function MarkNotifAsRead(idnotif) {
  return (dispatch) => {
    dispatch(FunctionService.request(notifsConstants.UPDATE_NOTIF_REQUEST));
    RequestService.PatchRequest(
      "shared/notification/" + idnotif + "/",
      { read: true },
      { Authorization: "Token " + token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res, notifsConstants.UPDATE_NOTIF_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, notifsConstants.UPDATE_NOTIF_FAILURE)
        );
      });
  };
}
function MarkAllAsSeen(idnotif) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(notifsConstants.UPDATE_STATUS_NOTIF_REQUEST)
    );
    RequestService.PostRequest(
      "shared/seen_notification/",
      {},
      { Authorization: "Token " + token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res,
            notifsConstants.UPDATE_STATUS_NOTIF_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            notifsConstants.UPDATE_STATUS_NOTIF_FAILURE
          )
        );
      });
  };
}

function GetAnnouncement() {
  return (dispatch) => {
    dispatch(FunctionService.request(messageConstants.NEW_ACTION_REQUEST));
    RequestService.GetRequest("base/announcements/beta/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, messageConstants.NEW_ACTION_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            messageConstants.NEW_ACTION_FAILURE
          )
        );
      });
  };
}
