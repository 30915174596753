import React, { Component } from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Translate } from "../../../../utils/lang/translate";

const options = {
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 2,
      bottom: 0,
    },
  },
  scales: {
    yAxes: [
      {
        id: "balance",
        type: "linear",
        position: "left",
        gridLines: {
          display: true,
          color: "#1b243150",
        },
      },
      {
        id: "orders",
        type: "linear",
        position: "right",
        gridLines: {
          display: true,
          color: "#1b243150",
        },
      },
    ],
    xAxes: [
      {
        // type: 'time',
        // time: {
        //     unit: 'month'
        // },
        gridLines: {
          display: false,
        },
      },
    ],
  },
};
export default class ChartsTab extends Component {
  constructor(props) {
    super(props);
  }
  setGradintColor(ctx, nb) {
    if (nb === 1) {
      const gradient = ctx.createLinearGradient(0, 0, 0, 500);
      gradient.addColorStop(0, "#3498DB80");
      gradient.addColorStop(0.8, "#3498DB00");
      return gradient;
    } else {
      const gradient = ctx.createLinearGradient(0, 0, 0, 500);
      gradient.addColorStop(0, "#1ABC9C80");
      gradient.addColorStop(1, "#1ABC9C00");
      return gradient;
    }
  }

  render() {
    let data = {
      datasets: [
        {
          label: Translate("payement", "payablebalance"),
          yAxisID: "balance",
          borderColor: "#008AFF",
          borderWidth: 2,
          lineTension: 0,
          devicePixelRatio: 2,
          display: false,
          hoverBackgroundColor: "#008AFF",
          hoverBorderColor: "#008AFF",
          data: this.props.dataset1,
        },
        {
          label: Translate("titles", "order"),
          yAxisID: "orders",
          borderColor: "#1ABC9C",
          borderWidth: 2,
          lineTension: 0,
          devicePixelRatio: 2,
          display: false,
          hoverBackgroundColor: "#1ABC9C",
          hoverBorderColor: "#1ABC9C",
          data: this.props.dataset2 || [],
        },
      ],
    };
    return (
      <div className="StElmD">
        <div className="CntChart">
          <Line options={options} data={data} />
        </div>
      </div>
    );
  }
}
