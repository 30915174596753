import React, { useState } from "react";
import PropTypes from "prop-types";

function ToggleSwitch(props) {
  const [on, setOn] = useState(props.isOn || false);

  const toggle = () => {
    setOn(!on);
    props.toggleHandler && props.toggleHandler();
  };

  return (
    <div className="TbDp RlPs FlHg StPaddingVal StScl">
      <div className="TbCdp RlPs TxtCn VrAlg">
        <div
          className={
            (on ? "status_22" : "FrInp") + " StBgbrd InFlx AlgnItm RlPs"
          }
          style={{ height: "35px", padding: "0 3px" }}
        >
          <div
            className="IcnSiz CrsPoi Inlflx StPaddingVal"
            onClick={() => toggle()}
          >
            {}
          </div>
          <div
            className="IcnSiz CrsPoi Inlflx StPaddingVal"
            onClick={() => toggle()}
          >
            {}
          </div>
          <span
            style={{
              width: "30px",
              height: "30px",
              top: "2.5px",
              left: !on ? "5px" : "38px",
            }}
            className={
              "stTranEs StAbs StBgbrd StTpNl DsBlc " +
              (on ? "BdgWhite" : "status_22")
            }
          ></span>
        </div>
      </div>
    </div>
  );
}

ToggleSwitch.propTypes = {
  isOn: PropTypes.bool,
  toggleHandler: PropTypes.func.isRequired,
};

export default ToggleSwitch;
