import React from "react";
import ReactTooltip from "react-tooltip";

interface IconButtonProps {
  clickHandler: () => void,
  icon: JSX.Element,
  label?: string,
  className?: string,
  tooltip?: string | null
}

const IconButton = ({
  clickHandler,
  icon,
  label,
  className,
  tooltip = null
}: IconButtonProps) => {
  return (
    <button
      onClick={clickHandler}
      className={`InFlx AlgnItm sb4R ClSidTh borderClr CrsPoi ${className ?? ""}`}
      style={{
        padding: "4px 10px",
        gap: "10px"
      }}
      data-tip
      data-for={`icon-button-tooltip-${label}`}
    >
      {
        tooltip !== null && tooltip !== ""
        &&
        <ReactTooltip
          id={`icon-button-tooltip-${label}`}
        >
          {tooltip}
        </ReactTooltip>
      }
      <span style={{ marginTop: "5px" }}> {icon} </span>
      {
        (label && label !== "")
        &&
        <span 
          className="StpdVlrglt Fntcl" 
          style={{ fontSize: "16px", fontFamily: "Nunito Sans" }}
        > 
          {label} 
        </span>
      }
    </button>
  );
}
 
export default IconButton;