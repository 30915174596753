import React from "react";
import PropTypes from "prop-types";
import TabShow from "../_Common/TabShow";
import { Translate } from "../../../utils/lang/translate";
import { CloseSvg } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";

function NewFeaturesModal(props) {
  return (
    <TabShow NotCls style={{ minWidth: "80vw" }}>
      <div
        className="FlWd Inlflx AlgnItm StDrc spcBtwn"
        style={{ marginBottom: "3rem" }}
      >
        <div className="StdirLn">
          <h3 className="DlMg">{Translate("titles", "newfeaturetitle")}</h3>
        </div>

        <div className="CrsPoi" onClick={props.onClose}>
          {CloseSvg}
        </div>
      </div>
      {Array.isArray(props.features) &&
        props.features.map((feature, index) => {
          const { title, desc, media, link } = feature;
          let body;
          switch (media) {
            case "img":
              body = <img src={link} alt="" style={{maxWidth: "90%"}}/>;
              break;
            case "video":
              body = (
                <iframe
                  style={{ width: "100%", minHeight: "50vh", border: "none" }}
                  src={link}
                  title={`${Translate("titles", "newfeaturetitle")} ${index}`}
                />
              );
              break;

            default:
              body = <div></div>;
              break;
          }
          return (
            <div key={index} className="SmMarS FlWd">
              <h5>{title}</h5>
              <p>{desc}</p>
              <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">{body}</div>
              
            </div>
          );
        })}
      <div className="SmMarS FlWd Inlflx AlgnItm flxDrc">
        {props.children}
      </div>

      <div
        className="SmMarS FlWd Inlflx AlgnItm JstfCnt CrsPoi"
        onClick={props.onClose}
      >
        <BtnLg
          style={{
            minHeight: "40px",
            minWidth: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          text={Translate("titles", "done")}
        />
      </div>
    </TabShow>
  );
}

NewFeaturesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  features: PropTypes.array.isRequired,
};

export default NewFeaturesModal;
