import React, { ChangeEvent, useEffect, useState } from 'react';
import { Translate } from '../../../../utils/lang/translate';
import { CloseSvg2 } from '../../_Common/IconSvg';
import GroupeForm from '../../../_Common/GroupeForm';
import { ProductInterface, ProductType, RequestStatus } from '../../../../types';
import { alertActions, productActions } from '../../../../actions';
import { Select } from '../../_Common';
import { connect } from 'react-redux';
import Button from '../../../_Common/Button';

interface ProductFieldError { 
  quantity: string | null, 
  product_name: string | null,
};

type OrderProduct = { product_id: string, quantity: number, logistical_description: string, is_exchange: boolean };

interface ProductsFormProps {
  active: boolean,
  GettingAllProducts: RequestStatus,
  productsData: {
    list: ProductType[],
  },
  dtStoreinfo: any,
  GetAllProductsV2: () => void,
  SendAlert: (code: string, text: string, action: string) => void,
  onFormSubmit: (data: { products: OrderProduct[], external_order_id: string | null }) => void,
  orderProductsData: { 
    orderProducts: OrderProduct[], 
    external_order_id: string | null 
  },
}

const ProductsForm = ({
  GettingAllProducts,
  productsData,
  dtStoreinfo,
  GetAllProductsV2,
  SendAlert,
  onFormSubmit,
  orderProductsData,
  active
}: ProductsFormProps) => {

  const INITIAL_PRODUCT: OrderProduct = {
    product_id: "",
    logistical_description: "",
    quantity: 0,
    is_exchange: false
  };

  const INITIAL_PRODUCT_ERROR_STATE: ProductFieldError = {
    product_name: null, 
    quantity: null
  };
 
  const [productsList, setProductsList] = useState<OrderProduct[]>([INITIAL_PRODUCT]);
  const [productsListErrors, setProductsListErrors] = useState<ProductFieldError[]>([INITIAL_PRODUCT_ERROR_STATE]);
  const [returnProducts, setReturnProducts] = useState<OrderProduct[]>([]);
  const [returnProductsErrors, setReturnProductsErrors] = useState<ProductFieldError[]>([]);
  const [externalId, setExternalId] = useState<string>("");

  const disableSubmit = 
    !productsListErrors.every((err, _) => !err.product_name && !err.quantity) || 
    !returnProductsErrors.every((err, _) => !err.product_name && !err.quantity) ||
    GettingAllProducts !== '2'
  ;

  const handleProductChange = (product: ProductInterface, index: number, text: string) => {
    let new_data = [...productsList];
    if(product) {
      new_data[index].product_id = product.id;
      new_data[index].logistical_description = product.logistical_description;
      if(productsListErrors[index].product_name) {
        let new_errors = productsListErrors;
        new_errors[index].product_name = null;
        setProductsListErrors(new_errors);
      }
    }else{
      new_data[index].product_id = "";
      new_data[index].logistical_description = text;
      if(!dtStoreinfo.stock_managed) {
        if(productsListErrors[index].product_name) {
          let new_errors = productsListErrors;
          new_errors[index].product_name = null;
          setProductsListErrors(new_errors);
        }
      }
    }
    setProductsList(new_data);
  };

  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    let new_data = [...productsList];
    let new_value = parseInt(e.target.value); 
    new_data[index].quantity = new_value;
    if(new_value !== 0 && productsListErrors[index].quantity) {
      let new_errors = productsListErrors;
      if(productsListErrors[index].quantity === Translate("error", "maxtotalquantity")){
        new_errors = new_errors.map((err, _) => ({ ...err, quantity: null }));  
      }else{
        new_errors[index].quantity = null;
      }
      setProductsListErrors(new_errors);
    } 
    setProductsList(new_data);
  };

  const AddFieldProduct = () => {
    setProductsList([ ...productsList, INITIAL_PRODUCT ]);
    setProductsListErrors([ ...productsListErrors, { product_name: null, quantity: null } ]);
  };

  const handleRemoveProduct = (product_index: number) => {
    let new_data = productsList.filter((_, index) => index !== product_index);
    let new_errors = productsListErrors.filter((_, index) => index !== product_index);
    setProductsListErrors(new_errors)
    setProductsList(new_data);
  };

  const handleReturnProductChange = (returnProduct: ProductInterface, index: number, text: string) => {
    let new_data = [...returnProducts];
    if(returnProduct){
      new_data[index].product_id = returnProduct.id;
      new_data[index].logistical_description = returnProduct.logistical_description;
      if(returnProductsErrors[index].product_name){
        let new_errors = returnProductsErrors;
        new_errors[index].product_name = null;
        setReturnProductsErrors(new_errors);
      }
    }else {
      new_data[index].logistical_description = text;
      new_data[index].product_id = "";
      if(!dtStoreinfo.stock_managed) {
        if(returnProductsErrors[index].product_name){
          let new_errors = returnProductsErrors;
          new_errors[index].product_name = null;
          setReturnProductsErrors(new_errors);
        }
      }
    }
  };  

  const handleReturnQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    let new_data = [...returnProducts];
    let new_value = parseInt(e.target.value);
    new_data[index].quantity = new_value;
    if(new_value !== 0 && returnProductsErrors[index].quantity){
      let new_errors = returnProductsErrors;
      new_errors[index].quantity = null;
      setReturnProductsErrors(new_errors);
    };
    setReturnProducts(new_data);
  };

  const handleAddReturnFieldProduct = () => {
    setReturnProducts([ ...returnProducts, { ...INITIAL_PRODUCT, is_exchange: true } ]);
    setReturnProductsErrors([ ...returnProductsErrors, { ...INITIAL_PRODUCT_ERROR_STATE } ]);
  };

  const handleRemoveReturnFieldProduct = (index: number) => {
    setReturnProducts(returnProducts.filter((_, productIndex) => productIndex !== index));
    setReturnProductsErrors(returnProductsErrors.filter((_, errIndex) => errIndex !== index));
  };

  const handleExternalIdChange = (e: React.ChangeEvent<HTMLInputElement>) => setExternalId(e.target.value);

  const handleSubmit = () => {
    const MAX_TOTAL_QUANTITY = 10;
    let new_errors = productsListErrors;
    let new_return_errors = returnProductsErrors;

    productsList.forEach((item, index) => {
      if(item.product_id === "" && dtStoreinfo.stock_managed) new_errors[index].product_name = Translate("error", "code5");
      if(item.logistical_description === "" && !dtStoreinfo.stock_managed) new_errors[index].product_name = Translate("error", "reqfield");
      if(item.quantity === 0 || isNaN(item.quantity)) new_errors[index].quantity = Translate("error", "quantitycant0");
    });

    returnProducts.forEach((item, index) => {
      if(item.product_id === "" && dtStoreinfo.stock_managed) new_return_errors[index].product_name = Translate("error", "code5");
      if(item.logistical_description === "" && !dtStoreinfo.stock_managed) new_return_errors[index].product_name = Translate("error", "reqfield");
      if(item.quantity === 0 || isNaN(item.quantity)) new_return_errors[index].quantity = Translate("error", "quantitycant0");
    });

    if(
      !new_errors.some((err, _) => err.quantity) && 
      productsList.reduce((val, item) => val + item.quantity, 0) > MAX_TOTAL_QUANTITY
    ){
      new_errors = new_errors.map(
        (err, _) => ({ 
          ...err, 
          quantity: Translate("error", "maxtotalquantity") 
        })
      );
    }

    if(
      new_errors.some((err, _) => err.product_name || err.quantity) || 
      new_return_errors.some((err, _) => err.product_name || err.quantity)
    ){
      setProductsListErrors([...new_errors]);
      setReturnProductsErrors([...new_return_errors]);
    }else {
      onFormSubmit({ 
        products: [
          ...productsList.map((item, _) => ({ ...item })),
          ...returnProducts.map((item, _) => ({ ...item }))
        ],
        external_order_id: externalId
      });
    };
  }

  useEffect(() => {
    
    const external_order_id = orderProductsData.external_order_id;
    setExternalId(external_order_id ?? "");
    
    const orderProducts = orderProductsData.orderProducts;
    
    if(orderProducts.length > 0){
      
      setProductsList(orderProducts.filter(p => !p.is_exchange).map((item, _) => ({ 
        product_id: item.product_id, 
        logistical_description: item.logistical_description, 
        quantity: item.quantity,
        is_exchange: item.is_exchange 
      })));
      
      setProductsListErrors(
        orderProducts
        .filter(p => !p.is_exchange)
        .map((_, __) => ({ ...INITIAL_PRODUCT_ERROR_STATE }))
      );
      
      setReturnProducts(orderProducts.filter(p => p.is_exchange).map((item, _) => ({
        product_id: item.product_id,
        logistical_description: item.logistical_description,
        quantity: item.quantity,
        is_exchange: item.is_exchange
      })));

      setReturnProductsErrors(
        orderProducts
        .filter(p => p.is_exchange)
        .map((_, __) => ({ ...INITIAL_PRODUCT_ERROR_STATE }))
      );

    }

  }, [orderProductsData.orderProducts, orderProductsData.external_order_id]);

  useEffect(() => {
    GetAllProductsV2();
  }, []);

  useEffect(() => {
    
    if(GettingAllProducts === '3') 
    SendAlert("50", "Failed to fetch data!", "");

  }, [GettingAllProducts])

  return (
    <div className="Stclmnf" style={{ gap: "30px", display: `${active ? "flex" : "none"}` }}>
      <div className="InFlx Stclmnf" style={{ gap: "16px" }}>
        <h3 className="DlMg">{Translate("orders", "orderinfo")}</h3>
        <div className="InFlx Stclmnf" style={{ gap: "20px", padding: "5px 0" }}>
          {
            productsList.map((productListItem, index) => (
              <div className="InFlx align-items-start spcBtwn" key={index} style={{ gap: "20px" }}>
                <Select
                  label={Translate("product", "productname")}
                  placholder={Translate("product", "searchforproduct")}
                  search={true}
                  Options={
                    (productsData?.list ?? []).filter(
                      (product, _) => !productsList.some(
                        (item, itemIndex) => item.product_id === product.id && index !== itemIndex
                      )
                    )
                  }
                  fieldShow={"logistical_description"}
                  name={"order-product-name-" + index}
                  loading={GettingAllProducts === '1'}
                  value={(productsData?.list ?? []).find((product, _) => product.id === productListItem.product_id) ?? null}
                  onChange={(option: ProductInterface, text: string) => handleProductChange(option, index, text)}
                  disabled={GettingAllProducts === "1" || GettingAllProducts === "3"}
                  containerClass={productsListErrors[index].product_name ? "borderError" : ""}
                  error={productsListErrors[index].product_name ? productsListErrors[index].product_name : null}
                />
                <GroupeForm
                  name={"order-product-quantity-" + index}
                  placeholder={Translate("orders", "quantity")}
                  text={Translate("orders", "quantity")}
                  type={"number"}
                  value={productListItem.quantity}
                  min={1}
                  workfun={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, index)}
                  stclass={productsListErrors[index].quantity ? "borderError" : ""}
                  error={productsListErrors[index].quantity ? productsListErrors[index].quantity : null}
                  style={{ minWidth: "385px" }}
                />
                {
                  productsList.length > 1
                  &&
                  <div
                    onClick={() => handleRemoveProduct(index)} 
                    className="CrsPoi">
                    {CloseSvg2}
                  </div>
                }
              </div>
            ))
          }
          <div className="FlWd StdirLn">
            <span className="StBle CrsPoi" onClick={AddFieldProduct}>
              {"+ " + Translate("orders", "addanotherproduct")}
            </span>
          </div>

          {
            returnProducts.length > 0
            &&
            <hr className="FlWd marginTop1 marginBottom1"/>
          }

          {
            returnProducts.map((productListItem, index) => (
              <div className="InFlx align-items-start spcBtwn" key={index} style={{ gap: "20px" }}>
                <Select
                  label={`${Translate("product", "productname")} (${Translate("exchanges", "tobereturned")})`}
                  placholder={Translate("product", "searchforproduct")}
                  search={true}
                  Options={
                    (productsData?.list ?? []).filter(
                      (product, _) => !returnProducts.some(
                        (item, itemIndex) => item.product_id === product.id && index !== itemIndex
                      )
                    )
                  }
                  fieldShow={"logistical_description"}
                  name={"order-return-product-name-" + index}
                  loading={GettingAllProducts === '1'}
                  value={(productsData?.list ?? []).find((product, _) => product.id === productListItem.product_id) ?? null}
                  onChange={(option: ProductInterface, text: string) => handleReturnProductChange(option, index, text)}
                  disabled={GettingAllProducts === "1" || GettingAllProducts === "3"}
                  containerClass={returnProductsErrors[index].product_name ? "borderError" : ""}
                  error={returnProductsErrors[index].product_name ? returnProductsErrors[index].product_name : null}
                />
                <GroupeForm
                  name={"order-return-product-quantity-" + index}
                  placeholder={Translate("orders", "quantity")}
                  text={Translate("orders", "quantity")}
                  type={"number"}
                  value={productListItem.quantity}
                  min={1}
                  workfun={(e: ChangeEvent<HTMLInputElement>) => handleReturnQuantityChange(e, index)}
                  stclass={returnProductsErrors[index].quantity ? "borderError" : ""}
                  error={returnProductsErrors[index].quantity ? returnProductsErrors[index].quantity : null}
                  style={{ minWidth: "385px" }}
                />
                {
                  returnProducts.length > 1
                  &&
                  <div
                    onClick={() => handleRemoveReturnFieldProduct(index)} 
                    className="CrsPoi">
                    {CloseSvg2}
                  </div>
                }
              </div>
            ))
          }

          {
            returnProducts.length > 0
            &&
            <div className="FlWd StdirLn">
              <span className="StBle CrsPoi" onClick={handleAddReturnFieldProduct}>
                +{" " + Translate("orders", "addanotherproducttoreturn")}
              </span>
            </div>
          }
          

          <GroupeForm
            id={"order-external-id"}
            name={"order-external-id"}
            placeholder={Translate("product", "extrnalid")}
            text={Translate("product", "extrnalid")}
            type={"text"}
            value={externalId}
            workfun={handleExternalIdChange}
            optional={true}
            style={{ maxWidth: "500px" }}
          />
        </div>
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="MrAot" style={{ width: "300px" }}>
        <Button
          gray={disableSubmit}
          disabled={disableSubmit} 
          onClick={handleSubmit}
          BtnText={Translate("exchanges", "next")} 
          style={{ padding: "10px 0" }} 
        />
      </div>
    </div>
  );
};

function mapState(state: any) {
  
  const { dtStoreinfo } = state.user;
  const { GettingAllProducts, productsData } = state.product;

  return {
    dtStoreinfo,
    GettingAllProducts,
    productsData
  }
};

const actionCreators = {
  GetAllProductsV2: productActions.GetAllProductsV2,
  SendAlert: alertActions.SendAlert,
};

const connectedProductsForm = connect(mapState, actionCreators)(ProductsForm);
 
export { connectedProductsForm as ProductsForm };