import XLSX from "xlsx";
export default class FileExporter{

    constructor(fileName) {
        this.fileName=fileName
        
    }

    exportExcel(sheets,fileType="xlsx"){
        var TypeFile = {
            xls: "biff8",
            xlsx: "xlsx",
            csv: "csv",
        };
        var wb = XLSX.utils.book_new();
        sheets.forEach(sheet=>{
              const {Header,Rows,sheetTitle,...others}=sheet
            var createXLSLFormatObj = [];
      
            createXLSLFormatObj.push(Header);
            for (var i = 0; i < Rows.length; i++) {
              let Row = Rows[i];
              createXLSLFormatObj.push(Row.content);
              if(Row.subRows)
                  for (var l = 0; l < Row.subRows.length; l++) {
                  createXLSLFormatObj.push(Row.subRows[l]);
                  }
            }
            var ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
            ws = XLSX.utils.aoa_to_sheet(createXLSLFormatObj);
            if(others.colsWidth)
            ws["!cols"] = others.colsWidthls;
            XLSX.utils.book_append_sheet(wb, ws, sheetTitle.replace(' ','').slice(0,30));
            
        })
        var todayDate = new Date().toISOString().split('T')[0];
        
        var filename = (this.fileName && this.fileName.slice(0,30)) || ("file_export" + todayDate + "." + fileType);
         
      
          XLSX.writeFile(wb, filename, { bookType: TypeFile[fileType] });

    }
}