import React from 'react';
import './VarCss/RootContainer.css'
export default function ContainerLog(props){
    return(
        <div className="CnGl DlPg DlMg FxPs FlWd FlHg OvfAtoY">
            <div className="TbDp RlPs FlWd FlHg StSizLn SetTopbd">
                <div className="TbCdp RlPs TxtCn VrAlg ">
                    <div className="StGwdt StGhgt ClSidTh MrAot StLyt RlPs StMev">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}