export const ordersConstants = {
  ADD_ORDER_SUCCESS: "ADD_ORDER_SUCCESS",
  ADD_ORDER_FAILURE: "ADD_ORDER_FAILURE",
  ADD_ORDER_REQUEST: "ADD_ORDER_REQUEST",

  CLEAR_ADD_ORDER_STATE: "CLEAR_ADD_ORDER_STATE",  

  IMPORT_ORDER_SUCCESS: "IMPORT_ORDER_SUCCESS",
  IMPORT_ORDER_FAILURE: "IMPORT_ORDER_FAILURE",
  IMPORT_ORDER_REQUEST: "IMPORT_ORDER_REQUEST",

  IMPORT_ORDPRDS_SUCCESS: "IMPORT_ORDPRDS_SUCCESS",
  IMPORT_ORDPRDS_FAILURE: "IMPORT_ORDPRDS_FAILURE",
  IMPORT_ORDPRDS_REQUEST: "IMPORT_ORDPRDS_REQUEST",

  IMPORT_PRODUCT_SUCCESS: "IMPORT_PRODUCT_SUCCESS",
  IMPORT_PRODUCT_FAILURE: "IMPORT_PRODUCT_FAILURE",
  IMPORT_PRODUCT_REQUEST: "IMPORT_PRODUCT_REQUEST",

  UPDATE_ORDER_SUCCESS: "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_FAILURE: "UPDATE_ORDER_FAILURE",
  UPDATE_ORDER_REQUEST: "UPDATE_ORDER_REQUEST",

  UPDATE_STATUS_ORDER_SUCCESS: "UPDATE_STATUS_ORDER_SUCCESS",
  UPDATE_STATUS_ORDER_FAILURE: "UPDATE_STATUS_ORDER_FAILURE",
  UPDATE_STATUS_ORDER_REQUEST: "UPDATE_STATUS_ORDER_REQUEST",

  EXPORT_ORDER_SUCCESS: "EXPORT_ORDER_SUCCESS",
  EXPORT_ORDER_FAILURE: "EXPORT_ORDER_FAILURE",
  EXPORT_ORDER_REQUEST: "EXPORT_ORDER_REQUEST",

  GET_ORDER_SUCCESS: "GET_ORDER_SUCCESS",
  GET_ORDER_FAILURE: "GET_ORDER_FAILURE",
  GET_ORDER_REQUEST: "GET_ORDER_REQUEST",

  GET_ALL_ORDER_SUCCESS: "GET_ALL_ORDER_SUCCESS",
  GET_ALL_ORDER_FAILURE: "GET_ALL_ORDER_FAILURE",
  GET_ALL_ORDER_REQUEST: "GET_ALL_ORDER_REQUEST",

  GET_ORDER_HISTORY_SUCCESS: "GET_ORDER_HISTORY_SUCCESS",
  GET_ORDER_HISTORY_FAILURE: "GET_ORDER_HISTORY_FAILURE",
  GET_ORDER_HISTORY_REQUEST: "GET_ORDER_HISTORY_REQUEST",

  GET_UNF_ORDER_SUCCESS: "GET_UNF_ORDER_SUCCESS",
  GET_UNF_ORDER_FAILURE: "GET_UNF_ORDER_FAILURE",
  GET_UNF_ORDER_REQUEST: "GET_UNF_ORDER_REQUEST",

  GET_UNF_ORDER_TIMELINE_SUCCESS: "GET_UNF_ORDER_TIMELINE_SUCCESS",
  GET_UNF_ORDER_TIMELINE_FAILURE: "GET_UNF_ORDER_TIMELINE_FAILURE",
  GET_UNF_ORDER_TIMELINE_REQUEST: "GET_UNF_ORDER_TIMELINE_REQUEST",

  UPDATE_UNF_ORDER_SUCCESS: "UPDATE_UNF_ORDER_SUCCESS",
  UPDATE_UNF_ORDER_FAILURE: "UPDATE_UNF_ORDER_FAILURE",
  UPDATE_UNF_ORDER_REQUEST: "UPDATE_UNF_ORDER_REQUEST",

  GET_CONF_SUMMARY_SUCCESS: "GET_CONF_SUMMARY_SUCCESS",
  GET_CONF_SUMMARY_FAILURE: "GET_CONF_SUMMARY_FAILURE",
  GET_CONF_SUMMARY_REQUEST: "GET_CONF_SUMMARY_REQUEST",

  GET_CONF_ORDER_SUCCESS: "GET_CONF_ORDER_SUCCESS",
  GET_CONF_ORDER_FAILURE: "GET_CONF_ORDER_FAILURE",
  GET_CONF_ORDER_REQUEST: "GET_CONF_ORDER_REQUEST",

  UPDATE_CONF_ORDER_SUCCESS: "UPDATE_CONF_ORDER_SUCCESS",
  UPDATE_CONF_ORDER_FAILURE: "UPDATE_CONF_ORDER_FAILURE",
  UPDATE_CONF_ORDER_REQUEST: "UPDATE_CONF_ORDER_REQUEST",

  CLEAR_UPDATE_UNF_ORDER: "CLEAR_UPDATE_UNF_ORDER",

  GET_UNF_ORDER_COUNT_SUCCESS: "GET_UNF_ORDER_COUNT_SUCCESS",
  GET_UNF_ORDER_COUNT_FAILURE: "GET_UNF_ORDER_COUNT_FAILURE",
  GET_UNF_ORDER_COUNT_REQUEST: "GET_UNF_ORDER_COUNT_REQUEST",

  GET_ORDER_DETAIL_SUCCESS: "GET_ORDER_DETAIL_SUCCESS",
  GET_ORDER_DETAIL_FAILURE: "GET_ORDER_DETAIL_FAILURE",
  GET_ORDER_DETAIL_REQUEST: "GET_ORDER_DETAIL_REQUEST",

  GET_CURRENT_ORDERS_SUCCESS: "GET_CURRENT_ORDERS_SUCCESS",
  GET_CURRENT_ORDERS_FAILURE: "GET_CURRENT_ORDERS_FAILURE",
  GET_CURRENT_ORDERS_REQUEST: "GET_CURRENT_ORDERS_REQUEST",

  UPDATE_CALLBACK_UNF_ORDER_SUCCESS: "UPDATE_CALLBACK_UNF_ORDER_SUCCESS",
  UPDATE_CALLBACK_UNF_ORDER_FAILURE: "UPDATE_CALLBACK_UNF_ORDER_FAILURE",
  UPDATE_CALLBACK_UNF_ORDER_REQUEST: "UPDATE_CALLBACK_UNF_ORDER_REQUEST",

  UPDATE_POSTPONED_UNF_ORDER_SUCCESS: "UPDATE_POSTPONED_UNF_ORDER_SUCCESS",
  UPDATE_POSTPONED_UNF_ORDER_FAILURE: "UPDATE_POSTPONED_UNF_ORDER_FAILURE",
  UPDATE_POSTPONED_UNF_ORDER_REQUEST: "UPDATE_POSTPONED_UNF_ORDER_REQUEST",

  GET_ORDER_BY_ID_REQUEST: "GET_ORDER_BY_ID_REQUEST",
  GET_ORDER_BY_ID_SUCCESS: "GET_ORDER_BY_ID_SUCCESS",
  GET_ORDER_BY_ID_FAILURE: "GET_ORDER_BY_ID_FAILURE",

  GET_TEMP_ORDER_BY_ID_REQUEST: "GET_TEMP_ORDER_BY_ID_REQUEST",
  GET_TEMP_ORDER_BY_ID_SUCCESS: "GET_TEMP_ORDER_BY_ID_SUCCESS",
  GET_TEMP_ORDER_BY_ID_FAILURE: "GET_TEMP_ORDER_BY_ID_FAILURE",


  CLEAR_BORDEREAU_STATE: "CLEAR_BORDEREAU_STATE"
};
