import React from "react";
import PropTypes from "prop-types";
import "./css/spinner.css"

const ColorMap={
    info: "#3288c4",
    danger: "#ff4f4f",
    success: "#44be84",
    pending: "#c57d12",
    white: "#fff",
}
function Spinner(props) {
  return (
    <div className="spinner" style={{borderTopColor: ColorMap[props.variant] || ColorMap["white"]}}>

    </div>
  );
}

Spinner.propTypes = {

    variant: PropTypes.string
};

export default Spinner;
