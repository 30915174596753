import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "../reducers";
const LogerModdleware = createLogger();
const midlleware =
  process.env.REACT_APP_STATE !== "prod" && window.__REDUX_DEVTOOLS_EXTENSION__
    ? compose(
        applyMiddleware(
          thunkMiddleware
          // LogerModdleware
        ),
        window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : applyMiddleware(thunkMiddleware);
export const store = createStore(rootReducer, midlleware);
