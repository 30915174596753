import React from "react";
import { Translate } from "../../../utils/lang/translate";
export default function StatusElement(props) {
  let class_status =
    " TxtCn StPdsml sDfpnc sb4R " +
    (props.classes || "status") +
    "_" +
    props.children;
  return (
    <div
      onClick={props.onClick}
      className={class_status + " " + (props.status_class || "")}
    >
      {Translate(props.category || "statusorder", props.children)}
    </div>
  );
}
