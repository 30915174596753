import { useState } from "react";

export const useWebhooksModals = () => {
  const [openModals, setOpenModals] = useState({
    openAddWebhookModal: false,
    openDeleteWebhookModal: false,
    openTestWebhookModal: false,
  });
  const handleOpenModals = {
    handleOpenAddWebhook: () =>
      setOpenModals((current) => ({ ...current, openAddWebhookModal: true })),
    handleOpenDeleteWebhook: () =>
      setOpenModals((current) => ({
        ...current,
        openDeleteWebhookModal: true,
      })),
    handleOpenTestWebhook: () =>
      setOpenModals((current) => ({ ...current, openTestWebhookModal: true })),
  };
  const handleCloseModals = {
    handleCloseAddWebhook: () =>
      setOpenModals((current) => ({ ...current, openAddWebhookModal: false })),
    handleCloseDeleteWebhook: () =>
      setOpenModals((current) => ({
        ...current,
        openDeleteWebhookModal: false,
      })),
    handleCloseTestWebhook: () =>
      setOpenModals((current) => ({ ...current, openTestWebhookModal: false })),
  };

  return { openModals, handleOpenModals, handleCloseModals };
};
