import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Translate } from '../../../utils/lang/translate';
import { date_difference } from '../../../utils/functions';

function CountDown(props) {
    const deadline=new Date(props.expirationDate);
    const [remainingTime,setRemainingTime]=useState(date_difference(deadline,new Date()));
    const cols=[
        {label: Translate("datepicker","days"), value: 0},
        {label: Translate("datepicker","hours"), value: 0},
        {label: Translate("datepicker","minutes"), value: 0},
        {label: Translate("datepicker","seconds"), value: 0},
    ];

    useEffect(()=>{
        setInterval(()=>{
            let remaining=date_difference(deadline,new Date())
            setRemainingTime(remaining)
        },1000)

    },[])
    return (
        <div className="FlWd Inlflx AlgnItm JstfCnt">
            {cols.map((el,idx)=>{
                return(
                    <div className="Inlflx JstfCnt" style={{flexGrow: 1,flexDirection:"column",margin:"0 7%"}}>
                        <h3 className="ClSidTh">
                            {idx===0 && remainingTime.days}
                            {idx===1 && remainingTime.hours}
                            {idx===2 && remainingTime.minutes}
                            {idx===3 && remainingTime.seconds}

                        </h3>
                        <h5 className="StOpcVal">{el.label}</h5>

                    </div>
                )

            })}

            
        </div>
    )
}

CountDown.propTypes = {
    expirationDate: PropTypes.any.isRequired

}

export default CountDown

