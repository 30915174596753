import React from 'react';  
import {Route } from 'react-router-dom';
import Cookies from 'js-cookie'
const PrivateRoute = ({ private: Private,public:Public, ...rest }) => (  
  <Route { ...rest } render={props => (
    Cookies.get('auth') ? 
    <Private { ...props } />
    :<Public/>
  )} />
);

export default PrivateRoute;