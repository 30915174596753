import React, { useRef } from "react";
export default function DropedList(props) {
  const DataDropref = useRef(null);
  const RefHei = useRef(null);
  function handleClick() {
    let classes = DataDropref.current.classList;
    let heigt = RefHei.current.clientHeight;
    if (classes.value.indexOf("hidElem2") === -1) {
      DataDropref.current.classList.add("hidElem2");
      DataDropref.current.style.height = "0px";
    } else {
      DataDropref.current.classList.remove("hidElem2");
      DataDropref.current.style.height = heigt + "px";
    }
  }
  return (
    <div>
      <div onClick={handleClick}>{props.title}</div>
      <div ref={DataDropref} className="stTranEs hidElem2">
        <div ref={RefHei}>{props.dataDrop}</div>
      </div>
    </div>
  );
}
