import React from 'react'
import './css/balance.css'
export default function BalanceTab(props){
    return(
        <div className="RlPs">
            <div className="RlPs TxtCn MrAot" style={{width:"130px",height:"130px"}}>
                <svg className="BrgSVGTB" style={{"--PrcBl":props.val}}>
                    <circle cx="60" cy="60" r="57"></circle>
                    <circle cx="60" cy="60" r="57"></circle>
                </svg>
                <div className="Brcnelm stbold" style={{fontSize:"20px"}}>{props.val+" "}%</div>
            </div>
        </div>
    )
}