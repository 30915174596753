import React, { useEffect, useState } from 'react';
import Button from '../../../_Common/Button';
import { Translate } from '../../../../utils/lang/translate';
import GroupeForm from '../../../_Common/GroupeForm';
import { checkPhoneNumberValidity } from '../../../../utils/helpers';

type CustomerInfoType = {
  name: string,
  phone: string,
};

interface NamePhoneError {
  name: string | null,
  phone: string | null,
};

interface CustomerInfoFormProps {
  onFormSubmit: (data: CustomerInfoType) => void,
  customerInfo?: CustomerInfoType | null,
  active: boolean,
};

const CustomerInfoForm = ({
  onFormSubmit,
  customerInfo,
  active
}: CustomerInfoFormProps) => {

  const INITIAL_NAME_PHONE_ERROR_STATE: NamePhoneError = {
    name: null,
    phone: null,
  };

  const [customerName, setCustomerName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [hideInput, setHideInput] = useState<number>(10);
  const [customerNamePhoneError, setCustomerNamePhoneError] = useState<NamePhoneError>(INITIAL_NAME_PHONE_ERROR_STATE);

  const disableSubmit: boolean = 
    customerNamePhoneError.name !== null || 
    customerNamePhoneError.phone !== null
  ;

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>, second?: boolean) => {
    const value = e.target.value;
    setCustomerPhoneNumber(value);

    const limitPlusDZ = 13;
    const limitPlusTN = 12;
    const limitDZ = 10;
    const StartNumberPhone = value.substring(0, 4);
    const StartNumberPhone0 = value.substring(0, 1);

    if (StartNumberPhone === "+213") {
      setHideInput(limitPlusDZ);
    }
    if (StartNumberPhone === "+216") {
      setHideInput(limitPlusTN);
    }
    if (StartNumberPhone0 === "0") {
      setHideInput(limitDZ);
    }
    if(checkPhoneNumberValidity(value) && customerNamePhoneError.phone){
      setCustomerNamePhoneError(prev => ({ ...prev, phone: null }));
    }
  };

  const handleCustomerNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if(value !== "" && customerNamePhoneError.name) setCustomerNamePhoneError(prev => ({ ...prev, name: null }));
    setCustomerName(value);
  };

  const handleSubmit = () => {
    if(customerName === "") setCustomerNamePhoneError(prev => ({ ...prev, name: Translate("error", "eo4") }));
    if(!checkPhoneNumberValidity(customerPhoneNumber)) setCustomerNamePhoneError(prev => ({ ...prev, phone: Translate("error", "invalidphone") }));
    if(
      customerName && checkPhoneNumberValidity(customerPhoneNumber)
    ){
      onFormSubmit({
        name: customerName,
        phone: customerPhoneNumber,
      });
    }
  };

  useEffect(() => {
    if(customerInfo){
      setCustomerName(customerInfo.name);
      setCustomerPhoneNumber(customerInfo.phone);
    }
  }, [customerInfo?.name, customerInfo?.phone]);

  return (
    <div className="InFlx Stclmnf" style={{ gap: "30px", display: `${active ? "flex" : "none"}` }}>
      <div className="InFlx Stclmnf" style={{ gap: "16px" }}>
        <h3 className="DlMg">{Translate("orders", "orderinfo")}</h3>
        <div 
          className="InFlx" 
          style={{ flexWrap: "wrap", justifyContent: "space-between", gap: "30px 16px" }}
        >
          <GroupeForm
            id={"customername"}
            name={"customername"}
            placeholder={Translate("orders", "customername")}
            text={Translate("orders", "customername")}
            type={"text"}
            value={customerName}
            workfun={handleCustomerNameChange}
            error={customerNamePhoneError.name}
            stclass={customerNamePhoneError.name ? "borderError" : ""}
            style={{ flex: "0 1 500px" }}
          />
          <GroupeForm
            id={"customerphonenumber"}
            name={"customerphonenumber"}
            placeholder={Translate("orders", "customerphonenumber")}
            text={Translate("orders", "customerphonenumber")}
            type={"phone"}
            pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
            value={customerPhoneNumber}
            workfun={handlePhoneNumberChange}
            maxLength={hideInput}
            error={customerNamePhoneError.phone}
            stclass={customerNamePhoneError.phone ? "borderError" : ""}
            style={{ flex: "0 1 500px" }}
          />
        </div>
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="MrAot" style={{ width: "300px" }}>
        <Button
          gray={disableSubmit}
          disabled={disableSubmit} 
          onClick={handleSubmit}
          BtnText={Translate("exchanges", "next")} 
          style={{ padding: "10px 0" }} 
        />
      </div>
    </div>
  );
}
 
export default CustomerInfoForm;