import React,{useRef} from 'react'
import Table from '../../_Common/Table'
import {Translate} from '../../../../utils/lang/translate'
import {FlechDwnSvg} from '../../_Common/IconSvg'
export default function ElementFacture(props){
    const ActElem = useRef(null);
    const RefHeiT=useRef(null)
    function handleClick() {
        let classes=ActElem.current.classList
        let heigt=RefHeiT.current.clientHeight;
        if(classes.value.indexOf("hidElem2")===-1){
            ActElem.current.classList.add("hidElem2");
            ActElem.current.style.height="0px"  
        }else{
            ActElem.current.classList.remove("hidElem2"); 
            ActElem.current.style.height=heigt+"px" 
        }
    }
    return(
        <div className="RlPs StGlbtr stbd">
            <h4 className="DlMg StdirLn">{props.title}</h4>
            <div className="StAbs StLanNl StTpNl FlHg CrsPoi ElmSvgrg2 StElDrp" onClick={handleClick}>
                {FlechDwnSvg}
            </div>
            <div >
                <div className="stbd2" >
                    <div className="StGlbtr" style={{height:"100%"}} ref={ActElem}>
                        <div ref={RefHeiT}>
                            <Table
                                thead_elem={["ID",Translate("product","productname").toUpperCase(),Translate("payement","price").toUpperCase()]}
                                tbody_elem={props.rows}
                                idtab={props.idtab}
                            />
                        </div>
                    </div>
                    <div className="PsinLan">
                        <div className="StPdsml flxDrc Inlflx AlgnItm">
                            <span className="StOpcVal StDrcIn" style={{fontSize:'1em'}}>{props.nameTtl}</span>
                            <strong style={{fontSize:"1em",margin:"0 4px"}}>{props.ValTtl}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}