import React, { ReactNode } from "react";
import "./Card.css";

interface CardProps {
  padding?: number,
  bgColor?: string,
  borderColor?: string,
  children?: ReactNode,
  bgImageSrc?: string,
  onClick?: () => void,
  className?: string,
  style?: React.CSSProperties
}

const Card = ({ padding, bgColor, borderColor, children, bgImageSrc, onClick, className, style }: CardProps) => {
  return (
    <div 
      className={`card-layout${className ? ` ${className}` : ""}`} 
      style={{ 
        padding: `${padding !== undefined ? padding + "px" : "24px"}`,
        backgroundColor: `${bgColor ? bgColor : "var(--defcl)"}`,
        borderColor: `${borderColor ? borderColor : "var(--clrbrd)"}`,
        backgroundImage: `url(${bgImageSrc ? bgImageSrc : null})`,
        cursor: `${onClick ? "pointer" : "default"}`,
        ...style
      }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

interface HeaderProps {
  children: ReactNode
}

const Header = ({ children }: HeaderProps) => {
  return (
    <div className="FlWd InFlx spcBtwn AlgnItm">
      {children}
    </div>
  );
};

Card.Header = Header;
 
export default Card;