import { messageConstants } from "../constants/messageUpadate.constants";

export const alertReducer = (state, action) => {
  switch (action.type) {
    case messageConstants.NEW_ACTION_REQUEST:
      return {
        Gettingmessage: "1",
        dataAnnouncement: action.payload,
      };
    case messageConstants.NEW_ACTION_SUCCESS:
      return {
        Gettingmessage: "2",
        dataAnnouncement: action.payload,
      };
    case messageConstants.NEW_ACTION_FAILURE:
      return {
        Gettingmessage: "3",
        dataAnnouncement: action.payload,
      };
    default:
      return { ...state };
  }
};
