import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '../../../_Common/Button';
import { Translate } from '../../../../utils/lang/translate';
import GroupeForm from '../../../_Common/GroupeForm';
import ToggleSwitch from '../../../_Common/ToggleSwitch';

interface ProductFieldError { 
  quantity: string | null, 
  product_name: string | null,
};

type OrderProduct = { product_id: string, quantity: number, logistical_description: string, is_exchange: boolean, defective: boolean, is_refund: boolean };

interface RefundProductsFormProps {
  dtStoreinfo: any,
  onFormSubmit: (data: { products: OrderProduct[] }) => void,
  orderProductsData: { orderProducts: OrderProduct[] | null },
  active: boolean,
};

const RefundProductsForm = ({
  dtStoreinfo,
  onFormSubmit,
  orderProductsData,
  active
}: RefundProductsFormProps) => {

  const INITIAL_PRODUCT_ERROR_STATE: ProductFieldError = {
    product_name: null, 
    quantity: null
  };

  const [productsList, setProductsList] = useState<OrderProduct[]>([]);
  const [productsListErrors, setProductsListErrors] = useState<ProductFieldError[]>([]);
  
  const handleQuantityChange = (e: ChangeEvent<HTMLInputElement>, index: number) => {
    let new_data = [...productsList.map(p => ({...p}))];
    let new_value = parseInt(e.target.value); 
    new_data[index].quantity = new_value;
    if(new_value !== 0) {
      new_data[index].is_refund = true;  
      if(productsListErrors[index].quantity){
        if(isNaN(productsList[index].quantity)) {
          let new_errors = [...productsListErrors];
          new_errors[index].quantity = null;
          setProductsListErrors([...new_errors]);  
        }else {
          setProductsListErrors(prev => prev.map(err => ({ ...err, quantity: null })));
        }
      }
    } 
    setProductsList([...new_data]);
  };

  const handleDefectiveChange = (index: number) => {
    let new_data = [...productsList];
    new_data[index].defective = !productsList[index].defective;
    setProductsList(new_data);
  };
  
  const handleSubmit = () => {
    let new_errors = productsListErrors;

    if(productsList.every((item, _) => item.quantity === 0)){
      new_errors = [ ...productsListErrors.map(err => ({ ...err, quantity: Translate("error", "quantitycant0") })) ];
    }else {
      productsList.forEach((item, index) => {
        if(isNaN(item.quantity)) new_errors[index].quantity = Translate("error", "reqfield");
      });
    };

    if(new_errors.some((err, _) => err.product_name || err.quantity)){
      setProductsListErrors([...new_errors]);
    }else {
      onFormSubmit({ 
        products: [ ...productsList.filter(p => p.quantity !== 0).map((item, _) => ({ ...item })) ]
      });
    };
  };

  const disableSubmit = !productsListErrors.every((err, _) => !err.product_name && !err.quantity);

  useEffect(() => {

    const orderProducts = orderProductsData.orderProducts;
    if(orderProducts && productsList.length === 0){
      
      setProductsList(orderProducts.map((item, _) => ({ 
        product_id: item.product_id, 
        logistical_description: item.logistical_description, 
        quantity: 0,
        is_exchange: item.is_exchange,
        defective: item.defective,
        exchange_reason: null,
        is_refund: item.is_refund 
      })));

      setProductsListErrors(
        orderProducts.map((_, __) => ({ ...INITIAL_PRODUCT_ERROR_STATE }))
      );
    }

  }, [orderProductsData.orderProducts]);

  return (
    <div className="InFlx Stclmnf" style={{ gap: "30px", display: `${active ? "flex" : "none"}` }}>
      <div className="InFlx Stclmnf" style={{ gap: "40px" }}>
        <h3 className="DlMg">{Translate("orders", "orderinfo")}</h3>
        {
          productsList.map((productListItem, index) => (
            <div className="InFlx Stclmnf" key={index} style={{ gap: "10px" }}>
              <div className="InFlx AlgnItm" style={{ gap: "12px" }}>
                <h2 className="DlMg">#{index + 1}</h2>
                <div className="InFlx Stclmnf align-items-start">
                  <h2 className="DlMg">{productListItem.logistical_description}</h2>
                  <p className="DlMg">{`${Translate("exchanges", "deliveredqty")}: ${orderProductsData?.orderProducts?.[index].quantity ?? 0}`}</p>
                </div>
              </div>
              <div className="InFlx AlgnItm spcBtwn" style={{ gap: "20px" }}>
                <GroupeForm
                  id={"product_return_quantity_" + (index + 1)}
                  name={"prodyct_return_quantity_" + (index + 1)}
                  placeholder={Translate("exchanges", "refundquantity")}
                  text={Translate("exchanges", "refundquantity")}
                  type={"number"}
                  min={0}
                  max={productListItem.quantity}
                  value={productListItem.quantity}
                  defaultValue={0}
                  workfun={(e: ChangeEvent<HTMLInputElement>) => handleQuantityChange(e, index)}
                  stclass={productsListErrors[index].quantity ? "borderError" : ""}
                  error={productsListErrors[index].quantity ? productsListErrors[index].quantity : null}
                  style={{ flex: "1 1 500px" }}
                />
                {
                  dtStoreinfo.stock_managed
                  &&
                  <div className="InFlx Stclmnf align-items-start" style={{ flex: "1 1 500px" }}>
                    <p className="StSizLn DlMg"> {Translate("exchanges", "productstat")} </p>
                    <div className="InFlx AlgnItm" style={{ gap: "10px" }}>
                      <ToggleSwitch isOn={productsList[index].defective} toggleHandler={() => handleDefectiveChange(index)}/>
                      <div className="InFlx Stclmnf">
                        <p className="DlMg">{Translate("exchanges", "productdefective")}</p>
                        <p className="DlMg StOpcVal">{Translate("exchanges", "productdefectiveseparat")}</p>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          ))
        }
      </div>
      <div className="FlWd" style={{ height: "1px", backgroundColor: "var(--fntClr)", opacity: 0.2 }}/>
      <div className="MrAot" style={{ width: "300px" }}>
        <Button
          gray={disableSubmit}
          disabled={disableSubmit} 
          onClick={handleSubmit}
          BtnText={Translate("exchanges", "next")} 
          style={{ padding: "10px 0" }} 
        />
      </div>
    </div>
  );
};
 
function mapState(state: any) {
  
  const { dtStoreinfo } = state.user;
  
  return {
    dtStoreinfo,
  }
};

const actionCreators = {};

const connectedRefundProductsForm = connect(mapState, actionCreators)(RefundProductsForm);
 
export { connectedRefundProductsForm as RefundProductsForm };