import React , {useRef,useState} from 'react'
import {FlechDwnSvg} from '../StorePages/_Common/IconSvg'
export function DropDownList(props){
    const initialState = {
        viewlist:true,
        selected:props.initValue ? props.initValue.text :'',
        value:props.initValue ? props.initValue.value :''
    }
    var refDrop=useRef(null)
    const [state, setstate] = useState(initialState);
    function handleClick(){
        let current=state.viewlist
        setstate({
            ...state,
            viewlist:!current
        })
    }
    function setValue(text,value){
        setstate({
            ...state,
            viewlist:true,
            selected:text,
            value:value
        })
        props.onChange(text,value)
    }
    return (
        <div ref={refDrop} className="RlPs StSmlS" style={{zIndex:"3"}}>
            <div className={props.className+" InFlx CrsPoi stb-top-le AlgnItm FrInp StwdMaxC"} onClick={handleClick} style={props.style}>
                <span className="Fnts-vl">{state.selected ? state.selected : props.placeholder}</span>
                <i className="InFlx StAutMr">{FlechDwnSvg}</i>
            </div>
            <div className={(state.viewlist ? "hidElem2" : "")+" StAbs stb-Btm-le FrInp"} style={props.style}>
                {props.listSelect.map((option,key)=>{
                    return  <div key={key} className="sTbtpv CrsPoi" onClick={() => setValue(option.text,option.value)}>{option.text}</div>
                })}
            </div>
        </div>
    )
}