import { Translate } from "../utils/lang/translate";

// Dashboard stats cards status list
export const PREMIUM_STATUSES_LIST = [8, 9, 12, 15, 31, 32, 42];
export const OLD_PREMIUM_STATUSES_LIST = [11, 22, 31, 42];
export const STARTER_STATUSES_LIST = [1, 8, 9, 51, 52, 15, 5, 31, 42];

// Order's filters statuslist
export const OLD_PREMIUM_FILTER_STATUS_LIST = [
  "11",
  "22",
  "31",
  "41",
  "42",
  "50",
];
export const PREMIUM_FILTER_STATUS_LIST = [
  "8",
  "9",
  "11",
  "12",
  "15",
  "22",
  "31",
  "32",
  "41",
  "42",
  "50",
];
export const OLD_STARTER_FILTER_STATUS_LIST = [
  "4",
  "5",
  "6",
  "8",
  "9",
  "15",
  "31",
  "41",
  "42",
  "50",
  "51",
  "52",
  "53",
];
export const STARTER_FILTER_STATUS_LIST = [
  "4",
  "5",
  "6",
  "8",
  "9",
  "15",
  "31",
  "32",
  "41",
  "42",
  "50",
  "51",
  "52",
  "53",
];

// Pickups's statuslist
export const PICKUPS_STATUS_LIST = ["5", "6", "7", "41", "50", "51"];

// Confirmation's statuslist
export const CONFIRMATION_STATUS_LIST = ["10", "20", "30", "40", "100"];

// Table Headers
export const CONFIRMATION_TABLE_HEADER = [
  Translate("orders", "status"),
  Translate("pickups", "products"),
  Translate("orders", "cancellationreason"),
  Translate("orders", "customername"),
  Translate("orders", "district") + " / " + Translate("product", "wilaya"),
  Translate("orders", "lastupdate"),
  Translate("orders", "totalprice"),
];
export const PICKUPS_TABLE_HEADER = [
  Translate("pickups", "id"),
  Translate("orders", "status"),
  Translate("pickups", "timeline"),
  Translate("pickups", "providerinfos"),
  Translate("pickups", "driver"),
  Translate("product", "action"),
];
export const STOCK_PICKUPS_TABLE_HEADER = [
  Translate("pickups", "id"),
  Translate("orders", "status"),
  Translate("pickups", "timeline"),
  Translate("pickups", "providerinfos"),
  Translate("pickups", "driver"),
  Translate("product", "action"),
];
export const PRODUCTS_TABLE_HEADER = [
  Translate("product", "id").toUpperCase(),
  Translate("product", "productname").toUpperCase(),
  Translate("dashboard", "deliveryrate").toUpperCase(),
  Translate("dashboard", "cancellationrate").toUpperCase(),
];
export const RECEIVED_PRODUCT_HEADER = [
  Translate("receivedProducts", "refrence").toUpperCase(),
  Translate("receivedProducts", "wharehouse").toUpperCase(),
  Translate("receivedProducts", "date").toUpperCase(),
  Translate("receivedProducts", "action").toUpperCase(),
];
export const PAYMENTS_TABLE_HEADER = [
  "#",
  Translate("payement", "invoiceelements").toUpperCase(),
  Translate("payement", "price").toUpperCase(),
  Translate("profile", "date").toUpperCase(),
  Translate("product", "action").toUpperCase(),
];
export const SEARCH_TABLE_HEADER = [
  Translate("product", "id").toUpperCase(),
  Translate("product", "productname").toUpperCase(),
  Translate("product", "wilaya").toUpperCase(),
  Translate("orders", "customername").toUpperCase(),
  Translate("orders", "creationdate").toUpperCase(),
  Translate("orders", "totalprice").toUpperCase(),
  Translate("orders", "status").toUpperCase(),
  Translate("product", "action").toUpperCase(),
];
export const UNFULLFILLED_TABLE_HEADER = [
  Translate("product", "id"),
  Translate("product", "productname"),
  Translate("product", "wilaya"),
  Translate("orders", "customername"),
  Translate("auths", "phone"),
  Translate("orders", "creationdate"),
  Translate("orders", "orderprice"),
  Translate("product", "action"),
];

export const DETAIL_PRODUCT_PREMIUM = [
  Translate("product", "product"),
  Translate("product", "qt"),
];
export const DETAIL_PRODUCT_STARTER = [
  Translate("product", "id"),
  Translate("product", "order"),
];

// Rubric & tutorials sidebar items

export const RUBRIC_MENU_ITEMS = [
  {
    icon: "",
    to: "/rubric/updateslog",
    text: Translate("titles", "updateslog"),
  },
  {
    icon: "",
    to: "/rubric/howto",
    text: Translate("titles", "tutorials"),
  },
];
export const WEBHOOK_HEADER = [
  Translate("webhooks", "eventtab").toUpperCase(),
  Translate("webhooks", "callbackURLtab").toUpperCase(),
  Translate("receivedProducts", "action").toUpperCase(),
];
