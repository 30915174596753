import React, { Component } from "react";
import { connect } from "react-redux";
import HeadLog from "../_Common/HeadLog";
import ContainerLog from "../_Common/ContainerLog";
import { Translate } from "../../../utils/lang/translate";
import InlinedElement from "../../StorePages/_Common/InlinedElement";
import {
  CallSvg,
  EmailSvg,
  FacebookSvg,
} from "../../StorePages/_Common/IconSvg";
import { Redirect } from "react-router-dom";
import { commonActions } from "../../../actions";
class SuccessPage extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.title =
      "Maystro Delivrey - " + Translate("titles", "successregister");
    this.props.GetInfoMaystro(this.props?.data?.country?.id);
  }
  render() {
    const { registered, data, user, dtStoreinfo } = this.props;
    if (registered !== "1" && data && data.is_verfied === undefined) {
      return <Redirect to="/login" />;
    }
    if (data && data.is_verfied !== false && registered === undefined) {
      return <Redirect to="/login" />;
    }
    return (
      <ContainerLog>
        <HeadLog
          titleHead={Translate("auths", "success")}
          descHead={
            <div>
              {Translate("auths", "textsuccess")}
              <br />
              <br />
              <div
                dangerouslySetInnerHTML={{
                  __html: Translate("auths", "textsuccess2"),
                }}
              />
            </div>
          }
        />
        <div className="TxtCn StwdMaxC MrAot">
          <div className="SmMarS1">
            <InlinedElement
              first={
                <div className="ElmSvgrg2" style={{ zoom: "1.2" }}>
                  {EmailSvg}
                </div>
              }
              secnd={
                <div className="StdirLn">
                  <div>
                    <strong>{Translate("auths", "email")}</strong>
                  </div>
                  <div className="StSmlS">{this.props?.dataMaystro?.Email}</div>
                </div>
              }
            />
          </div>
          <div className="SmMarS2">
            <InlinedElement
              first={
                <div className="ElmSvgrg2" style={{ zoom: "1.2" }}>
                  {CallSvg}
                </div>
              }
              secnd={
                <div className="StdirLn">
                  <div>
                    <strong>{Translate("auths", "phone")}</strong>
                  </div>
                  <div className="StSmlS">
                    {this.props?.dataMaystro?.["Phone number"]}
                  </div>
                </div>
              }
            />
          </div>
          <div className="SmMarS1">
            <InlinedElement
              first={
                <div className="ElmSvgrg2" style={{ zoom: "1.2" }}>
                  {FacebookSvg}
                </div>
              }
              secnd={
                <div className="StdirLn">
                  <div>
                    <strong>Facebook</strong>
                  </div>
                  <div className="StSmlS">
                    {this.props?.dataMaystro?.Facebook}
                  </div>
                </div>
              }
            />
          </div>
        </div>
      </ContainerLog>
    );
  }
}
function mapState(state) {
  const { registered, user, data } = state.registration;
  //const { data } = state.authentication;
  const { GetingInfomaystro, dataMaystro } = state.common;
  const { dtStoreinfo } = state.user;
  return {
    registered,
    data,
    GetingInfomaystro,
    dataMaystro,
    dtStoreinfo,
    user,
  };
}
const actionCreators = {
  GetInfoMaystro: commonActions.GetInfoMaystro,
};

const connectedSuccessPage = connect(mapState, actionCreators)(SuccessPage);

export { connectedSuccessPage as SuccessPage };
