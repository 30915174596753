import React from 'react'
export default function DetailAlert(props){
    return (
        <div className="InFlx flxDrc FlHg AlgnItm">
            <div className="RlPs TxtCn IcnSiz">
                {props.dataD[0]}
            </div>
            <div className="RlPs TxtCn">
                {props.dataD[1]}
            </div>
        </div>
    )
}