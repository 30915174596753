export function sidebar(state , action) {
    switch (action.type) {
      case "MANAGE_SIDE_BAR":
          return {
            sideState:!state.sideState
          }
      default:
          return {
              sideState:window.screen.availWidth - (window.outerWidth - window.innerWidth)<1200,
              ...state
            }
      }
  }