import React from "react";
import { closeIcon } from "../../../_Common/IconSvg";
import "./DeleteWebhook.scss";
const DeleteWebhook = (props) => {
  const { closeModal } = props;
  return (
    <article className="deleteWebhook">
      <header>
        <h1>Delete webhook</h1>
        <button onClick={closeModal}>{closeIcon}</button>
      </header>
      <main>
        <p>Do you confirm deleting this webhook ?</p>
        <section className="buttonsContainer">
          <button type="submit">Delete</button>
          <button type="button" onClick={closeModal}>
            Cancel
          </button>
        </section>
      </main>
    </article>
  );
};

export default DeleteWebhook;
